import React, { useState, useEffect, useRef } from 'react';

import FechCandidateImage from '../../api/FetchImageData';
import GetCandidateDetails from '../../api/fetchCandidateData';
import { Document, Page, Text, StyleSheet, PDFDownloadLink, Font, Image, View } from '@react-pdf/renderer';
import Logo from '../../assets/images/logo.png';
import { Table, TR, TH, TD } from '@ag-media/react-pdf-table';
import Check from '../../assets/images/arrow.png';

const pdfstyles = StyleSheet.create({
    table: {
        border: 'none',
        paddingTop: '20',
    },
    tables: {
        border: 'none',
        paddingTop: '10',
    },
    row: {
        border: 'none',
    },
    cell: {
        border: 'none',
        padding: 8,
    },
    body: {
        paddingTop: 35,
        paddingBottom: 65,
        paddingHorizontal: 35,
    },
    title: {
        fontSize: 24,
        textAlign: 'center',
    },
    author: {
        fontSize: 12,
        textAlign: 'center',
        marginBottom: 40,
    },
    subtitle: {
        fontSize: 18,
        margin: 12,

    },
    text: {
        margin: 12,
        fontSize: 9,
        textAlign: 'justify',
        fontFamily: 'Times-Roman',
    },
    image: {
        width: 100,
        height: 50,
        marginRight: 10,
    },
    candidateimage: {
        width: 150,
        height: 150,
        marginRight: 10,
    },
    candidatetext: {
        width: 150,
        height: 150,
        marginRight: 10,
        fontSize: 15,
    },
    header: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 20,
        fontSize: 12,
        color: 'grey',
    },

    basicTABLE: {
        border: 'none',
    },
    pageNumber: {
        position: 'absolute',
        fontSize: 10,
        bottom: 30,
        left: 0,
        right: 0,
        textAlign: 'center',
        color: 'grey',
    },
    mainHeading: {
        backgroundColor: '#263746',
        borderRadius: 5,
        color: '#fff',
        fontSize: 10,
        fontWeight: '500',
        marginBottom: 8,
        padding: 5,
        position: 'relative',
        textTransform: 'uppercase',
        marginTop: '20',
    },
    mainHeadings: {
        backgroundColor: '#8e8e9345',
        borderRadius: 5,
        color: '#0e103d',
        fontSize: 9,
        fontWeight: '700',
        marginBottom: 8,
        textTransform: 'uppercase',
        marginTop: '15',

        padding: 5,
    },
    basictd: {
        width: "33%",
        whiteSpace: 'normal',
        padding: '10px',
        verticalAlign: 'top',

    },
    span: {
        color: '#000',
        display: 'block',
        fontSize: 9,
        fontWeight: '600',
        textAlign: 'left',
        textTransform: 'uppercase',
        border: 'none',
    },
    div: {
        backgroundColor: '#f2f2f208',
        whiteSpace: 'normal',
        wordWrap: 'break-word',
        verticalAlign: 'top',

    },
    inputText: {
        background: '#fff',
        borderRadius: '6',
        boxSizing: 'border-box',
        color: '#0d103d',
        fontSize: 9,
        fontWeight: 500,
        padding: '5px',
        outline: 'none',
        textAlign: 'left',
        transition: '0.5s linear',
        width: '100%',
        paddingLeft: '20px',
    }
    ,
    inputTexts: {
        background: '#fff',
        borderRadius: '6',
        boxSizing: 'border-box',
        color: '#0d103d',
        fontSize: 9,
        fontWeight: 500,
        outline: 'none',
        textAlign: 'left',
        transition: '0.5s linear',
        width: '100%',
        wordWrap: 'break-word',
        whiteSpace: 'normal',

    },
    inputTextfield: {
        background: '#fff',
        color: '#0d103d',
        fontSize: 9,
        fontWeight: 500,
        paddingLeft: 80,
    },
    colSpan: {
        width: '100%',
    },
    declare: {
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 10,
        marginTop: 10,
        fontSize: 9,
    },
    checkbox: {
        marginRight: 10,
        display: 'flex',
        alignItems: 'center',
        fontSize: 9,
    },
    checkmark: {
        width: 20,
        height: 20,
        border: '1px solid black',
        backgroundColor: '#f5f5f5',
        marginRight: 5,
        fontSize: 9,
    },
    checkmarkChecked: {
        backgroundColor: 'black',
    },
    red: {
        color: 'red',
    },
});

const PDF = React.forwardRef(({ url }, ref) => {
   
    const [familyDetails, setFamilyDetails] = useState([]);
    const [refrenceDetails, setRefrenceDetails] = useState([]);
    const [professionalDetails, setProfessionalDetails] = useState([]);
    const [educationDetails, setEducationDetails] = useState([]);
    const [skillsDetails, setSkillsDetails] = useState([]);
    const [awardDetails, setAwardsDetails] = useState([]);
    const [languageDetails, setLanguageDetails] = useState([]);
    const [image, setImage] = useState('');
    const [imageLoaded, setImageLoaded] = useState(false);
    const [formData, setFormData] = useState({
        Job_Title: '',
        First_Name: '',
        Middle_Name: '',
        Last_Name: '',
        Height: '',
        Weight: '',
        Date_Of_Birth: '',
        Blood_Group: '',
        Identification_Marks: '',
        Email: '',
        Email2: '',
        Email3: '',
        Gender: '',
        Marital_Status: '',
        image: null,
        Present_Address: '',
        Present_City: '',
        Present_State: '',
        Present_Country: '',
        Present_Zip_Code: '',
        Permanent_Phone: '',
        Permanent_Address: '',
        Permanent_Country: '',
        Permanent_State: '',
        Permanent_City: '',
        Permanent_Zip_Code: '',
        Permanent_Phones: [],
        Referred_For_Employment: "",
        Criminal_Case: "",
        Major_illness: "",
        Vehicle_Detail: "",
        Abroad_Detail: "",
        Passport_Detail: "",
        Aim_In_Life: "",
        Yourself_after_Years: "",
        Hobby: "",
        Other_Information: "",
        Current_Salary: "",
        Expected_Salary: "",
        Notice_preiod: "",
        When_Join: "",
        Declare_Status: "",
        Candidate_Resume: ""
    });

    const fetchCandidateDetails = async () => {
        const params = new URLSearchParams(new URL(url).search);
        const token = params.get("token");
        const zoho_candidate_id = params.get("candidate_id");
        const view = 1;
        const details = await GetCandidateDetails(token, zoho_candidate_id, view);
        if (details) {
            const basicDetails = details.data.basic_details;
            const apiData = details.data;
            setFormData({
                ...formData,
                Job_Title: basicDetails.Job_Title || '',
                First_Name: basicDetails.First_Name || '',
                Middle_Name: basicDetails.Middle_Name || '',
                Last_Name: basicDetails.Last_Name || '',
                Height: basicDetails.Height || '',
                Weight: basicDetails.Weight || '',
                Date_Of_Birth: basicDetails.Date_Of_Birth || '',
                Blood_Group: basicDetails.Blood_Group || '',
                Identification_Marks: basicDetails.Identification_Marks || '',
                Email: basicDetails.Email || '',
                Email2: basicDetails.Email2 || '',
                Email3: basicDetails.Email3 || '',
                Gender: apiData.basic_details.Gender || '',
                Marital_Status: apiData.basic_details.Marital_Status || '',
                Present_Address: apiData.basic_details.Present_Address || '',
                Present_City: apiData.basic_details.Present_City || '',
                Present_State: apiData.basic_details.Present_State || '',
                Present_Country: apiData.basic_details.Present_Country || '',
                Present_Zip_Code: apiData.basic_details.Present_Zip_Code || '',
                Present_Phone: apiData.basic_details.Present_Phone || '',
                Present_Phone2: apiData.basic_details.Present_Phone2 || '',
                Present_Phone3: apiData.basic_details.Present_Phone3 || '',
                Permanent_Address: apiData.basic_details.Permanent_Address || '',
                Permanent_City: apiData.basic_details.Permanent_City || '',
                Permanent_State: apiData.basic_details.Permanent_State || '',
                Permanent_Country: apiData.basic_details.Permanent_Country || '',
                Permanent_Zip_Code: apiData.basic_details.Permanent_Zip_Code || ''

            });
            if (apiData.candidate_other_information === null) {
                setFormData(prevState => ({
                    ...prevState,
                    Referred_For_Employment: '',
                    Criminal_Case: '',
                    Major_illness: '',
                    Vehicle_Detail: '',
                    Abroad_Detail: '',
                    Passport_Detail: '',
                    Aim_In_Life: '',
                    Yourself_after_Years: '',
                    Hobby: '',
                    Other_Information: '',
                    Current_Salary: '',
                    Expected_Salary: '',
                    Notice_preiod: '',
                    When_Join: '',
                    Declare_Status: '',
                }));
            } else {
                setFormData(prevState => ({
                    ...prevState,
                    Referred_For_Employment: apiData.candidate_other_information.Referred_For_Employment || '',
                    Criminal_Case: apiData.candidate_other_information.Criminal_Case || '',
                    Major_illness: apiData.candidate_other_information.Major_illness || '',
                    Vehicle_Detail: apiData.candidate_other_information.Vehicle_Detail || '',
                    Abroad_Detail: apiData.candidate_other_information.Abroad_Detail || '',
                    Passport_Detail: apiData.candidate_other_information.Passport_Detail || '',
                    Aim_In_Life: apiData.candidate_other_information.Aim_In_Life || '',
                    Yourself_after_Years: apiData.candidate_other_information.Yourself_after_Years || '',
                    Hobby: apiData.candidate_other_information.Hobby || '',
                    Other_Information: apiData.candidate_other_information.Other_Information || '',
                    Current_Salary: apiData.candidate_other_information.Current_Salary || '',
                    Expected_Salary: apiData.candidate_other_information.Expected_Salary || '',
                    Notice_preiod: apiData.candidate_other_information.Notice_preiod || '',
                    When_Join: apiData.candidate_other_information.When_Join || '',
                    Declare_Status: apiData.candidate_other_information.Declare_Status || '',
                    Candidate_Resume: apiData.candidate_other_information.Candidate_Resume
                }));
            }
            //family data
            const candidate_family_details = apiData.candidate_family_details;
            setFamilyDetails(candidate_family_details || []);
            // refrence data
            const candidate_references_details = apiData.candidate_references_details;
            setRefrenceDetails(candidate_references_details || []);
            //professional data
            const candidate_professional_details = apiData.candidate_professional_details;
            setProfessionalDetails(candidate_professional_details || []);
            //educational data
            const candidate_educational_details = apiData.candidate_educational_details;
            setEducationDetails(candidate_educational_details || []);
            //skills data
            const candidate_computer_skills = apiData.candidate_computer_skills;
            setSkillsDetails(candidate_computer_skills || []);
            //awards data
            const candidate_scholarship_award = apiData.candidate_scholarship_award;
            setAwardsDetails(candidate_scholarship_award || []);
            //language data
            const candidate_language_known_skills = apiData.candidate_language_known_skills;
            setLanguageDetails(candidate_language_known_skills || []);




        } else {
            console.error('Error fetching candidate details:');
        }
    };

    const fetchImageURL = async () => {
        const params = new URLSearchParams(new URL(url).search);
        const zoho_candidate_id = params.get("candidate_id");
        
        try {
            const response = await FechCandidateImage(zoho_candidate_id);
            const imageUrl = response.encoded_image;
            setImage(imageUrl);
            
        } catch (error) {
            console.error('Error fetching image URL:', error);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            // Fetch candidate details
            await fetchCandidateDetails();

            // Fetch candidate image URL
            await fetchImageURL();
        };

        fetchData();
    }, []);



    return (
        <Document>
            <Page size="A4" style={pdfstyles.body}>

                <View style={pdfstyles.header} fixed>
                    <Image style={pdfstyles.image} src={Logo} />
                    <Text style={pdfstyles.header}>APPLICATION FOR EMPLOYMENT</Text>
                </View>

                <View style={pdfstyles.mainHeading}>
                    <Text style={pdfstyles['accordion-btn']}>Basic Details</Text>
                </View>
                <View style={{ flexDirection: 'row' }}>

                    <View style={{ flex: 1, paddingRight: 15 }}>
                        <View style={{ flexDirection: 'row', alignItems: 'center', paddingVertical: 2 }}>
                            <Text style={pdfstyles.span}>Post Apply:</Text>
                            <Text style={{ ...pdfstyles.inputText }}>{formData.Job_Title}</Text>
                        </View>

                        <View style={{ flexDirection: 'row', alignItems: 'center', paddingVertical: 2 }}>
                            <Text style={pdfstyles.span}>First Name:</Text>
                            <Text style={{ ...pdfstyles.inputText }}>{formData.First_Name}</Text>
                        </View>

                        {formData.Middle_Name ? (
                            <View style={{ flexDirection: 'row', alignItems: 'center', paddingVertical: 2 }}>
                                <Text style={pdfstyles.span}>Middle Name:</Text>
                                <Text style={{ ...pdfstyles.inputText }}>{formData.Middle_Name}</Text>
                            </View>
                        ) : null}

                        <View style={{ flexDirection: 'row', alignItems: 'center', paddingVertical: 2 }}>
                            <Text style={pdfstyles.span}>Last Name:</Text>
                            <Text style={{ ...pdfstyles.inputText }}>{formData.Last_Name}</Text>
                        </View>

                        <View style={{ flexDirection: 'row', alignItems: 'center', paddingVertical: 2 }}>
                            <Text style={pdfstyles.span}>Date of Birth:</Text>
                            <Text style={{ ...pdfstyles.inputText }}>{formData.Date_Of_Birth}</Text>
                        </View>

                        <View style={{ flexDirection: 'row', alignItems: 'center', paddingVertical: 2 }}>
                            <Text style={pdfstyles.span}>Height:</Text>
                            <Text style={{ ...pdfstyles.inputText }}>{formData.Height}</Text>
                        </View>


                        <View style={{ flexDirection: 'row', alignItems: 'center', paddingVertical: 2 }}>
                            <Text style={pdfstyles.span}>Weight:</Text>
                            <Text style={{ ...pdfstyles.inputText }}>{formData.Weight}</Text>
                        </View>




                    </View>

                    {/* Right Column for Image */}
                    <View >
                        {image ? (
                            <Image style={pdfstyles.candidateimage} src={image} />
                        ) : (
                            <Text style={pdfstyles.candidatetext}>No image available</Text>
                        )}
                    </View>
                </View>
                <Table cellPadding="0" cellSpacing="0" style={pdfstyles.table}>
                    <TH>
                        <TD style={pdfstyles.span}>Blood Group:</TD>
                        <TD style={pdfstyles.span}>Identification Marks:</TD>
                        <TD style={pdfstyles.span}>Email Id</TD>
                    </TH>

                    <TR>
                        <TD className={pdfstyles.basictd}>
                            <View style={pdfstyles.div}>
                                <Text style={pdfstyles.inputTexts}>{formData.Blood_Group}</Text>
                            </View>
                        </TD>
                        <TD className={pdfstyles.basictd}>
                            <View style={pdfstyles.div}>
                                <Text style={pdfstyles.inputTexts}>{formData.Identification_Marks}</Text>
                            </View>
                        </TD>
                        <TD className={pdfstyles.basictd}>
                            <View style={pdfstyles.div}>

                                <Text style={pdfstyles.inputTexts}>{formData.Email}{formData.Email2}</Text>


                            </View>
                        </TD>
                    </TR>

                </Table>
                <View style={pdfstyles.mainHeadings}>
                    <Text style={pdfstyles['accordion-btn']}>Status:</Text>
                </View>
                <Table cellPadding="0" cellSpacing="0" style={pdfstyles.tables}>
                    <TH>
                        <TD style={pdfstyles.span}>Sex:</TD>
                        <TD style={pdfstyles.span}>Marital Status:</TD>

                    </TH>

                    <TR>
                        <TD className={pdfstyles.basictd}>
                            <View style={pdfstyles.div}>
                                <Text style={pdfstyles.inputTexts}>{formData.Gender}</Text>
                            </View>
                        </TD>
                        <TD className={pdfstyles.basictd}>
                            <View style={pdfstyles.div}>
                                <Text style={pdfstyles.inputTexts}>{formData.Marital_Status}</Text>
                            </View>
                        </TD>

                    </TR>

                </Table>
                <View style={pdfstyles.mainHeadings}>
                    <Text style={pdfstyles['accordion-btn']}>Present Address:</Text>
                </View>
                <Table cellPadding="0" cellSpacing="0" style={pdfstyles.tables}>
                    <TH>
                        <TD style={pdfstyles.span}>Address:</TD>
                        <TD style={pdfstyles.span}>City:</TD>
                        <TD style={pdfstyles.span}>State:</TD>
                        <TD style={pdfstyles.span}>Country:</TD>
                        <TD style={pdfstyles.span}>Pin/Zip Code:</TD>
                        <TD style={pdfstyles.span}>Cell/Phone:</TD>

                    </TH>

                    <TR>
                        <TD className={pdfstyles.basictd}>
                            <View style={pdfstyles.div}>
                                <Text style={pdfstyles.inputTexts}>{formData.Present_Address}</Text>
                            </View>
                        </TD>
                        <TD className={pdfstyles.basictd}>
                            <View style={pdfstyles.div}>
                                <Text style={pdfstyles.inputTexts}>{formData.Present_City}</Text>
                            </View>
                        </TD>
                        <TD className={pdfstyles.basictd}>
                            <View style={pdfstyles.div}>
                                <Text style={pdfstyles.inputTexts}>{formData.Present_State}</Text>
                            </View>
                        </TD>
                        <TD className={pdfstyles.basictd}>
                            <View style={pdfstyles.div}>
                                <Text style={pdfstyles.inputTexts}>{formData.Present_Country}</Text>
                            </View>
                        </TD>
                        <TD className={pdfstyles.basictd}>
                            <View style={pdfstyles.div}>
                                <Text style={pdfstyles.inputTexts}>{formData.Present_Zip_Code}</Text>
                            </View>
                        </TD>
                        <TD className={pdfstyles.basictd}>
                            <View style={pdfstyles.div}>
                                <Text style={pdfstyles.inputTexts}>{formData.Present_Phone}{formData.Present_Phone2}</Text>
                            </View>
                        </TD>

                    </TR>

                </Table>


                <View style={pdfstyles.mainHeadings}>
                    <Text style={pdfstyles['accordion-btn']}>Permanent Address:</Text>
                </View>
                <Table cellPadding="0" cellSpacing="0" style={pdfstyles.tables}>
                    <TH>
                        <TD style={pdfstyles.span}>Address:</TD>
                        <TD style={pdfstyles.span}>City:</TD>
                        <TD style={pdfstyles.span}>State:</TD>
                        <TD style={pdfstyles.span}>Country:</TD>
                        <TD style={pdfstyles.span}>Pin/Zip Code:</TD>

                    </TH>

                    <TR>
                        <TD className={pdfstyles.basictd}>
                            <View style={pdfstyles.div}>
                                <Text style={pdfstyles.inputTexts}>{formData.Permanent_Address}</Text>
                            </View>
                        </TD>
                        <TD className={pdfstyles.basictd}>
                            <View style={pdfstyles.div}>
                                <Text style={pdfstyles.inputTexts}>{formData.Permanent_City}</Text>
                            </View>
                        </TD>
                        <TD className={pdfstyles.basictd}>
                            <View style={pdfstyles.div}>
                                <Text style={pdfstyles.inputTexts}>{formData.Permanent_City}</Text>
                            </View>
                        </TD>
                        <TD className={pdfstyles.basictd}>
                            <View style={pdfstyles.div}>
                                <Text style={pdfstyles.inputTexts}>{formData.Permanent_Country}</Text>
                            </View>
                        </TD>
                        <TD className={pdfstyles.basictd}>
                            <View style={pdfstyles.div}>
                                <Text style={pdfstyles.inputTexts}>{formData.Permanent_Zip_Code}</Text>
                            </View>
                        </TD>

                    </TR>

                </Table>

                <View style={pdfstyles.mainHeading}>
                    <Text style={pdfstyles['accordion-btn']}>Family/Reference</Text>
                </View>
                <View style={pdfstyles.mainHeadings}>
                    <Text style={pdfstyles['accordion-btn']}>Permanent Address:</Text>
                </View>
                <Table cellPadding="0" cellSpacing="0" style={pdfstyles.tables}>
                    <TH>
                        <TD style={pdfstyles.span}>Relation:</TD>
                        <TD style={pdfstyles.span}>Name:</TD>
                        <TD style={pdfstyles.span}>Age:</TD>
                        <TD style={pdfstyles.span}>Occupation:</TD>

                    </TH>
                    {familyDetails.map((member, index) => (
                        <TR style={{ paddingTop: 10 }}>
                            <TD className={pdfstyles.basictd}>
                                <View style={pdfstyles.div}>
                                    <Text style={pdfstyles.inputTexts}>{member.Relation}</Text>
                                </View>
                            </TD>
                            <TD className={pdfstyles.basictd}>
                                <View style={pdfstyles.div}>
                                    <Text style={pdfstyles.inputTexts}>{member.Name}</Text>
                                </View>
                            </TD>
                            <TD className={pdfstyles.basictd}>
                                <View style={pdfstyles.div}>
                                    <Text style={pdfstyles.inputTexts}>{member.Age}</Text>
                                </View>
                            </TD>
                            <TD className={pdfstyles.basictd}>
                                <View style={pdfstyles.div}>
                                    <Text style={pdfstyles.inputTexts}>{member.Occupation}</Text>
                                </View>
                            </TD>

                        </TR>
                    ))}
                </Table>

                <View style={pdfstyles.mainHeadings}>
                    <Text style={pdfstyles['accordion-btn']}>Whom can we refer about your past experience/credentials?</Text>
                </View>
                <Table cellPadding="0" cellSpacing="0" style={pdfstyles.tables}>
                    <TH>
                        <TD style={pdfstyles.span}>Name:</TD>
                        <TD style={pdfstyles.span}>Address:</TD>
                        <TD style={pdfstyles.span}>Mobile:</TD>
                        <TD style={pdfstyles.span}>Email Id:</TD>
                        <TD style={pdfstyles.span}>Relation:</TD>

                    </TH>
                    {refrenceDetails.map((member, index) => (
                        <TR key={index} style={{ paddingTop: 10 }}>
                            <TD className={pdfstyles.basictd}>
                                <View style={pdfstyles.div}>
                                    <Text style={pdfstyles.inputTexts}>{member.Name}</Text>
                                </View>
                            </TD>
                            <TD className={pdfstyles.basictd}>
                                <View style={pdfstyles.div}>
                                    <Text style={pdfstyles.inputTexts}>{member.Address}</Text>
                                </View>
                            </TD>
                            <TD className={pdfstyles.basictd}>
                                <View style={pdfstyles.div}>
                                    <Text style={pdfstyles.inputTexts}>{member.Mobile}</Text>
                                </View>
                            </TD>
                            <TD className={pdfstyles.basictd}>
                                <View style={pdfstyles.div}>
                                    <Text style={pdfstyles.inputTexts}>{member.Email}</Text>
                                </View>
                            </TD>
                            <TD className={pdfstyles.basictd}>
                                <View style={pdfstyles.div}>
                                    <Text style={pdfstyles.inputTexts}>{member.Relation}</Text>
                                </View>
                            </TD>

                        </TR>
                    ))}
                </Table>
                <View style={pdfstyles.mainHeading}>
                    <Text style={pdfstyles['accordion-btn']}>Professional Experience</Text>
                </View>
                <Table cellPadding="0" cellSpacing="0" style={pdfstyles.tables}>
                    {professionalDetails.map((member, index) => (
                        <React.Fragment key={index}>
                            {index === 0 && (
                                <>
                                    <TR style={{ marginBottom: 10 }}>
                                        <TD className={pdfstyles.basictd}>
                                            <View style={pdfstyles.div}>
                                                <Text style={pdfstyles.span}>Name of the company:</Text>
                                            </View>
                                        </TD>
                                        <TD className={pdfstyles.basictd}>
                                            <View style={pdfstyles.div}>
                                                <Text style={pdfstyles.span}>Name of the manager/employer:</Text>
                                            </View>
                                        </TD>
                                    </TR>

                                </>
                            )}
                            <TR style={{ marginBottom: 10 }}>
                                <TD className={pdfstyles.basictd}>
                                    <View style={pdfstyles.div}>
                                        <Text style={pdfstyles.inputTexts}>{member.Company_Name}</Text>
                                    </View>
                                </TD>
                                <TD className={pdfstyles.basictd}>
                                    <View style={pdfstyles.div}>
                                        <Text style={pdfstyles.inputTexts}>{member.Employer_Name}</Text>
                                    </View>
                                </TD>
                            </TR>

                            {index === 0 && (
                                <>
                                    <TR style={{ marginBottom: 10 }}>
                                        <TD className={pdfstyles.basictd}>
                                            <View style={pdfstyles.div}>
                                                <Text style={pdfstyles.span}>Start Date:</Text>
                                            </View>
                                        </TD>
                                        <TD className={pdfstyles.basictd}>
                                            <View style={pdfstyles.div}>
                                                <Text style={pdfstyles.span}>End Date:</Text>
                                            </View>
                                        </TD>
                                        <TD className={pdfstyles.basictd}>
                                            <View style={pdfstyles.div}>
                                                <Text style={pdfstyles.span}>Start Salary:</Text>
                                            </View>
                                        </TD>
                                        <TD className={pdfstyles.basictd}>
                                            <View style={pdfstyles.div}>
                                                <Text style={pdfstyles.span}>End Salary:</Text>
                                            </View>
                                        </TD>
                                        <TD className={pdfstyles.basictd}>
                                            <View style={pdfstyles.div}>
                                                <Text style={pdfstyles.span}>Reason for Leaving:</Text>
                                            </View>
                                        </TD>

                                    </TR>
                                </>
                            )}
                            <TR style={{ marginBottom: 10 }}>
                                <TD className={pdfstyles.basictd}>
                                    <View style={pdfstyles.div}>
                                        <Text style={pdfstyles.inputTexts}>{member.Start_Date}</Text>
                                    </View>
                                </TD>
                                <TD className={pdfstyles.basictd}>
                                    <View style={pdfstyles.div}>
                                        <Text style={pdfstyles.inputTexts}>{member.End_Date}</Text>
                                    </View>
                                </TD>
                                <TD className={pdfstyles.basictd}>
                                    <View style={pdfstyles.div}>
                                        <Text style={pdfstyles.inputTexts}>{member.Start_Salary}</Text>
                                    </View>
                                </TD>
                                <TD className={pdfstyles.basictd}>
                                    <View style={pdfstyles.div}>
                                        <Text style={pdfstyles.inputTexts}>{member.Last_Salary}</Text>
                                    </View>
                                </TD>
                                <TD className={pdfstyles.basictd}>
                                    <View style={pdfstyles.div}>
                                        <Text style={pdfstyles.inputTexts}>{member.Reason_For_Leaving}</Text>
                                    </View>
                                </TD>
                            </TR>



                        </React.Fragment>
                    ))}
                </Table>



                <View style={pdfstyles.mainHeading}>
                    <Text style={pdfstyles['accordion-btn']}>Education/Awards</Text>
                </View>

                <Table cellPadding="0" cellSpacing="0" style={pdfstyles.tables}>
                    {educationDetails.map((member, index) => (
                        <React.Fragment key={index}>
                            {index === 0 && (
                                <>
                                    <TR style={{ marginBottom: 10 }}>
                                        <TD className={pdfstyles.basictd}>
                                            <View style={pdfstyles.div}>
                                                <Text style={pdfstyles.span}>Qualification:</Text>
                                            </View>
                                        </TD>
                                        <TD className={pdfstyles.basictd}>
                                            <View style={pdfstyles.div}>
                                                <Text style={pdfstyles.span}>Name of Course:</Text>
                                            </View>
                                        </TD>
                                        <TD className={pdfstyles.basictd}>
                                            <View style={pdfstyles.div}>
                                                <Text style={pdfstyles.span}>Name of the Institution:</Text>
                                            </View>
                                        </TD>
                                        <TD className={pdfstyles.basictd}>
                                            <View style={pdfstyles.div}>
                                                <Text style={pdfstyles.span}>Address of Institution:</Text>
                                            </View>
                                        </TD>
                                    </TR>
                                </>
                            )}
                            <TR style={{ paddingTop: 10 }}>
                                <TD className={pdfstyles.basictd}>
                                    <View style={pdfstyles.div}>
                                        <Text style={pdfstyles.inputTexts}>{member.Qualification}</Text>
                                    </View>
                                </TD>
                                <TD className={pdfstyles.basictd}>
                                    <View style={pdfstyles.div}>
                                        <Text style={pdfstyles.inputTexts}>{member.Course_Name}</Text>
                                    </View>
                                </TD>
                                <TD className={pdfstyles.basictd}>
                                    <View style={pdfstyles.div}>
                                        <Text style={pdfstyles.inputTexts}>{member.Institution_Name}</Text>
                                    </View>
                                </TD>
                                <TD className={pdfstyles.basictd}>
                                    <View style={pdfstyles.div}>
                                        <Text style={pdfstyles.inputTexts}>{member.Institution_Address}</Text>
                                    </View>
                                </TD>
                            </TR>

                            {index === 0 && (
                                <>
                                    <TR style={{ paddingTop: 10 }}>
                                        <TD className={pdfstyles.basictd}>
                                            <View style={pdfstyles.div}>
                                                <Text style={pdfstyles.span}>Subjects/Specialization:</Text>
                                            </View>
                                        </TD>
                                        <TD className={pdfstyles.basictd}>
                                            <View style={pdfstyles.div}>
                                                <Text style={pdfstyles.span}>Period Start:</Text>
                                            </View>
                                        </TD>
                                        <TD className={pdfstyles.basictd}>
                                            <View style={pdfstyles.div}>
                                                <Text style={pdfstyles.span}>Period End:</Text>
                                            </View>
                                        </TD>
                                        <TD className={pdfstyles.basictd}>
                                            <View style={pdfstyles.div}>
                                                <Text style={pdfstyles.span}>Percentage:</Text>
                                            </View>
                                        </TD>
                                    </TR>
                                </>
                            )}
                            <TR style={{ paddingTop: 10 }}>
                                {index <= 1 && (
                                    <TD className={pdfstyles.basictd}>
                                        <View style={pdfstyles.div}>
                                            <Text style={pdfstyles.inputTexts}>{member.Specialization}</Text>
                                        </View>
                                    </TD>
                                )}
                                <TD className={pdfstyles.basictd}>
                                    <View style={pdfstyles.div}>
                                        <Text style={pdfstyles.inputTexts}>{member.Start_Date}</Text>
                                    </View>
                                </TD>
                                <TD className={pdfstyles.basictd}>
                                    <View style={pdfstyles.div}>
                                        <Text style={pdfstyles.inputTexts}>{member.End_Date}</Text>
                                    </View>
                                </TD>
                                <TD className={pdfstyles.basictd}>
                                    <View style={pdfstyles.div}>
                                        <Text style={pdfstyles.inputTexts}>{member.Percentage}</Text>
                                    </View>
                                </TD>
                                {index > 1 && (
                                    <TD className={pdfstyles.basictd}>
                                        <View style={pdfstyles.div}>
                                            <Text style={pdfstyles.inputTexts}>{member.Specialization}</Text>
                                        </View>
                                    </TD>
                                )}
                            </TR>

                            {index > 1 && member.Event_Name && member.Event_Date && member.Represented_Whom && member.Awards_Received && (
                                <>
                                    <TR style={{ paddingTop: 10 }}>
                                        <TD className={pdfstyles.basictd}>
                                            <View style={pdfstyles.div}>
                                                <Text style={pdfstyles.span}>Event:</Text>
                                            </View>
                                        </TD>
                                        <TD className={pdfstyles.basictd}>
                                            <View style={pdfstyles.div}>
                                                <Text style={pdfstyles.span}>Date:</Text>
                                            </View>
                                        </TD>
                                        <TD className={pdfstyles.basictd}>
                                            <View style={pdfstyles.div}>
                                                <Text style={pdfstyles.span}>Represented Whom:</Text>
                                            </View>
                                        </TD>
                                        <TD className={pdfstyles.basictd}>
                                            <View style={pdfstyles.div}>
                                                <Text style={pdfstyles.span}>Awards Received, if any:</Text>
                                            </View>
                                        </TD>
                                    </TR>
                                    <TR style={{ paddingTop: 10 }}>
                                        <TD className={pdfstyles.basictd}>
                                            <View style={pdfstyles.div}>
                                                <Text style={pdfstyles.inputTexts}>{member.Event_Name}</Text>
                                            </View>
                                        </TD>
                                        <TD className={pdfstyles.basictd}>
                                            <View style={pdfstyles.div}>
                                                <Text style={pdfstyles.inputTexts}>{member.Event_Date}</Text>
                                            </View>
                                        </TD>
                                        <TD className={pdfstyles.basictd}>
                                            <View style={pdfstyles.div}>
                                                <Text style={pdfstyles.inputTexts}>{member.Represented_Whom}</Text>
                                            </View>
                                        </TD>
                                        <TD className={pdfstyles.basictd}>
                                            <View style={pdfstyles.div}>
                                                <Text style={pdfstyles.inputTexts}>{member.Awards_Received}</Text>
                                            </View>
                                        </TD>
                                    </TR>
                                </>
                            )}
                        </React.Fragment>
                    ))}
                </Table>


                <View style={pdfstyles.mainHeading}>
                    <Text style={pdfstyles['accordion-btn']}>Other Information</Text>
                </View>
                <View style={{ flexDirection: 'column', width: '100%' }}>
                    {[
                        {
                            question: 'Who referred you to us for employment?',
                            answer: formData.Referred_For_Employment,
                        },
                        {
                            question: 'Have you been involved in any Civil/Criminal Litigation?',
                            answer: formData.Criminal_Case ? formData.Criminal_Case : 'No',
                        },
                        {
                            question: 'Have you had major illness/operations in the last three years?',
                            answer: formData.Major_illness ? formData.Major_illness : 'No',
                        },
                        {
                            question: 'Do you own any vehicle?',
                            answer: formData.Vehicle_Detail ? formData.Vehicle_Detail : 'No',
                        },
                        {
                            question: 'Have you been abroad?',
                            answer: formData.Abroad_Detail ? formData.Abroad_Detail : 'No',
                        },
                        {
                            question: 'Do you have a Valid passport?',
                            answer: formData.Passport_Detail ? formData.Passport_Detail : 'No',
                        },
                        {
                            question: 'What is your aim in life?',
                            answer: formData.Aim_In_Life,
                        },
                        {
                            question: 'Where will you find yourself after 5 years?',
                            answer: formData.Yourself_after_Years,
                        },
                    ].map((item, index) => (
                        <View key={index} style={{ flexDirection: 'row', alignItems: 'center', paddingVertical: 2, justifyContent: 'space-between' }}>
                            <Text style={pdfstyles.span}>{item.question}</Text>
                            <Text style={{ ...pdfstyles.inputTextfield }}>{item.answer}</Text>
                        </View>
                    ))}
                </View>


                <Text style={pdfstyles.pageNumber} render={({ pageNumber, totalPages }) => (
                    `${pageNumber} / ${totalPages}`
                )} fixed />

                <View style={pdfstyles.mainHeadings}>
                    <Text style={pdfstyles['accordion-btn']}>Languages known:</Text>
                </View>
                <Table cellPadding="0" cellSpacing="0" style={pdfstyles.tables}>
                    <TH>
                        <TD style={pdfstyles.span}>Language:</TD>
                        <TD style={pdfstyles.span}>Level:</TD>


                    </TH>
                    {languageDetails.map((member, index) => (
                        <React.Fragment key={index}>
                            <TR style={{ paddingTop: 10 }}>
                                <TD className={pdfstyles.basictd}>
                                    <View style={pdfstyles.div}>
                                        <Text style={pdfstyles.inputTexts}>{member.Language}</Text>
                                    </View>
                                </TD>
                                <TD className={pdfstyles.basictd}>
                                    <View style={pdfstyles.div}>
                                        <Text style={pdfstyles.inputTexts}>{member.Language_Knowledge}</Text>
                                    </View>
                                </TD>


                            </TR>
                        </React.Fragment>
                    ))}

                </Table>
                <View style={pdfstyles.mainHeadings}>
                    <Text style={pdfstyles['accordion-btn']}>If you have taken part in competitive sports, please specify:</Text>
                </View>
                {awardDetails && awardDetails.length > 0 ? (
                    <Table cellPadding="0" cellSpacing="0" style={pdfstyles.tables}>
                        <TH>
                            <TD style={pdfstyles.span}>Event:</TD>
                            <TD style={pdfstyles.span}>Year:</TD>
                            <TD style={pdfstyles.span}>Represented whom:</TD>
                            <TD style={pdfstyles.span}>Awards received, if any:</TD>
                        </TH>
                        {awardDetails.map((member, index) => (
                            <React.Fragment key={index}>
                                <TR>
                                    <TD className={pdfstyles.basictd}>
                                        <View style={pdfstyles.div}>
                                            <Text style={pdfstyles.inputTexts}>{member.Event_Name}</Text>
                                        </View>
                                    </TD>
                                    <TD className={pdfstyles.basictd}>
                                        <View style={pdfstyles.div}>
                                            <Text style={pdfstyles.inputTexts}>{member.Event_Date}</Text>
                                        </View>
                                    </TD>
                                    <TD className={pdfstyles.basictd}>
                                        <View style={pdfstyles.div}>
                                            <Text style={pdfstyles.inputTexts}>{member.Represented_Whom}</Text>
                                        </View>
                                    </TD>
                                    <TD className={pdfstyles.basictd}>
                                        <View style={pdfstyles.div}>
                                            <Text style={pdfstyles.inputTexts}>{member.Awards_Received}</Text>
                                        </View>
                                    </TD>
                                </TR>
                            </React.Fragment>
                        ))}
                    </Table>
                ) : (
                    null
                )}

                <View style={{ flexDirection: 'column', width: '100%', marginTop: 10 }}>
                    {[
                        {
                            question: 'Hobby/extra curricular activities:',
                            answer: formData.Hobby,
                        },
                        {
                            question: 'Any other information you wish to provide in support of your candidature?',
                            answer: formData.Other_Information,
                        },
                    ].map((item, index) => (
                        <View key={index} style={{ flexDirection: 'row', alignItems: 'center', paddingVertical: 2, justifyContent: 'space-between' }}>
                            <Text style={pdfstyles.span}>{item.question}</Text>
                            <Text style={{ ...pdfstyles.inputTextfield }}>{item.answer}</Text>
                        </View>
                    ))}
                </View>



                <View style={pdfstyles.mainHeadings}>
                    <Text style={pdfstyles['accordion-btn']}>If you have taken part in competitive sports, please specify:</Text>
                </View>
                <Table cellPadding="0" cellSpacing="0" style={pdfstyles.tables} >
                    <TH >
                        <TD style={pdfstyles.span}>RENUMERATION EXPECTED:</TD>
                        <TD style={pdfstyles.span}>Current SALARY:</TD>
                        <TD style={pdfstyles.span}>Notice period:</TD>
                        <TD style={pdfstyles.span}>WHEN CAN YOU JOIN?</TD>

                    </TH>

                    <TR style={{ paddingTop: 10 }}>
                        <TD className={pdfstyles.basictd}>
                            <View style={pdfstyles.div}>
                                <Text style={pdfstyles.inputTexts}>{formData.Expected_Salary}</Text>
                            </View>
                        </TD>
                        <TD className={pdfstyles.basictd}>
                            <View style={pdfstyles.div}>
                                <Text style={pdfstyles.inputTexts}>{formData.Current_Salary}</Text>
                            </View>
                        </TD>
                        <TD className={pdfstyles.basictd}>
                            <View style={pdfstyles.div}>
                                <Text style={pdfstyles.inputTexts}>{formData.Notice_preiod}</Text>
                            </View>
                        </TD>
                        <TD className={pdfstyles.basictd}>
                            <View style={pdfstyles.div}>
                                <Text style={pdfstyles.inputTexts}>{formData.When_Join}</Text>
                            </View>
                        </TD>


                    </TR>

                </Table>
                <View style={pdfstyles.declare}>
                    <Text style={pdfstyles.checkbox}>
                        I, hereby declare that the information furnished above is true, complete, and correct to the best of my knowledge and belief.
                        <Text style={pdfstyles.red}>*</Text>
                    </Text>
                    <Image style={{ width: 10, height: 10 }} src={Check} />
            
            </View>
               


                <View style={{ flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
                    <Text style={pdfstyles.text}>
                        Place: ________________________
                    </Text>
                    <Text style={pdfstyles.text}>
                        Date: ________________________
                    </Text>
                    <Text style={pdfstyles.text}>
                        Sign: ________________________
                    </Text>
                </View>

            </Page>
        </Document>
    );
});


export default PDF;
