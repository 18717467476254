import React, { useState, useEffect } from 'react';
import styles from '../Boading.module.scss';
import EmployeeInfo from '../EmployeeInfo/EmployeeInfo';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import OnboadingEmployeeDetails from '../../api/FetchEmployeeDetail';
import OnboadingSaveEmployeeDetails from '../../api/SaveOnboadingData';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ImportToBlitz from '../importToBlitz/importToBlitz';
import { useNavigate } from 'react-router-dom';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const EmployeeDetails = ({ department, isActive, authorized, token, employeeId, setEmployeeId, admin, disableTab, onSubmit, activeTabIndex }) => {
    const [country, setCountry] = useState('India');
    const [region, setRegion] = useState('');
    const [loading, setLoading] = useState(true);
    const [formData, setFormData] = useState(null);
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');
    const [phoneError, setPhoneError] = useState('');
    const [cellError, setcellError] = useState('');
    const [refreshEmployeeInfo, setRefreshEmployeeInfo] = useState(false);
    const [buttonsDisabled, setButtonsDisabled] = useState(false);
    const [titleError, setTitleError] = useState('');
    const [locationError, setLocationError] = useState('');
    const [departmentValue, setdepartmentValue] = useState('');
    const navigate = useNavigate();
    const [dateOfJoiningError, setDateOfJoiningError] = useState('');
    useEffect(() => {
        const fetchData = async () => {
            const url = 'get-employee-details';
            if (authorized) {
                try {
                    setLoading(true);
                    const employeeData = await OnboadingEmployeeDetails(url, token, employeeId);
                    if (employeeData.status === 200) {
                        const empDetails = employeeData.employee;
                        setFormData({
                            first_name: empDetails.first_name || '',
                            title: empDetails.title || '',
                            last_name: empDetails.last_name || '',
                            middle_name: empDetails.middle_name || '',
                            department: empDetails.department || '',
                            street_address: empDetails.street_address || '',
                            city: empDetails.city || '',
                            country: empDetails.country || '',
                            state: empDetails.state || '',
                            home_phone: empDetails.home_phone || '',
                            cell_phone: empDetails.cell_phone || '',
                            business_card_name_preferences: empDetails.business_card_name_preferences || '',
                            professional_email_name_preferences: empDetails.professional_email_name_preferences || '',
                            personal_email_address: empDetails.personal_email_address || '',
                            job_title: empDetails.job_title || '',
                            apt_unit: empDetails.apt_unit || '',
                            created_by: admin || '',
                            comment: empDetails.comment || '',
                            joining_date: empDetails.joining_date || '',
                            job_location: empDetails.job_location || '',
                            ship_laptop: empDetails.ship_laptop || false,
                            shipment_location: empDetails.shipment_location || '',
                        });
                        if (empDetails.status === 'Submitted') {
                            setButtonsDisabled(true);
                        }
                        setdepartmentValue(empDetails.department);
                        setCountry(empDetails.country || '');
                        setRegion(empDetails.state || '');
                    } else {
                        console.error('Error fetching employee data:', employeeData.error);
                    }
                } catch (error) {
                    console.error('Error fetching employee data:', error);
                } finally {
                    setLoading(false);
                }
            }
        };

        fetchData();
    }, [isActive, authorized, token, employeeId, admin, refreshEmployeeInfo]);

    const selectCountry = (val) => {
        setCountry(val);
        setRegion('');
        setFormData(prevFormData => ({
            ...prevFormData,
            country: val,
            state: ''
        }));
    };

    const selectRegion = (val) => {
        setRegion(val);
        setFormData(prevFormData => ({
            ...prevFormData,
            state: val
        }));
    };

    const handleInputChange = (event) => {
        setEmail(event.target.value);

        const { name, value } = event.target;
        if (name === 'personal_email_address') {
            if (!event.target.validity.valid) {
                setEmailError('Please enter a valid email address');
            } else {
                setEmailError('');
            }
        }
        if (name === 'home_phone') {
            if (value.includes('-')) {
                setPhoneError('Please enter a valid phone number.');
            } else {
                setPhoneError('');
            }
        }
        if (name === 'cell_phone') {
            if (value.includes('-')) {
                setcellError('Please enter a valid phone number.');
            } else {
                setcellError('');
            }
        }

        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value
        }));
    };
    const handleCheckboxChangelocation = (e) => {
        const { name, checked } = e.target;
        setFormData((prevData) => ({
          ...prevData,
          [name]: checked,
           shipment_location: checked ? prevData.shipment_location : "",
        }));
    };

    const hanldeSaveInfo = async (event) => {
        event.preventDefault();
        if (!formData.comment) {
            toast.error('Please Provide Comment!');
            return;
        }
        if (formData.ship_laptop && !formData.shipment_location) {
            toast.error('Please Provide Shipment Location!');
            return;
        }
        let joiningDate = '';
        if (formData.joining_date) {
            const date = new Date(formData.joining_date);
            // Check if the date is valid
            if (!isNaN(date.getTime())) {
                // Format date to YYYY-MM-DD
                const year = date.getFullYear();
                const month = String(date.getMonth() + 1).padStart(2, '0'); 
                const day = String(date.getDate()).padStart(2, '0');
                joiningDate = `${year}-${month}-${day}`;
            } else {
                toast.error('Invalid joining date!');
                return;
            }
        }
        try {
            setLoading(true);
            const requestData = {
                ...formData,
                joining_date: joiningDate,
                department: departmentValue,
                status: "Pending",
                created_by: admin,

            };
            console.log(requestData);
            const url = 'save-employee-details';
            const response = await OnboadingSaveEmployeeDetails(url, token, employeeId, requestData);
            setRefreshEmployeeInfo(prevState => !prevState);
            toast.success(response.msg);
        } catch (error) {
            console.error('Error saving form data:', error);
            toast.error('Failed to save form data.');
        } finally {
            setLoading(false);
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const hanldeSubmit = async (event) => {
        event.preventDefault();
        if (!formData.job_title) {
            setTitleError('Title is required');
            return;
        }
        if (!formData.job_location) {
            setLocationError('Job Location is required');
            return;
        }
        if (!formData.joining_date) {
            setDateOfJoiningError('Date of joining is required');
            return;
        } else {
            setDateOfJoiningError('');
        }
        if (!formData.comment) {
            toast.error('Please Provide Comment!');
            return;
        }
        if (formData.ship_laptop && !formData.shipment_location) {
            toast.error('Please Provide Shipment Location!');
            return;
        }
        try {
            setLoading(true);
            const requestData = {
                ...formData,
                department: departmentValue,
                status: "Submitted",
                created_by: admin,
            };
            const url = 'save-employee-details';
            const response = await OnboadingSaveEmployeeDetails(url, token, employeeId, requestData);
            toast.success(response.msg);
            //onSubmit(activeTabIndex);
        } catch (error) {
            console.error('Error saving form data:', error);
            toast.error('Failed to save form data.');
        } finally {
            setButtonsDisabled(true);
            setLoading(false);
        }
    }

    const hanldeBlitzSubmit = () => {
        const urlParams = new URLSearchParams(window.location.search);
        const tokens = urlParams.get('token');
        navigate('/candidate-details?token=' + tokens + '&employeID=' + employeeId);
    }

    const handleStartDateChange = (date) => {
        
        setFormData({ ...formData, joining_date: date });
    };

    if (!isActive) return null;



    return (
        <div className={styles.employee}>
            <div className={`${styles.grid} ${styles.relative}`}>
                <div className={styles['edit-bg']}>

                </div>
                {!formData && (
                    <div className={styles['loaderMain-center']}>
                        <div className={styles['formsloader-container']}>
                            <div className={styles.newsloader}></div>
                        </div>
                    </div>
                )}
                {formData && (
                    <>
                        <EmployeeInfo token={token} employeeId={employeeId} refreshEmployeeInfo={refreshEmployeeInfo} />

                        <div className={styles.col9}>
                            <div className={`${styles['box-shadow']} ${styles['border-radius']} `}>
                                <fieldset className={styles.fieldset}>
                                    <form id={styles.basicINFO} name="basicINFO">
                                        <legend>Employee Details</legend>
                                        <div className={styles.grid}>
                                            <div className={styles.col6}>
                                                <div className={styles['right-space']}>
                                                    <h3>Mailing Address</h3>
                                                    <ul className={styles['two-col']}>
                                                        <li><strong>First Name:</strong> <span><input type="text" name="first_name" className={styles.input} value={formData.first_name} onChange={handleInputChange} /></span></li>
                                                        <li><strong>Last Name:</strong> <span><input type="text" name="last_name" className={styles.input} value={formData.last_name} onChange={handleInputChange} /></span></li>
                                                        <li><strong>Middle Initial:</strong> <span><input type="text" name="middle_name" className={styles.input} value={formData.middle_name} onChange={handleInputChange} /></span></li>
                                                        <li><strong>Street Address:</strong> <span><textarea name="street_address" className={styles.input} value={formData.street_address} onChange={handleInputChange} /></span></li>
                                                        <li><strong>Apt/Unit #:</strong> <span><input type="text" name="apt_unit" className={styles.input} value={formData.apt_unit} onChange={handleInputChange} /></span></li>
                                                        <li><strong>City:</strong> <span><input type="text" name="city" className={styles.input} value={formData.city} onChange={handleInputChange} /></span></li>
                                                        <li><strong>Country:</strong> <span><CountryDropdown name="country" value={country} onChange={selectCountry} className={styles['form-select']} /></span></li>
                                                        <li><strong>State:</strong> <span><RegionDropdown name="state" country={country} value={region} onChange={selectRegion} className={styles['form-select']} /></span></li>
                                                        <li>
                                                            <strong>Shipped Laptop:</strong>
                                                            <span>
                                                                <input type="checkbox" id="ship_laptop" name="ship_laptop" checked={formData.ship_laptop}
                                                                    onChange={handleCheckboxChangelocation} />
                                                                <label htmlFor="ship_laptop"></label>
                                                            </span>
                                                        </li>
                                                        {formData.ship_laptop && (
                                                            <li>
                                                                <strong>Shipment Location :</strong>
                                                                <span>
                                                                    <textarea
                                                                        className={styles.input}
                                                                        name="shipment_location"
                                                                        value={formData.shipment_location}
                                                                        onChange={handleChange}
                                                                    ></textarea>
                                                                </span>
                                                            </li>
                                                        )}
                                                    </ul>
                                                </div>
                                            </div>

                                            <div className={styles.col6}>
                                                <div className={styles['left-space']}>
                                                    <h3>Phone Number(s) and Email</h3>
                                                    <ul className={styles['two-col']}>
                                                        <li><strong>Joining Date:</strong> <span><DatePicker
                                                            selected={formData.joining_date}
                                                            onChange={handleStartDateChange}
                                                            peekNextMonth
                                                            showMonthDropdown
                                                            showYearDropdown
                                                            dropdownMode="select"
                                                            className={styles.input}
                                                            id="joining_date"
                                                            name="joining_date"
                                                            autoComplete="off"
                                                            required
                                                        />
                                                            {dateOfJoiningError && <div className={styles['empl-error']}>{dateOfJoiningError}</div>}</span></li>
                                                        <li><strong>Title:</strong>
                                                            <span>
                                                                <input type="text" name="job_title" className={styles.input} value={formData.job_title} onChange={handleInputChange} />
                                                                {titleError && <div className={styles['empl-error']}>{titleError}</div>}
                                                            </span>
                                                        </li>
                                                        <li><strong>Job Location:</strong>
                                                            <span>
                                                                <input type="text" name="job_location" className={styles.input} value={formData.job_location} onChange={handleInputChange} />
                                                                {locationError && <div className={styles['empl-error']}>{locationError}</div>}
                                                            </span>
                                                        </li>
                                                        <li><strong>Home Phone:</strong> <span><input type="number" name="home_phone" min="1" className={styles.input} value={formData.home_phone} onChange={handleInputChange} />{phoneError && <div className={styles['empl-error']}>{phoneError}</div>}</span></li>
                                                        <li><strong>Cell Phone:</strong> <span><input type="number" name="cell_phone" className={styles.input} value={formData.cell_phone} onChange={handleInputChange} />{cellError && <div className={styles['empl-error']}>{cellError}</div>}</span></li>
                                                        <li><strong>Business Card Name Preference:</strong> <span><input type="text" name="business_card_name_preferences" className={styles.input} value={formData.business_card_name_preferences} onChange={handleInputChange} /></span></li>
                                                        <li><strong>Name as they would like to appear on their email (first name.last name):</strong> <span><input type="text" name="professional_email_name_preferences" className={styles.input} value={formData.professional_email_name_preferences} onChange={handleInputChange} /></span></li>
                                                        <li><strong>Personal Email address:</strong> <span><input type="email" name="personal_email_address" className={styles.input} value={formData.personal_email_address} onChange={handleInputChange} pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}" /> {emailError && <div className={styles['empl-error']}>{emailError}</div>}</span></li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className={styles['right-space']}>
                                                <ul className={styles['two-col']}>
                                                    <li><strong>Comment<i className={styles['red']}>*</i>:</strong> <span><textarea name="comment" className={styles.input} value={formData.comment} onChange={handleInputChange} ></textarea></span></li>
                                                </ul>
                                            </div>
                                            {/* Button block */}
                                            <div className={styles['button-block']}>
                                                {!buttonsDisabled && (
                                                    <input type="submit" value="Save" className={styles.button} onClick={hanldeSaveInfo} disabled={loading || !!emailError || !!phoneError || !!cellError} />
                                                )}
                                                {!buttonsDisabled && (
                                                    <input type="submit" value={buttonsDisabled ? 'Submitted' : 'Submit'} className={styles.button} disabled={loading || !!emailError || !!phoneError || !!cellError} onClick={hanldeSubmit} />
                                                )}
                                                <input type="button" style={{ backgroundColor: "rgb(239 155 38)" }} value="Import To Blitz" className={styles.button} onClick={hanldeBlitzSubmit} />
                                                <ToastContainer />
                                            </div>
                                        </div>
                                        <input type="hidden" name="created_by" className={styles.input} value={formData.created_by} />
                                    </form>
                                </fieldset>
                            </div>
                        </div>
                        {loading && <div className={`${styles['loader-center']} ${styles['loaderHeight-classification']} `}>
                            <div className={`${styles['newsloader-container']} ${styles['newsloaderContainer-classification']} `}>
                                <div className={styles.newsloader}></div></div>
                        </div>}
                    </>
                )}
            </div>

        </div>
    );
};

export default EmployeeDetails;
