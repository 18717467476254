import React, { useState, useEffect } from 'react';
import styles from '../Boading.module.scss';
import OffBoadingEmployeeDetails from '../OffBoadingEmployeeDetails/OffBoadingEmployeeDetails';
import OffBoadingClassification from '../OffBoadingClassification/OffBoadingClassification';
import OffBoadingIT from '../OffBoadingIT/OffBoadingIT';
import OffBoadingEmployeeJob from '../OffBoadingEmployeeJob/OffBoadingEmployeeJob';
import OffBoadingAdministrative from '../OffBoadingAdministrative/OffBoadingAdministrative';
import OffBoadingAccount from '../OffBoadingAccount/OffBoadingAccount';
import OffBoadingFinalProcess from '../OffBoadingFinalHRProcess/OffBoadingFinalHRProcess';
import OffboardingTabProgress from '../../api/OffboradingTabProgress';

const Tabs = ({ department, authorized, token, employeeId, setActiveTab, admin, tabs }) => {
  const [activeTab, setActiveTabLocal] = useState(0);
  const [disableTabs, setDisableTabs] = useState(Array(8).fill(true));
  const [TabStatus, setTabStatus] = useState({});

  useEffect(() => {
    const fetchTabProgress = async () => {
      if (token && employeeId) {
        try {
          const tabProgress = await OffboardingTabProgress(token, employeeId);
          console.log(tabProgress.tab_progress[0])
          setTabStatus(tabProgress.tab_progress[0]); 
        } catch (error) {
          console.error('Error fetching tab progress:', error);
        }
      }
    };

    fetchTabProgress();
  }, [token, employeeId]);
  useEffect(() => {
    const tabTitles = [
      'Employee Info',
      'Employee Job',
      'Classification',
      'IT',
      'Administrative',
      'Account',
      'Final HR Process',
    ];

   const tabIndices = {
      'Employee Info': 0,
      'Employee Job': 1,
      'Classification': 2,
      'IT': 3,
      'Administrative': 4,
      'Account': 5,
      'Final HR Process': 6,
    };

    const newDisableTabs = Array(8).fill(true);
    const activeIndices = tabs.map(tab => tabIndices[tab]);

    activeIndices.forEach(index => {
      if (index !== undefined) {
        newDisableTabs[index] = false;
      }
    });
  
    // Set the active tab to the first active index
    if (activeIndices.length > 0) {
      setActiveTabLocal(activeIndices[0]);
    }

    setDisableTabs(newDisableTabs);
  }, [department, tabs]);

  const handleTabClick = (index) => {
    if (!disableTabs[index]) {
      setActiveTab(index);
      setActiveTabLocal(index);
    }
  };

  const handleTabSubmission = (index) => {
    const updatedDisabledTabs = [...disableTabs];
    updatedDisabledTabs[index] = true;
    setDisableTabs(updatedDisabledTabs);
  };

  const tabTitles = [
    'Employee Info',
    'Employee Job',
    'Classification',
    'IT',
    'Administrative',
    'Account',
    'Final HR Process',
  ];

  const disabledTabClass = styles.disabledTab;

  return (
    <div className={`${styles.grid} ${styles.relative} ${styles['grid-height']}`}>
      <div className={styles.col12}>
        <ul className={styles.tab}>
          {tabTitles.map((title, index) => (
             <li
             key={index}
             
           >
              <a
                href="#"
                className={`
                  ${index === activeTab ? styles.current : ''} 
                  ${disableTabs[index] ? styles.disabledTab : ''} 
                  ${TabStatus[title.replace(/\s+/g, '')] !== "Pending" ? styles.activeTabUnderline : ''}
                `}
                onClick={() => handleTabClick(index)}
                style={disableTabs[index] ? { pointerEvents: 'none', background: '#c5c6c7', color: 'gray' } : {}}
              >
                {title}
                {TabStatus[title.replace(/\s+/g, '')] !== "Pending" && (
                  <span className={styles.checkmark}></span>
                )}
              </a>
            </li>
          ))}
        </ul>
        {activeTab === 0 && <OffBoadingEmployeeDetails isActive={true} authorized={authorized} token={token} employeeId={employeeId} admin={admin} disableTab={disableTabs[0]} onSubmit={() => handleTabSubmission(0)} activeTabIndex={activeTab} department={department} />}
        {activeTab === 1 && <OffBoadingEmployeeJob authorized={authorized} token={token} employeeId={employeeId} admin={admin} disableTab={disableTabs[1]} onSubmit={() => handleTabSubmission(1)} activeTabIndex={activeTab} department={department} />}
        {activeTab === 2 && <OffBoadingClassification authorized={authorized} token={token} employeeId={employeeId} admin={admin} disableTab={disableTabs[2]} onSubmit={() => handleTabSubmission(2)} activeTabIndex={activeTab} department={department} />}
        {activeTab === 3 && <OffBoadingIT authorized={authorized} token={token} employeeId={employeeId} admin={admin} disableTab={disableTabs[3]} onSubmit={() => handleTabSubmission(3)} activeTabIndex={activeTab} department={department} />}
        {activeTab === 4 && <OffBoadingAdministrative authorized={authorized} token={token} employeeId={employeeId} admin={admin} disableTab={disableTabs[4]} onSubmit={() => handleTabSubmission(4)} activeTabIndex={activeTab} department={department} />}
        {activeTab === 5 && <OffBoadingAccount authorized={authorized} token={token} employeeId={employeeId} admin={admin} disableTab={disableTabs[5]} onSubmit={() => handleTabSubmission(5)} activeTabIndex={activeTab} department={department} />}
        {activeTab === 6 && <OffBoadingFinalProcess authorized={authorized} token={token} employeeId={employeeId} admin={admin} disableTab={disableTabs[6]} onSubmit={() => handleTabSubmission(6)} activeTabIndex={activeTab} department={department} />}
      </div>
    </div>
  );
};

export default Tabs;
