import React, { useState, useEffect } from 'react';
import styles from '../Boading.module.scss';
import EmployeeInfo from '../EmployeeInfo/EmployeeInfo';
import OnboadingDetails from '../../api/FetchOnboaingDetail';
import OnboadingSaveEmployeeDetails from '../../api/SaveOnboadingData';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const FinalProcess = ({ isActive, authorized, token, employeeId, setEmployeeId, admin, disableTab, onSubmit, activeTabIndex }) => {

    const [loading, setLoading] = useState(true);
    const [start_date, setStartDate] = useState(null);
    const [buttonsDisabled, setButtonsDisabled] = useState(false);
    const [formData, setFormData] = useState({
        all_documents_collected: false,
        background_check: false,
        training_status: '',
        comment: '',
        sales_marketing: false,
        performance_comment: '',
        sales_marketing_comment: '',
        background_check: false,
        administrative: false,
        administrative_comment: '',
        payroll: false,
        payroll_comment: '',
        it: false,
        performance: false,
        it_comment: '',
        all_hands: false,
        all_heads:false,
        strategy_meetings: false,
        operation:false,
        welcome_email_sent:false
    });

    const [errors, setErrors] = useState({
        all_hands: false,
        all_heads: false,
        operation: false,
        strategy_meetings: false
    });
    
    const [agreement_executed, setAggrement] = useState({
        contract: false,
        offer_letter: false,
        system_agreement: false
    });

    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: checked
        }));
    };


    
    const handleAgreementCheckboxChange = (event) => {
        const { name, checked } = event.target;
        setAggrement(prevState => ({
            ...prevState,
            [name]: checked
        }));
    };
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    useEffect(() => {
        const url = 'get-final-hr-process';
        if (authorized) {
            const fetchData = async () => {
                try {
                    setLoading(true);
                    const employeeData = await OnboadingDetails(url, token, employeeId);
                    if (employeeData.status === 200) {
                        const empDetails = employeeData.final_hr_process;
                        setFormData({
                          all_documents_collected: empDetails.all_documents_collected || false,
                            background_check: empDetails.background_check || false,
                            training_status: empDetails.training_status || '',
                            comment: empDetails.comment || '',
                            sales_marketing: empDetails.sales_marketing || false,
                            performance_comment: empDetails.performance_comment || '',
                            sales_marketing_comment: empDetails.sales_marketing_comment || '',
                            background_check: empDetails.background_check || false,
                            administrative: empDetails.administrative || false,
                            administrative_comment: empDetails.administrative_comment || '',
                            payroll: empDetails.payroll || false,
                            payroll_comment: empDetails.payroll_comment || '',
                            it: empDetails.it || false,
                            performance: empDetails.performance || false,
                            it_comment: empDetails.it_comment || '',
                            strategy_meetings: empDetails.strategy_meetings || false,
                            all_hands: empDetails.all_hands || false,
                            all_heads:empDetails.all_heads || false,
                            operation: empDetails.operation || false,
                            welcome_email_sent:empDetails.welcome_email_sent || false
                            
                        });
                       
                        const agreement_executed = empDetails.agreement_executed;
                        if (agreement_executed) {
                            setAggrement({
                                contract: agreement_executed.contract || false,
                                offer_letter: agreement_executed.offer_letter || false,
                                system_agreement: agreement_executed.system_agreement || false
                            });
                        }

                        if(empDetails.status === 'Submitted'){
                            setButtonsDisabled(true);
                        }
                        setLoading(false);

                    } else {
                        console.error('Error fetching employee data:', employeeData.error);
                    }
                } catch (error) {
                    console.error('Error fetching employee data:', error);
                } finally {
                    setLoading(false);
                }
            };

            fetchData();
        }
    }, [authorized, token, employeeId]);

    const hanldeSaveInfo = async (event) => {
        event.preventDefault();
        const newErrors = {};
         
 
         setErrors(newErrors);
 
         if (Object.keys(newErrors).length > 0) {
             toast.error('Please fill all required fields');
             return;
         }
         if (!formData.comment) {
            toast.error('Please Provide Comment!');
            return;
        }
        try {
            setLoading(true);
            const url = 'save-final-hr-process';

            const requestData = {
                employee_id: employeeId,
                finalhrprocess: {
                    ...formData,
                    
                    agreement_executed: {
                        contract: agreement_executed.contract,
                        offer_letter: agreement_executed.offer_letter,
                        system_agreement: agreement_executed.system_agreement
                    },
                    
                    
                    status: "Pending",
                    created_by: admin,
                }
            };

            const response = await OnboadingSaveEmployeeDetails(url, token, employeeId, requestData);
            if(!response.form_submitted){
                toast.error(response.msg);
               }
               else{
                   toast.success(response.msg);
                   //setButtonsDisabled(true);
               }
        } catch (error) {
            console.error('Error saving form data:', error);
        } finally {
            setLoading(false);
        }
    }

   
    const hanldeSubmit = async (event) => {
        event.preventDefault();
        const newErrors = {};
         
 
         setErrors(newErrors);
 
         if (Object.keys(newErrors).length > 0) {
             toast.error('Please fill all required fields');
             return;
         }
         if (!formData.comment) {
            toast.error('Please Provide Comment!');
            return;
        }
        try {
            setLoading(true);
            const url = 'save-final-hr-process';

            const requestData = {
                employee_id: employeeId,
                finalhrprocess: {
                    ...formData,
                    
                    agreement_executed: {
                        contract: agreement_executed.contract,
                        offer_letter: agreement_executed.offer_letter,
                        system_agreement: agreement_executed.system_agreement
                    },
                    
                    
                    status: "Submitted",
                    created_by: admin,
                }
            };

            const response = await OnboadingSaveEmployeeDetails(url, token, employeeId, requestData);
            if(!response.form_submitted){
                toast.error(response.msg);
               }
               else{
                   toast.success(response.msg);
                   setButtonsDisabled(true);
               }
        } catch (error) {
            console.error('Error saving form data:', error);
        } finally {
            setLoading(false);
        }
    }
    return (
        <div className={styles.employee}>
            <div className={styles.grid}>
                <div className={styles['edit-bg']}>

                </div>

                <EmployeeInfo token={token} employeeId={employeeId} />

                <div className={styles.col9}>
                    <div className={`${styles['box-shadow']} ${styles['border-radius']} `}>
                        <fieldset className={styles.fieldset}>
                            <legend style={{paddingBottom : "20px"}}>Final HR Process</legend>
                            <div className={styles.grid}>
                                <div className={styles.col6}>
                                    <div className={styles['right-space']}>
                                        <ul className={styles['two-col']}>

                                            <li>
                                                <strong>Welcome Email Sent with Documents:</strong>
                                                <span>
                                                    <input type="checkbox" id="w4" name="welcome_email_sent" checked={formData.welcome_email_sent}
                                                        onChange={handleCheckboxChange} />
                                                    <label for="w4"></label>

                                                </span>
                                            </li>

                                            <li><strong>Conduct Background Check:</strong> <span>
                                                <input type="checkbox" id="backgroundCheck" name="background_check" onChange={handleCheckboxChange}
                                                    checked={formData.background_check} />
                                                <label for="backgroundCheck"></label>
                                            </span></li>
                                            <li>
                                                <strong>All Hands:</strong>
                                                <span>
                                                    <input type="checkbox" id="allHand" name="all_hands" value="yes" checked={formData.all_hands}
                                                        onChange={handleCheckboxChange} />
                                                    <label htmlFor="allHand"></label>
                                                </span>
                                            </li>
                                            <li>
                                                <strong>All Heads:</strong>
                                                <span>
                                                    <input type="checkbox" id="allHead" name="all_heads" value="yes" checked={formData.all_heads}
                                                        onChange={handleCheckboxChange} />
                                                    <label htmlFor="allHead" ></label>
                                                </span>
                                            </li>
                                            <li><strong>Operation:<i className="required"></i></strong> <span>
                                                <input type="checkbox" id="Operation" name="operation" value="Operation" checked={formData.operation}
                                                    onChange={handleCheckboxChange} />
                                                <label htmlFor="Operation" ></label>
                                            </span></li>

                                            <li><strong>Strategy Meeting:<i className="required"></i></strong> <span>
                                                <input type="checkbox" id="Strategy" name="strategy_meetings" value="Strategy meeting" checked={formData.strategy_meetings}
                                                    onChange={handleCheckboxChange} />
                                                <label htmlFor="Strategy" ></label>
                                            </span></li>
                                            
                                        </ul>
                                    </div>
                                </div>

                                <div className={styles.col6}>
                                    <div className={styles['left-space']}>
                                        <ul className={styles['two-col']}>
                                            <li><strong>Agreements Executed:</strong> <span>
                                                <input type="checkbox" id="contract" name="contract" checked={agreement_executed.contract}
                                                    onChange={handleAgreementCheckboxChange} />
                                                <label for="contract">Contract</label>

                                                <input type="checkbox" id="offer" name="offer_letter" checked={agreement_executed.offer_letter}
                                                    onChange={handleAgreementCheckboxChange} />
                                                <label for="offer">Offer Letter</label>

                                                <input type="checkbox" id="system" name="system_agreement" checked={agreement_executed.system_agreement}
                                                    onChange={handleAgreementCheckboxChange} />
                                                <label for="system">System Agreement</label>
                                            </span></li>
                                            <li>
                                                <strong>All Documents Gathered and confirmed (From Candidate) </strong>
                                                <span>
                                                    <input type="checkbox" id="alldocument" name="all_documents_collected" onChange={handleCheckboxChange}
                                                        checked={formData.all_documents_collected} />
                                                    <label for="alldocument"></label>
                                                </span>
                                            </li>
                                            <li></li>
                                            <li><strong>Training Status:</strong> <span><textarea name="training_status" value={formData.training_status} onChange={handleChange} className={styles.input}></textarea></span></li>

                                        </ul>
                                    </div>
                                </div>
                                
                                <div className={`${styles.row} ${styles['final-space']} `}>
                                    <fieldset className={`${styles.col8} ${styles['final-spaceIt']} `}>
                                        <legend>IT</legend>
                                        <div>

                                            <ul className={styles['two-col']}>
                                                <li>
                                                    <strong>Verified:</strong>
                                                    <span className={styles.calling}>
                                                        <input
                                                            type="checkbox"
                                                            id="it"
                                                            name="it"
                                                            checked={formData.it}
                                                            onChange={handleCheckboxChange}
                                                        />
                                                        <label htmlFor="it"></label>
                                                    </span>
                                                </li>
                                                <li><strong>Comment:</strong> <span><textarea name="it_comment" className={styles.input} value={formData.it_comment} onChange={handleChange} ></textarea></span></li>

                                            </ul>

                                        </div>
                                    </fieldset>
                                   

                                    <fieldset className={`${styles.col8} ${styles['final-space']} `}>
                                        <legend>Administrative</legend>
                                        <div>

                                            <ul className={styles['two-col']}>
                                                <li>
                                                    <strong>Verified:</strong>
                                                    <span className={styles.calling}>
                                                        <input
                                                            type="checkbox"
                                                            id="administrative"
                                                            name="administrative"
                                                            checked={formData.administrative}
                                                            onChange={handleCheckboxChange}
                                                        />
                                                        <label htmlFor="administrative"></label>
                                                    </span>
                                                </li>
                                                <li><strong>Comment:</strong> <span><textarea name="administrative_comment" className={styles.input} value={formData.administrative_comment} onChange={handleChange} ></textarea></span></li>

                                            </ul>

                                        </div>
                                    </fieldset>
                                    <fieldset className={`${styles.col8} ${styles['final-space']} `}>
                                        <legend>Account</legend>
                                        <div>

                                            <ul className={styles['two-col']}>
                                                <li>
                                                    <strong>Verified:</strong>
                                                    <span className={styles.calling}>
                                                        <input
                                                            type="checkbox"
                                                            id="payroll"
                                                            name="payroll"
                                                            checked={formData.payroll}
                                                            onChange={handleCheckboxChange}
                                                        />
                                                        <label htmlFor="payroll"></label>
                                                    </span>
                                                </li>
                                                <li><strong>Comment:</strong> <span><textarea name="payroll_comment" className={styles.input} value={formData.payroll_comment} onChange={handleChange} ></textarea></span></li>

                                            </ul>

                                        </div>
                                    </fieldset>
                                    <fieldset className={`${styles.col8} ${styles['final-space']} `}>
                                        <legend>Performance</legend>
                                        <div>

                                            <ul className={styles['two-col']}>
                                                <li>
                                                    <strong>Verified:</strong>
                                                    <span className={styles.calling}>
                                                        <input
                                                            type="checkbox"
                                                            id="performance"
                                                            name="performance"
                                                            checked={formData.performance}
                                                            onChange={handleCheckboxChange}
                                                        />
                                                        <label htmlFor="performance"></label>
                                                    </span>
                                                </li>
                                                <li><strong>Comment</strong> <span><textarea name="performance_comment" className={styles.input} value={formData.performance_comment} onChange={handleChange} ></textarea></span></li>

                                            </ul>

                                        </div>
                                    </fieldset>
                                    <div className={styles['right-space']}>
                                    <ul className={`${styles['two-col']}  `}>
                                        <li><strong>Comment<i className={styles['red']}>*</i>:</strong> <span><textarea name="comment" className={styles.input} value={formData.comment} onChange={handleChange} ></textarea></span></li>

                                    </ul>
                                </div>
                                </div>
                                {/* Button block */}
                                <div className={styles['button-block']}>
                                {!buttonsDisabled  && (
                                <input type="submit" value="Save " className={styles.button} onClick={hanldeSaveInfo} disabled={loading || buttonsDisabled} />
                                )}
                                {!buttonsDisabled  && (
                                    <input type="submit" value={buttonsDisabled ? 'Submitted' : 'Submit'} className={styles.button} onClick={hanldeSubmit} disabled={loading || buttonsDisabled}/>
                                )}   
                                    <ToastContainer />
                                </div>
                            </div>
                        </fieldset>
                    </div>
                </div>
            </div>
        </div>
    );
};


export default FinalProcess;
