import React, { useState, useEffect } from 'react';
import styles from '../Boading.module.scss';
import EmployeeDetails from '../EmployeeDetails/EmployeeDetails';
import Classification from '../Classification/Classification';
import IT from '../IT/IT';
import Administrative from '../Administrative/Administrative';
import Payroll from '../Payroll/Payroll';
import FinalProcess from '../FinalHRProcess/FinalHRProcess';
import Performance from '../Performance/Performance';
import OnboardingTabProgress from '../../api/OnboradingTabProgress';



const tabTitles = [
  'Employee Details',
  'Classification',
  'IT',
  'Administrative',
  'Account',
  'Performance',
  'Final HR Process',
];

const Tabs = ({ department, authorized, token, employeeId, setActiveTab, admin, tabs }) => {
  const [activeTab, setActiveTabLocal] = useState(0);
  const [disableTabs, setDisableTabs] = useState(Array(tabTitles.length).fill(false));
  // /const TabStatus = tab_progress[0]; 
  const [TabStatus, setTabStatus] = useState({});

  useEffect(() => {
    const fetchTabProgress = async () => {
      if (token && employeeId) {
        try {
          const tabProgress = await OnboardingTabProgress(token, employeeId);
          console.log(tabProgress.tab_progress[0])
          setTabStatus(tabProgress.tab_progress[0]); 
        } catch (error) {
          console.error('Error fetching tab progress:', error);
        }
      }
    };

    fetchTabProgress();
  }, [token, employeeId]);

  useEffect(() => {
    const tabIndices = {
      'Employee Details': 0,
      'Classification': 1,
      'IT': 2,
      'Administrative': 3,
      'Account': 4,
      'Performance': 5,
      'Final HR Process': 6,
    };

    const newDisableTabs = Array(tabTitles.length).fill(true);
    const activeIndices = tabs.map(tab => tabIndices[tab]);

    activeIndices.forEach(index => {
      if (index !== undefined) {
        newDisableTabs[index] = false;
      }
    });

    if (activeIndices.length > 0) {
      setActiveTabLocal(activeIndices[0]);
    }

    setDisableTabs(newDisableTabs);
  }, [department, tabs]);

  const handleTabClick = (index) => {
    if (!disableTabs[index]) {
      setActiveTab(index);
      setActiveTabLocal(index);
    }
  };

  const handleTabSubmission = (index) => {
    const updatedDisabledTabs = [...disableTabs];
    updatedDisabledTabs[index] = true;
    setDisableTabs(updatedDisabledTabs);
  };

  return (
    <div className={`${styles.grid} ${styles.relative} ${styles['grid-height']}`}>
      <div className={styles.col12}>
        <ul className={styles.tab}>
          {tabTitles.map((title, index) => (
           <li
           key={index}
           
         >
              <a
                href="#"
                className={`
                  ${index === activeTab ? styles.current : ''} 
                  ${disableTabs[index] ? styles.disabledTab : ''} 
                  ${TabStatus[title.replace(/\s+/g, '')] !== "Pending" ? styles.activeTabUnderline : ''}
                `}
                
                onClick={() => handleTabClick(index)}
                style={disableTabs[index] ? { pointerEvents: 'none', background: '#c5c6c7', color: 'gray' } : {}}
              >
                
                {title}
                {TabStatus[title.replace(/\s+/g, '')] !== "Pending" && (
                  <span className={styles.checkmark}></span>
                )}
                
              </a>
            </li>
          ))}
        </ul>
        {activeTab === 0 && <EmployeeDetails isActive={true} authorized={authorized} token={token} employeeId={employeeId} admin={admin} disableTab={disableTabs[0]} onSubmit={() => handleTabSubmission(0)} activeTabIndex={activeTab} department={department} />}
        {activeTab === 1 && <Classification authorized={authorized} token={token} employeeId={employeeId} admin={admin} disableTab={disableTabs[1]} onSubmit={() => handleTabSubmission(1)} activeTabIndex={activeTab} department={department} />}
        {activeTab === 2 && <IT authorized={authorized} token={token} employeeId={employeeId} admin={admin} disableTab={disableTabs[2]} onSubmit={() => handleTabSubmission(2)} activeTabIndex={activeTab} department={department} />}
        {activeTab === 3 && <Administrative authorized={authorized} token={token} employeeId={employeeId} admin={admin} disableTab={disableTabs[3]} onSubmit={() => handleTabSubmission(3)} activeTabIndex={activeTab} department={department} />}
        {activeTab === 4 && <Payroll authorized={authorized} token={token} employeeId={employeeId} admin={admin} disableTab={disableTabs[4]} onSubmit={() => handleTabSubmission(4)} activeTabIndex={activeTab} department={department} />}
        {activeTab === 5 && <Performance authorized={authorized} token={token} employeeId={employeeId} admin={admin} disableTab={disableTabs[5]} onSubmit={() => handleTabSubmission(5)} activeTabIndex={activeTab} department={department} />}
        {activeTab === 6 && <FinalProcess authorized={authorized} token={token} employeeId={employeeId} admin={admin} disableTab={disableTabs[6]} onSubmit={() => handleTabSubmission(6)} activeTabIndex={activeTab} department={department} />}
      </div>
    </div>
  );
};

export default Tabs;
