import axios from 'axios';


const GetCandidateDetails = async (token,candidate_id,view) => {
  
 try {
  
    const response = await axios.post(
      process.env.REACT_APP_API_URL + 'api/employment-form/get-candidate', 
      { token, candidate_id, view}, 
      {
        headers: {
          'Content-Type': 'application/json', 
        },
      }
    );
    return response.data;
  } catch (error) {
    return 1;
    console.error('Error fetching candidate details:', error);
    if (error.response) {
      console.log('Response data:', error.response.data);
    }
    throw error;
  }
};

export default GetCandidateDetails;
