import React, { useEffect, useState } from 'react';
import styles from '../Boading.module.scss';
import OffBoadingEmployeeInfo from '../OffBoadingEmployeeInfo/OffBoadingEmployeeInfo';
import OffboadingEmployeeDetails from '../../api/FetchOffboadingEmployeeDetails';
import OffboadingSaveEmployeeDetails from '../../api/OffboadingSaveEmployeeDetails';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const OffBoadingAdministrative = ({ department, isActive, authorized, token, employeeId, setEmployeeId, admin, disableTab, onSubmit, activeTabIndex }) => {
    const [loading, setLoading] = useState(true);
    const [buttonsDisabled, setButtonsDisabled] = useState(false);
    const [formData, setFormData] = useState({
        books: false,
        books_comment: '',
        return_business_card: false,
        return_business_card_comment: '',
        no_dues: false,
        no_dues_comment: '',
        comment: '',
        id_card_returned: false,
        id_card_returned_no: false,
        id_card_returned_not_applicable: false,
    });

    const [formErrors, setFormErrors] = useState({
        books: '',
        books_comment: '',
        return_business_card: '',
        return_business_card_comment: '',
        no_dues_comment: '',
        comment: '',
        id_card_returned: false,
        id_card_returned_no: false,
        id_card_returned_not_applicable: false,
    });
    const handleCheckboxChange = (event) => {
        const { id, checked } = event.target;
    
        if (checked) {
            setFormData(prevState => {
                const updatedState = {
                    id_card_returned: false,
                    id_card_returned_no: false,
                    id_card_returned_not_applicable: false
                };
                updatedState[id] = true;
                return {
                    ...prevState,
                    ...updatedState,
                    [`${id}_comment`]: checked ? prevState[`${id}_comment`] : ''
                };
            });
        } else {
            setFormData(prevState => ({
                ...prevState,
                [id]: false,
                [`${id}_comment`]: ''
            }));
        }
    
        setFormErrors(prevErrors => ({
            ...prevErrors,
            [id]: '',
            [`${id}_comment`]: ''
        }));
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));

        setFormErrors(prevErrors => ({
            ...prevErrors,
            [name]: ''
        }));
    };

    useEffect(() => {
        const url = 'get-admin';
        if (authorized) {
            const fetchData = async () => {
                try {
                    setLoading(true);
                    const employeeData = await OffboadingEmployeeDetails(url, token, employeeId);
                    
                    if (employeeData.status === 200) {
                        const empDetails = employeeData.data;
                        setFormData({
                            books: empDetails.books || false,
                            books_comment: empDetails.books_comment || '',
                            return_business_card: empDetails.return_business_card || false,
                            return_business_card_comment: empDetails.return_business_card_comment || '',
                            id_card_returned: empDetails.id_card_returned || false,
                            id_card_returned_no: empDetails.id_card_returned_no || false,
                            id_card_returned_not_applicable: empDetails.id_card_returned_not_applicable || false,
                            no_dues: empDetails.no_dues || false,
                            no_dues_comment: empDetails.no_dues_comment || '',
                            comment: empDetails.comment || ''
                        });
                        setLoading(false);
                        if (empDetails.status === 'Submitted') {
                            setButtonsDisabled(true);
                        }

                    } else {
                        console.error('Error fetching employee data:', employeeData.error);
                    }
                } catch (error) {
                    console.error('Error fetching employee data:', error);
                } finally {
                    setLoading(false);
                }
            };

            fetchData();
        }
    }, [authorized, token, employeeId]);

    const validateForm = () => {
        let valid = true;
        const errors = {
            //books: '',
            books_comment: '',
            return_business_card: '',
            return_business_card_comment: '',
            no_dues_comment: '',
            comment: ''
        };

        if (formData.books && !formData.books_comment) {
            toast.error('Books comment is required');
            valid = false;
        }

        if (formData.return_business_card && !formData.return_business_card_comment) {
            toast.error('Business card comment is required');
            valid = false;
        }


        if (formData.no_dues && !formData.no_dues_comment) {
            toast.error('No Dues comment is required');
            valid = false;
        }

        if (!formData.comment) {
            toast.error('Comment is required');
            valid = false;
        }

        // Additional validation for checkboxes
        /*if (formData.books === false) {
            errors.books = 'Books checkbox should be checked';
            valid = false;
        }

        if (formData.return_business_card === false) {
            errors.return_business_card = 'Business Card checkbox should be checked';
            valid = false;
        }*/

        setFormErrors(errors);
        return valid;
    };
    const handleSaveInfo = async (event) => {
        event.preventDefault();

        if (!validateForm()) {
            return;
        }

        try {
            setLoading(true);
            const url = 'admin';
            const requestData = {
                ...formData,
                status: "Pending",
                created_by: admin,

            };

            const response = await OffboadingSaveEmployeeDetails(url, token, employeeId, requestData);
            toast.success('Form data saved successfully!');
            // onSubmit(activeTabIndex);
        } catch (error) {
            console.error('Error saving form data:', error);
        } finally {
            setLoading(false);
        }
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!validateForm()) {
            return;
        }

        try {
            setLoading(true);
            const url = 'admin';
            const requestData = {
                ...formData,
                status: "Submitted",
                created_by: admin,

            };

            const response = await OffboadingSaveEmployeeDetails(url, token, employeeId, requestData);
            toast.success('Form data Submitted successfully!');
            //onSubmit(activeTabIndex);
        } catch (error) {
            console.error('Error saving form data:', error);
        } finally {
            setButtonsDisabled(true);
            setLoading(false);
        }
    }

    return (
        <div className={styles.employee}>
            <div className={styles.grid}>
                <div className={styles['edit-bg']}></div>

                <OffBoadingEmployeeInfo token={token} employeeId={employeeId} />

                <div className={styles.col9}>
                    <div className={`${styles['box-shadow']} ${styles['border-radius']} `}>
                        <fieldset className={styles.fieldset}>
                            <legend>Administrative</legend>
                            <div className={styles.grid} style={{ marginTop: "10px" }}>
                                <div className={styles.col6}>
                                    <div className={styles['right-space']}>
                                        <ul className={styles['two-col']}>
                                            <li><strong>Return business card (if Assign):</strong>
                                                <span>
                                                    <input type="checkbox" id="return_business_card" name="return_business_card" checked={formData.return_business_card} onChange={handleCheckboxChange} />
                                                    <label htmlFor="return_business_card"></label>
                                                    {formData.return_business_card && (
                                                        <textarea name="return_business_card_comment" className={styles.input} value={formData.return_business_card_comment} placeholder="Return Business Comment" onChange={handleChange}></textarea>
                                                    )}
                                                    {formErrors.return_business_card && <div className={styles['empl-error']}>{formErrors.return_business_card}</div>}
                                                    {formErrors.return_business_card_comment && <div className={styles['empl-error']}>{formErrors.return_business_card_comment}</div>}
                                                </span>
                                            </li>
                                            <li><strong>No Dues:</strong>
                                                <span>
                                                    <input type="checkbox" id="no_dues" name="no_dues" checked={formData.no_dues} onChange={handleCheckboxChange} />
                                                    <label htmlFor="no_dues"></label>
                                                    {formData.no_dues && (
                                                        <textarea name="no_dues_comment" className={styles.input} value={formData.no_dues_comment} placeholder="No Dues Comment" onChange={handleChange}></textarea>
                                                    )}
                                                    {formErrors.no_dues && <div className={styles['empl-error']}>{formErrors.no_dues}</div>}
                                                    {formErrors.no_dues_comment && <div className={styles['empl-error']}>{formErrors.no_dues_comment}</div>}
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div className={styles.col6}>
                                    <div className={styles['left-space']}>
                                        <ul className={styles['two-col']}>
                                            <li><strong>Books:</strong>
                                                <span>
                                                    <input type="checkbox" id="books" name="books" checked={formData.books} onChange={handleCheckboxChange} />
                                                    <label htmlFor="books"></label>
                                                    {formData.books && (
                                                        <textarea name="books_comment" className={styles.input} value={formData.books_comment} placeholder="Books Comment" onChange={handleChange}></textarea>
                                                    )}
                                                    {formErrors.books && <div className={styles['empl-error']}>{formErrors.books}</div>}
                                                    {formErrors.books_comment && <div className={styles['empl-error']}>{formErrors.books_comment}</div>}
                                                </span>
                                            </li>
                                            <li><strong>ID Card Returned:</strong>
                                                <span>
                                                    <input type="checkbox" id="id_card_returned" name="id_card_returned" checked={formData.id_card_returned} onChange={handleCheckboxChange} />
                                                    <label htmlFor="id_card_returned">Yes</label>
                                                    <input type="checkbox" id="id_card_returned_no" name="id_card_returned_no" checked={formData.id_card_returned_no} onChange={handleCheckboxChange} />
                                                    <label htmlFor="id_card_returned_no">No</label>
                                                    <input type="checkbox" id="id_card_returned_not_applicable" name="id_card_returned_not_applicable" checked={formData.id_card_returned_not_applicable} onChange={handleCheckboxChange} />
                                                    <label htmlFor="id_card_returned_not_applicable">Not Applicable</label>

                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div className={styles['right-space']}>
                                    <ul className={styles['two-col']}>
                                        <li><strong>Comment<i className={styles['red']}>*</i>:</strong>
                                            <span>
                                                <textarea name="comment" className={styles.input} value={formData.comment} onChange={handleChange}></textarea>
                                            </span>
                                            {formErrors.comment && <div className={styles['empl-error']}>{formErrors.comment}</div>}
                                        </li>
                                    </ul>
                                </div>

                                <div className={styles['button-block']}>
                                {!buttonsDisabled && department !== "Human Resources" && (
                                    <input type="submit" value="Save" className={styles.button} onClick={handleSaveInfo} disabled={loading || buttonsDisabled || department === "Human Resources"} />
                                )}
                                  {!buttonsDisabled && department !== "Human Resources" && ( 
                                    <input type="submit" value={buttonsDisabled ? 'Submitted' : 'Submit'} className={styles.button} onClick={handleSubmit} disabled={loading || buttonsDisabled || department === "Human Resources"} />
                                  )} 
                                    <ToastContainer />
                                </div>
                            </div>
                        </fieldset>
                    </div>
                </div>
                {loading && <div className={`${styles['loader-center']} ${styles['loaderHeight-sales']} `}>
                    <div className={`${styles['newsloader-container']} ${styles['newsloaderContainer-payroll']} `}>
                        <div className={styles.newsloader}></div>
                    </div>
                </div>}
            </div>
        </div>
    );
};

export default OffBoadingAdministrative;
