import React, { useState, useEffect, useRef } from 'react';
import { useToken } from '../../context/AuthContext'
import styles from './Admin.module.scss';
import User from '../../assets/images/icon-user.svg';
import Logo from '../../assets/images/logo.svg';
import Table from '../../components/Table/Table';
import CandidateList from '../../api/CandidateList';
import Copy from '../../assets/images/copy.svg';
import View from '../../assets/images/view.svg';
import Email from '../../assets/images/email.svg';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CandidateDetail from './CandidateDetail';
import FeedbackDetail from './FeedbackDetail';
import AdminAuth from '../../api/AdminAuth';
import top_button from '../../assets/images/top_image.png';
import EmployeeListImage from '../../assets/images/Onborading.png'
import Offboading from '../../assets/images/Offborading.png'
import { Helmet } from 'react-helmet-async';
import EmployeeDetail from './EmployeeDetail';
import { useNavigate } from 'react-router-dom';
import OffboadingEmployeeDetail from './OffboadingEmployeeDetail';
import user_in from '../../assets/images/user_in.svg'
import Archive from '../../assets/images/archive.svg'
import Interview from '../../assets/images/Calendar-Time.svg'
import DatePicker from "react-datepicker";
import Select from 'react-select';
import { format, parseISO } from 'date-fns';

import Calander from '../../assets/images/calendar.svg'
const initialFormData = {
    punch_card: '',
    interview_time: null,
    interview_date: null,
    department: '',
    first_name: '',
    department: '',


};
const AdminDashboard = () => {
    const { token } = useToken();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pageSize, setPageSize] = useState(10);
    const [pagination, setPagination] = useState(true);
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [sortState, setSortState] = useState({});
    const [searchQuery, setSearchQuery] = useState('');
    const currentYear = new Date().getFullYear();
    const [showCandidateDetail, setCandidateDetail] = useState(false);
    const [showFeedback, setFeedback] = useState(false);
    const [tokenValue, settoken] = useState(null);
    const [showPopup, setShowPopup] = useState(false);
    const [authorized, setAuthorized] = useState(false);
    const [adminName, setadminName] = useState();
    const [deptname, setDeptname] = useState('');
    const [disableTabs, setDisableTabs] = useState([false, false]);
    const [showUnoutherizedModal, setShowUnoutherizedModal] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [authenticateToken, setauthenticateToken] = useState(null);
    const [error, setError] = useState(false);
    const [dashboardTabs, setDashboardTabs] = useState([]);
    const [tabVisibility, setTabVisibility] = useState([true, true, true]);
    const [showCopyButton, setshowCopyButton] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [time, setTime] = useState(null);
    const [punchCards, setPunchCards] = useState([]);
    const [message, setMessage] = useState('');
    const [messageType, setMessageType] = useState('');
    const [selectedPunchCard, setSelectedPunchCard] = useState('');
    const tabTitles = [
        'Candidate',
        'Onboarding',
        'Offboarding',


    ];
    const tabIndices = {
        'Candidate': 0,
        'Onboarding': 1,
        'Offboarding': 2,
    };
    const urlParams = new URLSearchParams(window.location.search);
    const activeTabs = urlParams.get('activeTab');

    const [activeTab, setActiveTab] = useState(0);
    const navigate = useNavigate();

    const activeTabRef = useRef(activeTab);
    const handleTabClick = (index) => {
        if (tabVisibility[index]) {
            setActiveTab(index);
        }
    };
    const [formData, setFormData] = useState(initialFormData);

    const handleInterviewList = () => {

        if(deptname === 'Human Resources'){
            navigate(`/candidate-interview-list?token=`+authenticateToken);
        }else{
            navigate(`/today-interview`, { replace: true });
        }

        
    }


    useEffect(() => {
        const checkAuthorization = async () => {
            try {
                const authResult = await AdminAuth(token);
                if (authResult && authResult.status === 200 && authResult.authenticate) {
                    setAuthorized(true);

                    const activeTabsParam = parseInt(urlParams.get('activeTab'), 10) || 0;
                    setDashboardTabs(authResult.dashboard_tabs || []);
                    const dashboardTabs = authResult.dashboard_tabs || [];
                    const tabIndices = {
                        'Candidate': 0,
                        'Onboarding': 1,
                        'Offboarding': 2,
                    };

                    const newDisableTabs = Array(3).fill(true);
                    dashboardTabs.forEach(tab => {
                        const index = tabIndices[tab];
                        if (index !== undefined) {
                            newDisableTabs[index] = false;
                        }
                    });

                    const newTabVisibility = newDisableTabs.map(disabled => !disabled);

                    let newActiveTab = 0;

                    if (activeTabsParam !== undefined && !newDisableTabs[activeTabsParam]) {
                        newActiveTab = activeTabsParam;
                    } else {
                        const firstVisibleTabIndex = newTabVisibility.findIndex(visible => visible);
                        if (firstVisibleTabIndex !== -1) {
                            newActiveTab = firstVisibleTabIndex;
                        }
                    }

                    setActiveTab(newActiveTab);
                    setadminName(authResult.user_name);
                    setDeptname(authResult.department);
                    setauthenticateToken(authResult.token);
                    setDisableTabs(newDisableTabs);
                    setTabVisibility(newTabVisibility);
                    setShowUnoutherizedModal(false);
                    setshowCopyButton(authResult.copied)
                } else {
                    setAuthorized(false);
                    setShowUnoutherizedModal(true);
                }
            } catch (error) {
                setAuthorized(false);
                setShowUnoutherizedModal(true);
                console.error('Error checking authorization:', error);
            }
        };

        checkAuthorization();
    }, [token]);

    const fetchData = async () => {
        try {
            setLoading(true);
            const candidateData = await CandidateList(authenticateToken);
            setData(candidateData.data);
            const pages = Math.ceil(candidateData.data.length / pageSize);
            setTotalPages(pages);
        } catch (error) {
            console.error('Error fetching candidate data:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (authorized) {
            fetchData();
        }
    }, [authorized]);

    useEffect(() => {
        const fetchPunchCardList = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}api/interview/punch-card-list`, {
                    headers: {
                        'token': authenticateToken,
                        'accept': 'application/json',
                    },
                });
               
                if (response.data) {
                    setPunchCards(response.data.data);

                } else {
                    console.log(response.msg);
                }
            } catch (error) {
                console.error('Error fetching punch card list:', error);

            }
        };

        if (authorized) {
            fetchPunchCardList();
        }
    }, [authorized]);

    const handleStartDateChange = (date) => {
        setFormData({ ...formData, start_date: date });
    };

    const handleInterviewDateChange = (date) => {
        const formattedDate = format(date, 'yyyy-MM-dd');
        setFormData({ ...formData, interview_date: formattedDate });
    };
    const handleInterviewCopy = async () => {
        setLoading(true);
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}api/interview/get-candidates-list-link`,

                {
                    headers: {
                        'token': authenticateToken,
                        'accept': 'application/json',
                    },
                }
            );

            if (response.data.data) {
                setLoading(false);
                let dataToCopy = response.data.data;
                dataToCopy = dataToCopy.replace(/"/g, '');
                navigator.clipboard.writeText(dataToCopy)
                    .then(() => {
                        toast.success('Interview Candidate link copied successfully', {
                            duration: 4000,
                        });

                    })
                    .catch((error) => {
                        toast.error('Error copying to clipboard', {
                            duration: 4000,
                        });
                    });
            } else {
                toast.error('Failed to fetch data from the API', {
                    duration: 4000,
                });
            }
        } catch (error) {
            console.error('Error fetching data from the API:', error);
            toast.error('Error fetching data from the API', {
                duration: 4000,
            });
        }
    }

    const handleCopy = async (candidateId) => {
        setLoading(true);
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}api/employment-form/raise-employment-form`,
                {
                    candidate_id: candidateId,
                    email: false,
                },
                {
                    headers: {
                        'token': authenticateToken,
                        'accept': 'application/json',
                    },
                }
            );
            if (response.data) {
                setLoading(false);
                let dataToCopy = response.data.employment_form_link;
                dataToCopy = dataToCopy.replace(/"/g, '');
                navigator.clipboard.writeText(dataToCopy)
                    .then(() => {
                        toast.success('Employment form link copied successfully', {
                            duration: 4000,
                        });
                    })
                    .catch((error) => {
                        toast.error('Error copying to clipboard', {
                            duration: 4000,
                        });
                    });
            } else {
                toast.error('Failed to fetch data from the API', {
                    duration: 4000,
                });
            }
        } catch (error) {
            console.error('Error fetching data from the API:', error);
            toast.error('Error fetching data from the API', {
                duration: 4000,
            });
        }
    };

    const handleFeedbackCopy = async (candidateId) => {
        setLoading(true);
        try {

            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}api/feedback/generate-feedback-form`,
                {
                    candidate_id: candidateId,
                    survey_id: 1,
                    email: false,
                },
                {
                    headers: {
                        'token': authenticateToken,
                        'accept': 'application/json',
                    },
                }
            );
            if (response.data) {
                setLoading(false);
                let dataToCopy = response.data.feedback_form_link;
                dataToCopy = dataToCopy.replace(/"/g, '');
                navigator.clipboard.writeText(dataToCopy)
                    .then(() => {
                        toast.success('Feedback form link copied successfully', {
                            duration: 4000,
                        });
                    })
                    .catch((error) => {
                        toast.error('Error copying to clipboard', {
                            duration: 4000,
                        });
                    });
            } else {
                toast.error('Failed to fetch data from the API', {
                    duration: 4000,
                });
            }
        } catch (error) {
            console.error('Error fetching data from the API:', error);
            toast.error('Error fetching data from the API', {
                duration: 4000,
            });
        }
    };




    const sendEmail = async (candidateId, email) => {

        const confirmed = window.confirm('Employment form link will be send to ' + email);

        if (!confirmed) {
            return;
        }
        setLoading(true);
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}api/employment-form/raise-employment-form`,
                {
                    candidate_id: candidateId,
                    email: true,
                },
                {
                    headers: {
                        'token': authenticateToken,
                        'accept': 'application/json',
                    },
                }
            );
            if (response.data) {
                setLoading(false);
                toast.success(response.data.msg, {
                    duration: 4000,
                });
            } else {
                toast.success('Failed to send email', {
                    duration: 4000,
                });
            }
        } catch (error) {
            console.error('Error sending email:', error);
        }
    };



    const sendFeedbackEmail = async (candidateId, email) => {

        const confirmed = window.confirm('Feedback form link will be send to ' + email);

        if (!confirmed) {
            return;
        }
        setLoading(true);
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}api/feedback/generate-feedback-form`,
                {
                    candidate_id: candidateId,
                    survey_id: 1,
                    email: true,
                },
                {
                    headers: {
                        'token': authenticateToken,
                        'accept': 'application/json',
                    },
                }
            );
            if (response.data) {
                setLoading(false);
                toast.success(response.data.msg, {
                    duration: 4000,
                });
            } else {
                toast.success('Failed to send email', {
                    duration: 4000,
                });
            }
        } catch (error) {
            console.error('Error sending email:', error);
        }
    };

    function handleSort(column) {
        const sortedData = [...data];
        const currentSort = sortState[column] || "asc";
        sortedData.sort((a, b) => {
            if (currentSort === "asc") {
                return a[column] > b[column] ? 1 : -1;
            } else {
                return a[column] < b[column] ? 1 : -1;
            }
        });
        const newSortState = {
            ...sortState,
            [column]: currentSort === "asc" ? "desc" : "asc"
        };
        setData(sortedData);
        setSortState(newSortState);
    }
    const handleSearch = (query) => {
        setSearchQuery(query);

        const filteredData = data.filter((item) => {
            let status = '';

            if (item.Employment_Form_Status === 'Pending') {
                item.Employment_Form_Status = 'Processing' + (item.Process_Status ? `(${item.Process_Status})` : '');
            } else {
                status = 'Submitted';
            }

            const isMatchingSearchQuery = (
                (item.First_Name && item.First_Name.toLowerCase().includes(query.toLowerCase())) ||
                (item.Department && item.Department.toLowerCase().includes(query.toLowerCase())) ||
                (item.Email && item.Email.toLowerCase().includes(query.toLowerCase())) ||
                (item.Employment_Form_Status && item.Employment_Form_Status.toLowerCase().includes(query.toLowerCase()))
            );

            if (deptname === 'Human Resources') {
                return isMatchingSearchQuery;
            } else {
                return item.Department === deptname && isMatchingSearchQuery;
            }
        });

        const pages = Math.ceil(filteredData.length / pageSize);
        setTotalPages(pages);
    };




    const filteredData = data.filter((item) => {
        if (item.Employment_Form_Status === 'Pending') {
            item.Employment_Form_Status = 'Processing' + (item.Process_Status ? `(${item.Process_Status})` : '');
        }

        const isMatchingSearchQuery = (
            item.First_Name && item.First_Name.toLowerCase().includes(searchQuery.toLowerCase()) ||
            item.Department && item.Department.toLowerCase().includes(searchQuery.toLowerCase()) ||
            item.Email && item.Email.toLowerCase().includes(searchQuery.toLowerCase()) ||
            item.Employment_Form_Status && item.Employment_Form_Status.toLowerCase().includes(searchQuery.toLowerCase())
        );

        if (deptname === 'Human Resources') {
            return isMatchingSearchQuery;
        } else {
            return item.Department === deptname && isMatchingSearchQuery;
        }
    });




    const handleChangePage = (page) => {
        setCurrentPage(page);
    };

    const handleClosePopup = () => {
        setShowPopup(false);
        setCandidateDetail(false);
        setFeedback(false);
    };
    const handlePreviewData = async (candidateId) => {

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}api/employment-form/raise-employment-form`,
                {
                    candidate_id: candidateId,
                    email: false,
                },
                {
                    headers: {
                        'token': authenticateToken,
                        'accept': 'application/json',
                    },
                }
            );
            if (response.data) {
                settoken(response.data.employment_form_link);
                setShowPopup(true);
                setCandidateDetail(true);
            } else {
                
            }
        } catch (error) {
            console.error('Error fetching data from the API:', error);
            
        }
    };

    const handleFeedbackPreview = async (candidateId) => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}api/feedback/generate-feedback-form`,
                {
                    candidate_id: candidateId,
                    survey_id: 1,
                    email: false,
                },
                {
                    headers: {
                        'token': authenticateToken,
                        'accept': 'application/json',
                    },
                }
            );

            if (response.data) {
                settoken(response.data.feedback_form_link);
                setShowPopup(true);
                setFeedback(true);
            } else {
                
            }
        } catch (error) {
            console.error('Error fetching data from the API:', error);
            
        }


    }
    const handleOnboadingData = async (candidateId) => {
        setLoading(true);
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}api/onboarding/copy-candidate-to-onboarding`,
                {
                    zoho_item_id: candidateId

                },
                {
                    headers: {
                        'token': authenticateToken,
                        'accept': 'application/json',
                    },
                }
            );

            if (response.data) {
                setLoading(false);
                toast.success(response.data.msg, {
                    duration: 4000,
                });
            } else {
                toast.error('There is no token for selected candidate', {
                    duration: 4000,
                });
            }
        } catch (error) {
            console.error('Error fetching data from the API:', error);
            toast.error('Error fetching data from the API', {
                duration: 4000,
            });
        }


    }
    const handleArchived = async (candidateId) => {
        const confirmArchive = window.confirm("Do you want to archive this candidate?");

        if (!confirmArchive) {
            return;
        }
        setLoading(true);
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}api/employment-form/candidate-archive`,
                {
                    user_id: candidateId,
                    'archive': true

                },
                {
                    headers: {
                        'token': authenticateToken,
                        'accept': 'application/json',
                    },
                }
            );

            if (response.data) {
                setLoading(false);
                await fetchData();
                toast.success(response.data.msg, {
                    duration: 4000,
                });
            } else {
                toast.error('There is no token for selected candidate', {
                    duration: 4000,
                });
            }
        } catch (error) {
            console.error('Error fetching data from the API:', error);
            toast.error('Error fetching data from the API', {
                duration: 4000,
            });
        }


    }

    const handleInterviewScheduled = (candidate, department, first_name, interview_date, interview_time, punch_card) => {
        setMessage('');
        if (interview_time) {
            const [hours, minutes] = interview_time.split(':');
            const date = new Date(interview_date);
            date.setHours(parseInt(hours, 10), parseInt(minutes, 10), 0);
            setFormData({
                ...initialFormData,
                candidate_id: candidate,
                first_name: first_name,
                department: department,
                interview_date: interview_date,
                interview_time: date,
                punch_card: punch_card
            });

            setIsModalOpen(true);
        } else {
            setFormData({
                ...initialFormData,
                candidate_id: candidate,
                first_name: first_name,
                department: department,
                interview_date: interview_date,
                interview_time: interview_time,
                punch_card: punch_card
            });

            setIsModalOpen(true);
        }

    }


    const columns = [
        { title: 'Name', dataKey: 'First_Name', sortable: true, minwidth: 178 },
        { title: 'Department', dataKey: 'Department', sortable: true, minwidth: 178 },
        {
            title: 'Employment Status',
            dataKey: 'Employment_Form_Status',
            sortable: true,

            minwidth: 220,
        },
        {
            title: 'Feedback Status',
            dataKey: 'Feedback_Form_Status',
            sortable: true,

            minwidth: 190,
        },
        {
            title: 'Application Form',
            dataKey: 'Zoho_Item_Id',
            renderer: (rowData) => (
                <div className={styles.toolTip} key={rowData.Zoho_Item_Id}>
                    <button
                        onClick={() => handlePreviewData(rowData.Zoho_Item_Id)}>
                        <img src={View} alt="First Action" width="20" height="20" />

                    </button>
                    {showCopyButton && (
                        <>
                            <button
                                onClick={() => handleCopy(rowData.Zoho_Item_Id)}>
                                <img src={Copy} alt="First Action" width="20" height="20" />

                            </button>
                            <button
                                onClick={() => sendEmail(rowData.Zoho_Item_Id, rowData.Email)}>
                                <img src={Email} alt="Second Action" width="20" height="20" />

                            </button>
                        </>
                    )}
                </div>
            ),
            minwidth: 190,
        },

        {
            title: 'Feedback Form',
            dataKey: 'Zoho_Item_Id',
            renderer: (rowData) => (
                
                <div className={styles.toolTip} key={rowData.Zoho_Item_Id}>
                    <button
                        onClick={() => handleFeedbackPreview(rowData.Zoho_Item_Id)}
                    >
                        <img src={View} alt="First Action" width="20" height="20" />

                    </button>
                    
                    {showCopyButton && (
                        <>
                            <button
                                onClick={() => handleFeedbackCopy(rowData.Zoho_Item_Id)}
                            >
                                <img src={Copy} alt="First Action" width="20" height="20" />

                            </button>
                            <button
                                onClick={() => sendFeedbackEmail(rowData.Zoho_Item_Id, rowData.Email)}>
                                <img src={Email} alt="Second Action" width="20" height="20" />

                            </button>
                        </>
                    )}
                </div>
            ),
            minwidth: 182,
        },
        {
            title: 'Action',
            dataKey: 'Id',
            renderer: (rowData) => (
                <div className={styles.toolTip} key={rowData.Zoho_Item_Id}>
                    <button
                        className={`${rowData.Employment_Form_Status !== 'Submitted' ? styles.disabledButton : ''} ${styles.hiredimg}`}
                        onClick={() => handleOnboadingData(rowData.Zoho_Item_Id)}
                        disabled={rowData.Employment_Form_Status !== 'Submitted'}

                    >
                        <img src={user_in} alt="Hired Action" width="20" height="20" />
                    </button>
                    <button
                        onClick={() => handleArchived(rowData.Id)}
                        className={styles.achivedUser}
                    >
                        <img src={Archive} alt="Archived User" width="20" height="20" />
                    </button>
                    <button
                        onClick={() => handleInterviewScheduled(rowData.Id, rowData.Department, rowData.First_Name, rowData.interview_date, rowData.interview_time, rowData.punch_card)}
                        className={styles.interviewButton}
                    >
                        <img src={Interview} alt="Interview Action" width="20" height="20" />
                    </button>
                </div>
            ),
            minwidth: 190,
        },
    ];
    const validateInput = () => {
        // Custom validation logic
        return searchValue.trim() !== '';
    };

    const handleZohoCandidate = async () => {
        setLoading(true);
        if (validateInput()) {
            try {

                const response = await axios.post(
                    `${process.env.REACT_APP_API_URL}api/employment-form/talent-trigger-employment-forms?candidate_id=${searchValue}`,

                    {
                        headers: {
                            'Content-Type': 'application/json'
                        },
                    }
                );

                if (response.data) {
                    toast.success(response.data.msg, {
                        duration: 1000,
                    });
                    fetchData();
                } else {
                    setError(true);
                    toast.error('Failed to create candidate. Please enter valid candidate Id.', {
                        duration: 1000,
                    });
                }
            } catch (error) {
                setError(true);
                toast.error('An error occurred while processing the request.', {
                    duration: 1000,
                });
            } finally {
                setLoading(false);
            }
        } else {
            setError(true);
            toast.error('Please enter a valid Zoho Candidate Id.', {
                duration: 1000,
            });
            setLoading(false);
        }
    };


    const handleInputChange = (e) => {
        setSearchValue(e.target.value);
        setError(false);
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleZohoCandidate();
        }
    };

    const redirectToBlitz = () => {
        window.location.href = 'https://blitz.bluent.it/';
    };
    const disabledTabClass = styles.disabledTab;

    const handleSaveInterview = async (e) => {
        e.preventDefault();
        const { first_name, department, interview_date, interview_time, candidate_id, punch_card } = formData;
        let interviewTimeString = null;
        if (interview_time) {
            const dateObj = new Date(interview_time);
            interviewTimeString = dateObj.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false });
        }

        if (!first_name || !department || !interview_date || !interviewTimeString || !punch_card) {
            setMessage('Please fill all Interview Schedule\'s fields.');
            setMessageType('error');
            return;
        }
        const dataToSend = {
            candidate_id: candidate_id,
            candidate_name: first_name,
            department: department,
            punch_card: punch_card,
            interview_date: interview_date,
            interview_time: interviewTimeString,
            created_by: adminName,
        };

        setLoading(true);

        try {
            const response = await axios.post(process.env.REACT_APP_API_URL + 'api/interview/interview-schedule', dataToSend, {
                headers: {
                    'Content-Type': 'application/json',
                    'token': authenticateToken
                },
            });
            //if (response.status == 200) {
            setMessage(response.data.message);
            await fetchData();
            setMessageType('success');
            setFormData(initialFormData);

            if (response.status === 200) {
                setTimeout(() => {
                    setIsModalOpen(false);
                }, 4000);
            } else if (response.status === 404) {
                 setIsModalOpen(true);
            }
            


        } catch (error) {
            setMessage('Error scheduling interview: ' + error.message);
            setMessageType('error');
        } finally {
            setLoading(false);
            
        }

    }

    const handleSelectChange = (selectedOption) => {
        setFormData(prev => ({
            ...prev,
            punch_card: selectedOption ? selectedOption.value : ''
        }));
    };

    const handleDateChange = (date) => {
        setFormData(prev => ({
            ...prev,
            interview_time: date
        }));
    };
    const punchcardOptions = punchCards.map(card => ({
        value: card.card_name,
        label: card.card_name,
    }));

    return (
        <>
            <Helmet>
                <title>Candidate List</title>

            </Helmet>

            {showUnoutherizedModal && (
                <>
                    {redirectToBlitz()}
                </>

            )}
            {!showUnoutherizedModal && (
                <section className={`${styles['box-shadow']} ${styles.container}`}>
                    <>
                    {showCandidateDetail ? (
                        <CandidateDetail url={tokenValue} onClose={handleClosePopup} />
                    ) : showFeedback ? (
                        <FeedbackDetail url={tokenValue} onClose={handleClosePopup} />
                    ) : (
                        <>
                            <div className={styles.header}>
                                <div className={styles.grid}>
                                    <div className={styles.col12}>
                                        <ul>
                                            <li className={styles['li-first']}>
                                                <div className={styles.logo}>
                                                    <img src={Logo} alt="Logo" width="195" height="42" />
                                                </div>
                                            </li>
                                            {activeTab === 0 && (
                                                <li className={styles['li-third']}>
                                                    <div className={styles['align-center']}><img src={top_button} alt="Logo" width="195" height="42" />
                                                    </div>
                                                </li>
                                            )}
                                            {activeTab === 1 && (
                                                <li className={styles['li-third']}>
                                                    <div className={styles['align-center']}><img src={EmployeeListImage} alt="Logo" width="195" height="42" />
                                                    </div>
                                                </li>
                                            )}
                                            {activeTab === 2 && (
                                                <li className={styles['li-third']}>
                                                    <div className={styles['align-center']}><img src={Offboading} alt="Logo" width="195" height="42" />
                                                    </div>
                                                </li>
                                            )}
                                            <li className={styles['li-fifth']}>
                                                <div className={styles.admin}>
                                                    <img src={User} alt="User" width="25" height="25" />{adminName}
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            {activeTab === 0 && (
                                <>

                                    <div className={styles.grid} style={{ marginTop: '10px' }}>


                                        <div className={styles.col4}>
                                            <ul className={styles.tab}>
                                                {tabTitles.map((title, index) => (
                                                    <li key={index}>
                                                        <a
                                                            href="#"
                                                            className={`${index === activeTab ? styles.current : ''} ${disableTabs[index] ? disabledTabClass : ''}`}
                                                            onClick={() => handleTabClick(index)}
                                                            style={disableTabs[index] ? { pointerEvents: 'none', background: '#c5c6c7', color: 'gray' } : {}}
                                                        >
                                                            {title}
                                                        </a>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                        <div className={styles.col2}>
                                            <div className={`${styles['calander-div']}`}>

                                                <button onClick={handleInterviewList} className={styles['calander-button']}>

                                                </button>
                                            </div>
                                        </div>
                                        <div className={styles.col3}>
                                            <div className={`${styles['search-icon']} ${styles['search-space-top']}`}>
                                                <input type="search" onChange={(e) => handleSearch(e.target.value)} className={styles['search-field']} placeholder="Search …" value={searchQuery} name="s" title="Search for:" />
                                            </div>
                                        </div>

                                        <div className={styles.col3}>
                                            <div className={`${styles['zoho-search']} ${styles['zoho-search-top']}`}>
                                                <input
                                                    type="search"
                                                    onChange={handleInputChange}
                                                    //onKeyPress={handleKeyPress}
                                                    className={`${styles.input} ${error ? styles.invalidInput : ''}`}
                                                    value={searchValue}
                                                    placeholder='Zoho Candidate Id'
                                                    required
                                                />
                                                <button onClick={handleZohoCandidate} className={styles['zoho-button']}>
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={`${styles.grid} ${styles['grid-height']}`}>
                                        <div className={styles['dashboard-table1']}>
                                            <Table
                                                data={filteredData}
                                                columns={columns}
                                                pageSize={pageSize}
                                                onSort={handleSort}
                                                loading={loading}
                                                pagination={pagination}
                                                onChangePage={handleChangePage}
                                                total={totalPages}
                                                activePage={currentPage}
                                                limit={totalPages}
                                                minWidth={20}
                                            />
                                        </div>
                                    </div>
                                    <ToastContainer />
                                </>
                            )}
                            {activeTab === 1 && (
                                <EmployeeDetail deptname={deptname} activeTab={activeTab} setActiveTab={setActiveTab} authenticateToken={authenticateToken} tabs={dashboardTabs} />
                            )}
                            {activeTab === 2 && (
                                <OffboadingEmployeeDetail deptname={deptname} activeTab={activeTab} setActiveTab={setActiveTab} authenticateToken={authenticateToken} tabs={dashboardTabs} showCopyButton={showCopyButton} />
                            )}
                            <div className={styles.grid}>
                                <div className={styles.footer}>
                                    &copy; {currentYear}. BluEnt All Rights Reserved.
                                </div>
                            </div>
                            
                        </>
                    )}
                </>
                </section>
            )}
            {isModalOpen && (

                <div className={styles.rejectpopup}>
                    <>
                        <div className={styles.rejectpopupContent}>
                            <button className={styles.closeIntervieButton} onClick={() => setIsModalOpen(false)}>
                                &times;
                            </button>
                            <div className={styles.employee}>
                                {formData && (
                                    <>
                                        <div className={styles.col9}>
                                            <fieldset className={styles.fieldset}>
                                                <form id={styles.basicINFO} name="basicINFO">
                                                    <legend style={{ paddingBottom: '20px' }}>Interview Schedule</legend>
                                                    <div className={styles.grid}>
                                                        <div className={`${styles.col6} ${styles.interviewCol}`}>
                                                            <div className={styles['right-space']}>
                                                                <input type="hidden" name="candidate_id" value={formData.candidate_id} />
                                                                <ul className={styles['two-col']}>
                                                                    <li>
                                                                        <strong>Candidate Name:</strong>
                                                                        <span>
                                                                            <input
                                                                                type="text"
                                                                                name="first_name"
                                                                                className={styles.input}
                                                                                value={formData.first_name}
                                                                                onChange={handleInputChange}
                                                                            />
                                                                        </span>
                                                                    </li>
                                                                    <li>
                                                                        <strong>Department:</strong>
                                                                        <span>
                                                                            <input
                                                                                type="text"
                                                                                name="department"
                                                                                className={styles.input}
                                                                                value={formData.department}
                                                                                onChange={handleInputChange}
                                                                                required
                                                                                placeholder="Enter department name"
                                                                            />
                                                                        </span>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className={`${styles.col6} ${styles.interviewCol}`} style={{ float: 'right' }}>

                                                            <div className={styles['left-space']}>
                                                                <ul className={styles['two-col']}>
                                                                    <li>
                                                                        <strong>Interview Date<i className={styles['red']}>*</i>:</strong>
                                                                        <span>
                                                                            <DatePicker
                                                                                selected={formData.interview_date}
                                                                                onChange={handleInterviewDateChange}
                                                                                peekNextMonth
                                                                                showMonthDropdown
                                                                                showYearDropdown
                                                                                dropdownMode="select"
                                                                                className={styles.input}
                                                                                id="interview_date"
                                                                                name="interview_date"
                                                                                autoComplete="off"
                                                                                required
                                                                                placeholderText="Select interview date"
                                                                            />

                                                                        </span>
                                                                    </li>
                                                                    <li>
                                                                        <strong>Interview Time<i className={styles['red']}>*</i>:</strong>
                                                                        <span>
                                                                            <DatePicker
                                                                                selected={formData.interview_time}
                                                                                onChange={handleDateChange}
                                                                                showTimeSelect
                                                                                showTimeSelectOnly
                                                                                timeIntervals={15}
                                                                                timeCaption="Time"
                                                                                dateFormat="h:mm aa"
                                                                                className={styles.input}
                                                                                placeholderText="Select interview time"
                                                                            />
                                                                        </span>
                                                                    </li>
                                                                    <li>
                                                                        <strong>Punch Card<i className={styles['red']}>*</i>:</strong>
                                                                        <span> <Select value={punchcardOptions.find(option => option.value === formData.punch_card) || null}
                                                                            onChange={handleSelectChange}
                                                                            options={punchcardOptions}
                                                                            placeholder="Select Punch Card"
                                                                            name="punch_card"
                                                                        /></span>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className={styles['right-space']} style={{ float: 'right' }}>
                                                            <ul className={styles['two-col9']}>
                                                                <li>
                                                                    <strong style={{ visibility: 'hidden' }}>Comment</strong>
                                                                    <span>

                                                                    </span>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        {/* Button block */}
                                                        <div className={styles['button-block']}>

                                                            {message && (
                                                                <div className={messageType === 'success' ? styles.successMessage : styles.errorMessage}>
                                                                    {message}
                                                                </div>
                                                            )}
                                                            <input type="submit" value="Save" className={styles.button} disabled={loading} onClick={handleSaveInterview} />


                                                        </div>
                                                    </div>
                                                    <input type="hidden" name="created_by" className={styles.input} value={formData.created_by} />
                                                </form>
                                            </fieldset>
                                        </div>
                                        {loading && (
                                            <div className={`${styles['loader-center']} ${styles['loaderHeight-classification']} `}>
                                                <div className={`${styles['newsloader-container']} ${styles['newsloaderContainer-classification']} `}>
                                                    <div className={styles.newsloader}></div>
                                                </div>
                                            </div>
                                        )}
                                    </>
                                )}
                            </div>

                        </div>

                    </>
                </div>
            )}

        </>
    );

};

export default AdminDashboard; 
