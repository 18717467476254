import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Document, Page, Text, StyleSheet, Image, View } from '@react-pdf/renderer';
import Logo from '../../assets/images/logo.png';

const pdfstyles = StyleSheet.create({
    body: {
        paddingTop: 35,
        paddingBottom: 65,
        paddingHorizontal: 35,
    },
    header: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 20,
        fontSize: 12,
        color: 'grey',
    },
    image: {
        width: 100,
        height: 50,
        marginRight: 10,
    },
    span: {
        color: '#000',
        display: 'block',
        fontSize: 9,
        fontWeight: '600',
        textAlign: 'left',
        textTransform: 'uppercase',
    },
});

const OffBoardingPdf = React.forwardRef(({ userId }, ref) => {
    const [formData, setFormData] = useState({ info: { employee_details: {} } });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const apiEndpoint = process.env.REACT_APP_API_URL + 'api/offboarding/view-offboarding-info/';

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            setError(null); // Reset error state
            try {
                const response = await axios.post(
                    apiEndpoint,
                    { blitz_user_id: '460' },
                    {
                        headers: {
                            'token': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjo1NjYsInVzZXJfbmFtZSI6IktpcnRpIFNoYXJtYSIsImlwX2FkZHJlc3MiOiI0OS4zNi4xNzYuMTA3IiwiY3VycmVudF90aW1lIjoiMjAyNC0wOC0xOSAwNToxNTowMS4zNzMwMzEifQ.yXeRVqoEJ1Tpx3oGHJuaChYpLaAVXZPxDPCMvtGeOCw',
                            'accept': 'application/json',

                        },
                    }
                );
                console.log('Response:', response.data); 
                setFormData(response.data);
            } catch (error) {
                console.error('Error fetching data:', error);
                setError('Failed to fetch data. Please check your internet connection or API status.');
            } finally {
                setLoading(false);
            }
        };

        if (userId) {
            fetchData();
        }
    }, [apiEndpoint, userId]);

    if (loading) {
        return <Text>Loading...</Text>; // Show loading state
    }

    if (error) {
        return <Text>{error}</Text>; // Show error message
    }

    return (
        <Document>
            <Page size="A4" style={pdfstyles.body}>
                <View style={pdfstyles.header} fixed>
                    <Image style={pdfstyles.image} src={Logo} />
                    <Text style={pdfstyles.header}>APPLICATION FOR EMPLOYMENT</Text>
                </View>
                <View style={{ flexDirection: 'row' }}>
                    <Text style={pdfstyles.span}>Post Apply:</Text>
                    <Text style={{ fontSize: 9 }}>{formData.Job_Title}</Text>

                   
                </View>
            </Page>
        </Document>
    );
});

export default OffBoardingPdf;
