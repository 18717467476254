import React, { useState, useEffect } from 'react';
import styles from '../Boading.module.scss';
import OffBoadingEmployeeInfo from '../OffBoadingEmployeeInfo/OffBoadingEmployeeInfo';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import OffboadingEmployeeDetails from '../../api/FetchOffboadingEmployeeDetails';
import OffboadingSaveEmployeeDetails from '../../api/OffboadingSaveEmployeeDetails';
import AdminAuth from '../../api/AdminAuth'
import BoadingHeader from '../Header/BoadingHeader';
import BoadingFooter from '../Footer/BoadingFooter';
import Logo from '../../assets/images/logo.svg';
const EmployeeJob = ({ }) => {
    const [loading, setLoading] = useState(true);
    const [showCommentInput, setShowCommentInput] = useState(false);
    const [showUnoutherizedModal, setShowUnoutherizedModal] = useState(false);
    const [authorized, setAuthorized] = useState(false);
    const [employeeId, setEmployeeId] = useState('');
    const [adminName, setadminName] = useState();
    const [department, setdepartment] = useState();
    const [buttonsDisabled, setButtonsDisabled] = useState(false);
    const [commentRequired, setCommentRequired] = useState(false);
    const [formData, setFormData] = useState({
        company_belongings_returned: false,
        timesheet_submitted: false,
        id_card_returned_comment: '',
        things_you_not_liked: "",
        no_join_reasons: "",
        summarize_responsibilities: "",
        dream_job: "",
        special_qualifications: "",
        like_features_of_BLUENT: "",
        technologies_list: "",
        not_like_features_of_BLUENT: "",
        comment: "",
        project_backups: false,
        performing_regular_duties: "",
        suggestions_to_improve: "",
        id_card_returned: false,
        hr: false,
        experience_working_here: "",
        people_you_would_like_to_work: "",
        things_you_liked: "",
        join_us_again: false,
        worked_describe: ""
    });
    const [showTextarea, setShowTextarea] = useState(formData.join_us_again);


    useEffect(() => {
        const url = 'get-employee-responsibilities';
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get('token');
        const employee_Id = urlParams.get('employeeId');
        const fetchData = async () => {
            try {
                setLoading(true);

                const employeeData = await OffboadingEmployeeDetails(url, token, employee_Id);
                if (employeeData.status === 200) {
                    const empDetails = employeeData.employee;
                    setFormData({
                        company_belongings_returned: empDetails.company_belongings_returned || false,
                        timesheet_submitted: empDetails.timesheet_submitted || false,
                        things_you_not_liked: empDetails.things_you_not_liked || "",
                        no_join_reasons: empDetails.no_join_reasons || "",
                        summarize_responsibilities: empDetails.summarize_responsibilities || "",
                        dream_job: empDetails.dream_job || "",
                        special_qualifications: empDetails.special_qualifications || "",
                        like_features_of_BLUENT: empDetails.like_features_of_BLUENT || "",
                        technologies_list: empDetails.technologies_list || "",
                        not_like_features_of_BLUENT: empDetails.not_like_features_of_BLUENT || "",
                        comment: empDetails.comment || "",
                        confidentiality_agreement_signed: empDetails.confidentiality_agreement_signed || false,
                        project_backups: empDetails.project_backups || false,
                        performing_regular_duties: empDetails.performing_regular_duties || "",
                        worked_describe: empDetails.worked_describe || "",
                        suggestions_to_improve: empDetails.suggestions_to_improve || "",
                        id_card_returned: empDetails.id_card_returned || false,
                        id_card_returned_comment: empDetails.id_card_returned_comment || "",
                        hr: empDetails.hr || false,
                        experience_working_here: empDetails.experience_working_here || "",
                        people_you_would_like_to_work: empDetails.people_you_would_like_to_work || "",
                        things_you_liked: empDetails.things_you_liked || "",
                        join_us_again: empDetails.join_us_again || false
                    });
                    if (empDetails.status === 'Submitted') {
                        setButtonsDisabled(true);
                    }
                    setLoading(false);
                } else {
                    console.error('Error fetching employee data:', employeeData.error);
                }
            } catch (error) {
                console.error('Error fetching employee data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();

    }, [authorized, employeeId]);

    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        if (name === 'id_card_returned') {
            setShowCommentInput(!checked);
            if (!checked) {
                setCommentRequired(true);
            } else {
                setCommentRequired(false);
                if (checked) {
                    setFormData(prevData => ({
                        ...prevData,
                        id_card_returned_comment: '',
                    }));
                }
            }
        }

        setFormData(prevData => ({
            ...prevData,
            [name]: checked,
            comment: checked ? '' : prevData.comment
        }));
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value,
        }));
    };

    const hanldeSaveInfo = async (event) => {
        event.preventDefault();
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get('token');
        const employee_Id = urlParams.get('employeeId');

        if (!formData.id_card_returned && formData.id_card_returned_comment.trim() === '') {
            toast.error('Please enter a ID card returned comment');
            return;
        }
        const requiredFields = [
            'things_you_not_liked', 'summarize_responsibilities',
            'dream_job', 'special_qualifications', 'like_features_of_BLUENT',
            'technologies_list', 'not_like_features_of_BLUENT', 'comment',
            'performing_regular_duties', 'suggestions_to_improve',
            'experience_working_here', 'people_you_would_like_to_work', 'things_you_liked'
        ];


        const emptyFields = requiredFields.filter(field => formData[field] === '');


        if (emptyFields.length > 0) {
            toast.error('Please fill in all text fields');
            return;
        }

        try {
            setLoading(true);
            const url = 'save-employee-responsibilities';
            const requestData = {
                ...formData,
                status: "Pending",
                blitz_user_id: employee_Id,
                created_by: adminName,
            };

            const response = await OffboadingSaveEmployeeDetails(url, token, employeeId, requestData);
            toast.success('Form data saved successfully!');
            // onSubmit(activeTabIndex);
        } catch (error) {
            console.error('Error saving form data:', error);
        } finally {
            setLoading(false);
        }
    }

    const hanldeSubmit = async (event) => {
        event.preventDefault();
        event.preventDefault();
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get('token');
        const employee_Id = urlParams.get('employeeId');
        const requiredFields = [
            'things_you_not_liked', 'summarize_responsibilities',
            'dream_job', 'special_qualifications', 'like_features_of_BLUENT',
            'technologies_list', 'not_like_features_of_BLUENT', 'comment',
            'performing_regular_duties', 'suggestions_to_improve',
            'experience_working_here', 'people_you_would_like_to_work', 'things_you_liked'
        ];

        // Count empty required fields
        const emptyFields = requiredFields.filter(field => formData[field] === '');

        if (emptyFields.length > 0) {
            toast.error('Please fill  all text fields');
            return;
        }

        try {
            setLoading(true);
            const url = 'save-employee-responsibilities';
            const requestData = {
                ...formData,
                status: "Submitted",
                blitz_user_id: employee_Id,
                created_by: adminName,
            };

            const response = await OffboadingSaveEmployeeDetails(url, token, employeeId, requestData);
            toast.success('Form data saved successfully!');
            // onSubmit(activeTabIndex);
        } catch (error) {
            console.error('Error saving form data:', error);
        } finally {
            setLoading(false);
        }
    }

    return (
        <>

                       <section className={`${styles['box-shadow']} ${styles.container}`}>
                            <div className={styles.header}>
                                <div className={styles.grid}>
                                    <div className={styles.col12}>
                                        <ul>
                                            <li className={styles['li-first']}>
                                                <div className={styles.logo}>
                                                    <img src={Logo} alt="Logo" width="195" height="42" />
                                                </div>
                                            </li>
                                            <li className={styles['li-second']}>
                                                
                                            </li>

                                            <li className={styles['li-third']}>
                                                <div className={styles['align-center']} style={{color: '#9393ff' ,fontWeight: '500', fontSize: '1.5rem'}}> Employee check list
                                                </div>
                                            </li>

                                            
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <fieldset className={styles.fieldset} >

                                <div className={styles.grid} style={{ marginTop: "10px", padding: "20px" }}>
                                    <div className={styles.col6}>
                                        <div className={styles['right-space']}>
                                            <ul className={styles['two-col']}>

                                                <li><strong>ID Card Returned:</strong>
                                                    <span>
                                                        <input type="checkbox" id="id_card_returned" name="id_card_returned" value="ID Card" checked={formData.id_card_returned} onChange={handleCheckboxChange} />
                                                        <label htmlFor="id_card_returned"></label>
                                                    </span>
                                                    {!formData.id_card_returned && (
                                                        <li>
                                                            <textarea
                                                                name="id_card_returned_comment"
                                                                value={formData.id_card_returned_comment}
                                                                onChange={handleChange}
                                                                placeholder='Add Comment Here'
                                                                className={styles.input}
                                                            />
                                                        </li>
                                                    )}
                                                </li>
                                                <li><strong>Exit Process Completed(Notice Period, Exit Interview):</strong>
                                                    <span>
                                                        <input type="checkbox" id="CheckNoticPeriod" name="hr" checked={formData.hr} onChange={handleCheckboxChange} />
                                                        <label htmlFor="CheckNoticPeriod"></label>

                                                    </span></li>


                                                <li><strong>Timesheet Submitted:</strong>
                                                    <span>
                                                        <input type="checkbox" id="Timesheet" name="timesheet_submitted" checked={formData.timesheet_submitted} onChange={handleCheckboxChange} />
                                                        <label htmlFor="Timesheet"></label>
                                                    </span></li>

                                            </ul>

                                        </div>
                                    </div>

                                    <div className={styles.col6}>
                                        <div className={styles['left-space']}>

                                            <ul className={styles['two-col']}>
                                                <li><strong>Hand Over all projects:</strong>
                                                    <span>
                                                        <input type="checkbox" id="ProjectBackups" name="project_backups" checked={formData.project_backups} onChange={handleCheckboxChange} />
                                                        <label htmlFor="ProjectBackups"></label>


                                                    </span></li>
                                                <li>
                                                    <strong>Any company belongings returned:(CD, Book or Asset like Hardware, Cell phone, Laptop, Mobile etc list)</strong>
                                                    <span>
                                                        <input type="checkbox" id="company_belongings_returned" name="company_belongings_returned" value="CD List" checked={formData.company_belongings_returned


                                                        } onChange={handleCheckboxChange} />
                                                        <label htmlFor="company_belongings_returned"></label>
                                                    </span>


                                                </li>
                                                <li style={{ paddingTop: "50px" }}>


                                                </li>
                                            </ul>

                                        </div>
                                    </div>





                                </div>
                            </fieldset>
                            <fieldset className={styles.fieldset} >
                                <legend style={{ textAlign: "center", paddingBottom: "20px" }}>Exit Interview Form</legend>
                                <div className={styles.grid} style={{ marginTop: "10px", padding: "20px" }}>
                                    <div className={styles.col6}>
                                        <div className={styles['right-space']}>


                                            <h5 style={{ paddingBottom: '10px', color: '#9393ff', fontWeight: '600' }}>Details Of Job Responsibilities</h5>
                                            <ul className={styles['two-col']}>
                                                <li className={styles['strong-text']}><strong>Summarize your major Duties/ Responsibilities:<i className={styles.red}>*</i></strong>
                                                    <span>
                                                        <textarea className={styles.input} name="summarize_responsibilities" value={formData.summarize_responsibilities} onChange={handleChange}></textarea>
                                                    </span></li>
                                                <li className={styles['strong-text']}><strong>Special qualifications, certifications etc. required to perform duties assigned to you:<i className={styles.red}>*</i></strong>
                                                    <span>
                                                        <textarea className={styles.input} name="special_qualifications" value={formData.special_qualifications} onChange={handleChange}></textarea>
                                                    </span></li>
                                            </ul>
                                            <h5 style={{ paddingBottom: '10px', color: '#9393ff', fontWeight: '600' }}>Technologies & Facilities Used:</h5>
                                            <ul className={styles['two-col']}>
                                                <li className={styles['strong-text']}><strong>List all the technologies, equipments, services or facilities used to perform your assignments:<i className={styles.red}>*</i></strong>
                                                    <span>
                                                        <textarea className={styles.input} name="technologies_list" value={formData.technologies_list} onChange={handleChange}></textarea>
                                                    </span></li>
                                            </ul>
                                            <h5 style={{ paddingBottom: '10px', color: '#9393ff', fontWeight: '600' }}>Decision-making:</h5>
                                            <ul className={styles['two-col']}>
                                                <li className={styles['strong-text']}><strong>Please explain the decisions you made while performing your regular duties:<i className={styles.red}>*</i></strong>
                                                    <span>
                                                        <textarea className={styles.input} name="performing_regular_duties" value={formData.performing_regular_duties} onChange={handleChange}></textarea>
                                                    </span></li>

                                            </ul>
                                            <h5 style={{ paddingBottom: '10px', color: '#9393ff', fontWeight: '600' }}>Working conditions:</h5>
                                            <ul className={styles['two-col']}>
                                                <li className={styles['strong-text']}><strong>Things you liked while working at BLUENT:<i className={styles.red}>*</i></strong>
                                                    <span>
                                                        <textarea className={styles.input} name="things_you_liked" value={formData.things_you_liked} onChange={handleChange}></textarea>
                                                    </span></li>
                                                <li className={styles['strong-text']}><strong>Things you didn’t like at all while working at BLUENT:<i className={styles.red}>*</i></strong>
                                                    <span>
                                                        <textarea className={styles.input} name="things_you_not_liked" value={formData.things_you_not_liked} onChange={handleChange}></textarea>
                                                    </span></li>
                                                <li className={styles['strong-text']}><strong>Suggestions to improve BLUENT?:<i className={styles.red}>*</i></strong>
                                                    <span>
                                                        <textarea className={styles.input} name="suggestions_to_improve" value={formData.suggestions_to_improve} onChange={handleChange}></textarea>
                                                    </span></li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div className={styles.col6}>
                                        <div className={styles['left-space']}>



                                            <ul className={styles['two-col']}>
                                                <h5 style={{ paddingBottom: '10px', color: '#9393ff', fontWeight: '600' }}>Working conditions:</h5>
                                                <li className={styles['strong-text']}><strong>Please describe the conditions under which you worked - inside, outside, infrastructure, technologies, setup, systems & policies etc:<i className={styles.red}>*</i></strong>
                                                    <span>
                                                        <textarea className={styles.input} name="worked_describe" value={formData.worked_describe} onChange={handleChange}></textarea>
                                                    </span></li>
                                                <li className={styles['strong-text']}><strong>How was your experience working here?:<i className={styles.red}>*</i></strong>
                                                    <span>
                                                        <textarea className={styles.input} name="experience_working_here" value={formData.experience_working_here} onChange={handleChange}></textarea>
                                                    </span></li>

                                                <li className={styles['strong-text']}><strong>What does BLUENT Lack against other companies or employers?<br></br> (In whatever you perceive as a dream job):<i className={styles.red}>*</i></strong>
                                                    <span>
                                                        <textarea className={styles.input} name="dream_job" value={formData.dream_job} onChange={handleChange}></textarea>
                                                    </span></li>
                                                <li className={styles['strong-text']}><strong>What policies or features of BLUENT would you like to have in your next organisation?:<i className={styles.red}>*</i></strong>
                                                    <span>
                                                        <textarea className={styles.input} name="like_features_of_BLUENT" value={formData.like_features_of_BLUENT} onChange={handleChange}></textarea>
                                                    </span></li>
                                                <li className={styles['strong-text']}><strong>What policies or features of BLUENT you would NOT like to have in your next organisation?:<i className={styles.red}>*</i></strong>
                                                    <span>
                                                        <textarea className={styles.input} name="not_like_features_of_BLUENT" value={formData.not_like_features_of_BLUENT} onChange={handleChange}></textarea>
                                                    </span></li>

                                                <li className={styles['strong-text']}><strong>The People you would like to work with & reasons:<i className={styles.red}>*</i></strong>
                                                    <span>
                                                        <textarea className={styles.input} name="people_you_would_like_to_work" value={formData.people_you_would_like_to_work} onChange={handleChange}></textarea>
                                                    </span></li>

                                                <li>
                                                    <strong>Would you like to join us again?</strong>
                                                    <span>
                                                        <input
                                                            type="checkbox"
                                                            id="join_us_again"
                                                            name="join_us_again"
                                                            value="ID Card"
                                                            checked={formData.join_us_again}
                                                            onChange={handleCheckboxChange}
                                                        />
                                                        <label htmlFor="join_us_again"></label>
                                                    </span>
                                                </li>
                                                {(!formData.join_us_again || !!formData.no_join_reasons) && (
                                                    <ul>
                                                        <li className={styles['strong-text']}>
                                                            <strong>If No, please give reasons:<i className={styles.red}>*</i></strong>
                                                            <span>
                                                                <textarea
                                                                    className={styles.input}
                                                                    name="no_join_reasons"
                                                                    value={formData.no_join_reasons}
                                                                    onChange={handleChange}
                                                                ></textarea>
                                                            </span>
                                                        </li>
                                                    </ul>
                                                )}
                                            </ul>
                                        </div>
                                    </div>


                                    <div className={styles['right-space']} style={{ paddingTop: "30px" }}>
                                        <ul className={styles['two-col']}>
                                            <li>Comment<strong><i className={styles['red']}>*</i>:</strong>
                                                <span>
                                                    <textarea name="comment" className={styles.input} value={formData.comment} onChange={handleChange}></textarea>
                                                </span>


                                            </li>
                                        </ul>
                                    </div>

                                    {/* Button block */}
                                    <div className={styles['button-block']}>
                                        {!buttonsDisabled && (
                                            <input type="submit" value="Save " className={styles.button} onClick={hanldeSaveInfo} disabled={loading} />

                                        )}
                                        {!buttonsDisabled && (
                                            <input type="submit" value={buttonsDisabled ? 'Submitted' : 'Submit'} className={styles.button} onClick={hanldeSubmit} disabled={loading} />
                                        )}
                                        <ToastContainer />
                                    </div>
                                </div>
                            </fieldset>
                        
                    {loading && <div className={`${styles['loader-center']} ${styles['loaderHeight-classification']} `}>
                        <div className={`${styles['newsloader-container']} ${styles['newsloaderContainer-classification']} `}>
                            <div className={styles.newsloader}></div>
                        </div>
                    </div>}
                </section>
        </>
    );
};
export default EmployeeJob;
