import React, { useState, useEffect } from 'react';
import styles from '../Boading.module.scss';
import EmployeeInfo from '../EmployeeInfo/EmployeeInfo';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import OnboadingEmployeeDetails from '../../api/FetchEmployeeDetail';
import OnboadingSaveEmployeeDetails from '../../api/SaveOnboadingData';
import { toast, ToastContainer } from 'react-toastify';
import User from '../../assets/images/icon-user.svg';
import 'react-toastify/dist/ReactToastify.css';
import Logo from '../../assets/images/logo.svg';
import { useNavigate } from 'react-router-dom';
import AdminAuth from '../../api/AdminAuth';
import Back from '../../assets/images/icon-back-main.svg';
import OnboadingBlitzData from '../../api/BlitzGetCandidate';
import axios from 'axios';
const ImportToBlitz = ({ department, isActive, authorized, token, employeeId, setEmployeeId, admin, disableTab, onSubmit, activeTabIndex }) => {
    const [country, setCountry] = useState('India');
    const [region, setRegion] = useState('');
    const [loading, setLoading] = useState(true);
    const [formData, setFormData] = useState(null);
    const [formZohoData, setFormZohhoData] = useState(null);
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');
    const [phoneError, setPhoneError] = useState('');
    const [cellError, setcellError] = useState('');
    const [refreshEmployeeInfo, setRefreshEmployeeInfo] = useState(false);
    const [buttonsDisabled, setButtonsDisabled] = useState(false);
    const [titleError, setTitleError] = useState('');
    const [adminName, setadminName] = useState();
    const [showUnoutherizedModal, setShowUnoutherizedModal] = useState(false);
    const [emailData, setEmailData] = useState('');
    const [authToken, setAuthToken] = useState('');
    const navigate = useNavigate();
    useEffect(() => {
        const checkAuthorization = async () => {
            const urlParams = new URLSearchParams(window.location.search);
            const token = urlParams.get('token');
            const employeeId = urlParams.get('employeID');
            if (token) {
                try {
                    const authResult = await AdminAuth(token);
                    if (authResult && authResult.status === 200 && authResult.authenticate) {
                        console.log(authResult.user_name);
                        setadminName(authResult.user_name);
                        setAuthToken(authResult.token);
                        setShowUnoutherizedModal(false);
                        const Result = await OnboadingBlitzData(authResult.token, employeeId);
                        setFormData(Result);




                    } else {
                        setLoading(false);
                    }
                } catch (error) {
                    //setAuthorized(false);
                    //setShowUnoutherizedModal(true);


                }
                finally {
                    setLoading(false);
                }
            }
        };

        checkAuthorization();
    }, []);



    const hanldeSaveInfo = async () => {
        setLoading(true);
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}api/onboarding/employee-details-to-blitz`,
                {
                    candidate_new_data: formData?.candidate_new_data
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'token': authToken
                    }
                }
            );

            if (response.data.status === 200) {
                console.log(response);
                toast.success(response.data.msg);
                setButtonsDisabled(true); 
                setLoading(false);
            } else {
                throw new Error('Failed to save data');
            }
        } catch (error) {
            console.error('Error saving data:', error);
            toast.error('Failed to save data');
        }
    };


    const hanldeSaveZohoInfo = async () => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}api/onboarding/employee-details-to-blitz`,
                {
                    candidate_new_data: formData?.candidate_old_data
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'token': authToken
                    }
                }
            );

            if (response.status === 200) {
                toast.success('Zoho data successfully imported into blitz.');
                setButtonsDisabled(true); 
            } else {
                throw new Error('Failed to save data');
            }
        } catch (error) {
            console.error('Error saving data:', error);
            toast.error('Failed to save data');
        }
    };


    

    const redirectToDashboard = () => {
        const urlParams = new URLSearchParams(window.location.search);
            const token = urlParams.get('token');
            const employeeId = urlParams.get('employeID');
        navigate('/onboarding?token=' + token + '&employeeId='+employeeId);

    };



    return (
        <>
            {showUnoutherizedModal && (
                <div id="myModal" className={styles.modal}>
                    <div className={styles['modal-content']}>
                        <p>Yor are not authorized to access this page. Token is expired.</p>
                    </div>
                </div>
            )}
            <div className={styles.header}>
                <div className={styles.grid}>
                    <div className={styles.col12}>
                        <ul>
                            <li className={styles['li-first']}>
                                <div className={styles.logo}>
                                    <img src={Logo} alt="Logo" width="195" height="42" />
                                </div>
                            </li>
                            <li className={styles['li-second']}>
                                <div></div>
                            </li>
                            <li className={styles['li-third']}>
                                <h1 className={styles['align-center']}>Employee Details
                                </h1>
                            </li><li className={styles['li-fourth']}>

                            </li>
                            <li className={styles['li-fifth']}>
                                <div className={styles.admin}>
                                    <img src={User} alt="User" width="25" height="25" />{adminName}
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className={styles.employee}>
                <div className={`${styles.grid} ${styles.relative}`}>
                    <div className={styles['edit-bg']}>

                    </div>


                    <>

                        <div className={styles.col3}>
                            <div className={`${styles['box-shadow']} ${styles['border-radius']}`}>
                                <ul>
                                    <li><strong>First Name:</strong> <span>{formData?.candidate_new_data?.first_name}</span></li>
                                    <li><strong>Last Name:</strong> <span>{formData?.candidate_new_data?.last_name}</span></li>
                                    <li><strong>Title:</strong> <span>{formData?.candidate_new_data?.job_title}</span></li>
                                    <li><strong>Department:</strong> <span>{formData?.candidate_new_data?.department}</span></li>
                                </ul>
                                <div className={styles['align-right']}>
                                    <button onClick={redirectToDashboard}>
                                        <img src={Back} width="30" height="15" alt="Back" />
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className={styles.col9}>
                            <div className={`${styles['box-shadow']} ${styles['border-radius']} `}>
                                <fieldset className={styles.fieldset}>
                                    <form id={styles.basicINFO} name="basicINFO">
                                        <div className={styles.grid}>
                                            <div className={styles.col6}>
                                                <div className={styles['right-space']}>
                                                    <h3>EMPLOYMENT FORM DATA</h3>
                                                    <ul className={styles['two-col']}>
                                                    <li>
                                                            <strong>User Name:</strong>
                                                            <span>
                                                                <input
                                                                    type="text"
                                                                    name="user_name"
                                                                    className={styles.input}
                                                                    value={`${formData?.candidate_new_data?.user_name || ''} `}
                                                                    disabled
                                                                />
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <strong>First Name:</strong>
                                                            <span>
                                                                <input
                                                                    type="text"
                                                                    name="first_name"
                                                                    className={styles.input}
                                                                    value={`${formData?.candidate_new_data?.first_name || ''} `}
                                                                    disabled
                                                                />
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <strong>Middle Name:</strong>
                                                            <span>
                                                                <input
                                                                    type="text"
                                                                    name="first_name"
                                                                    className={styles.input}
                                                                    value={` ${formData?.candidate_new_data?.middle_name ? formData.candidate_new_data.middle_name + ' ' : ''}`}
                                                                    disabled
                                                                />
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <strong>Last Name:</strong>
                                                            <span>
                                                                <input
                                                                    type="text"
                                                                    name="first_name"
                                                                    className={styles.input}
                                                                    value={`${formData?.candidate_new_data?.last_name || ''}`}
                                                                    disabled
                                                                />
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <strong>Joining Date:</strong>
                                                            <span>
                                                                <input
                                                                    type="text"
                                                                    name="joining_date"
                                                                    className={styles.input}
                                                                    value={formData?.candidate_new_data?.joining_date || ''}
                                                                    disabled
                                                                />
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <strong>Date Of Birth:</strong>
                                                            <span>
                                                                <input
                                                                    type="text"
                                                                    name="dob"
                                                                    className={styles.input}
                                                                    value={formData?.candidate_new_data?.dob || ''}
                                                                    disabled
                                                                />
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <strong>Blood Group:</strong>
                                                            <span>
                                                                <input
                                                                    type="text"
                                                                    name="blood_group"
                                                                    className={styles.input}
                                                                    value={formData?.candidate_new_data?.blood_group || ''}
                                                                    disabled
                                                                />
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <strong>Sex:</strong>
                                                            <span>
                                                                <input
                                                                    type="text"
                                                                    name="gender"
                                                                    className={styles.input}
                                                                    value={formData?.candidate_new_data?.gender || ''}
                                                                    disabled
                                                                />
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <strong>Marital status:</strong>
                                                            <span>
                                                                <input
                                                                    type="text"
                                                                    name="martial_status"
                                                                    className={styles.input}
                                                                    value={formData?.candidate_new_data?.martial_status || ''}
                                                                    disabled
                                                                />
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <strong>Identification Marks:</strong>
                                                            <span>
                                                                <input
                                                                    type="text"
                                                                    name="identification_marks"
                                                                    className={styles.input}
                                                                    value={formData?.candidate_new_data?.identification_marks || ''}
                                                                    disabled
                                                                />
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <strong>Weight:</strong>
                                                            <span>
                                                                <input
                                                                    type="text"
                                                                    name="weight"
                                                                    className={styles.input}
                                                                    value={formData?.candidate_new_data?.weight || ''}
                                                                    disabled
                                                                />
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <strong>Height:</strong>
                                                            <span>
                                                                <input
                                                                    type="text"
                                                                    name="height"
                                                                    className={styles.input}
                                                                    value={formData?.candidate_new_data?.height || ''}
                                                                    disabled
                                                                />
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <strong>Current Address:</strong>
                                                            <span>
                                                                <textarea
                                                                    name="current_address"
                                                                    className={styles.input}
                                                                    disabled
                                                                    value={[
                                                                        formData?.candidate_new_data?.contact_details?.address || '',
                                                                        formData?.candidate_new_data?.contact_details?.state || '',
                                                                        formData?.candidate_new_data?.contact_details?.city || '',
                                                                        formData?.candidate_new_data?.contact_details?.country || '',
                                                                        formData?.candidate_new_data?.contact_details?.zip || ''
                                                                    ].filter(Boolean).join(', ')}
                                                                >

                                                                </textarea>
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <strong>Permanent Address:</strong>
                                                            <span>
                                                                <textarea
                                                                    name="permanent_address"
                                                                    className={styles.input}
                                                                    disabled
                                                                    value={[
                                                                        formData?.candidate_new_data?.contact_details?.address2 || '',
                                                                        formData?.candidate_new_data?.contact_details?.state2 || '',
                                                                        formData?.candidate_new_data?.contact_details?.city2 || '',
                                                                        formData?.candidate_new_data?.contact_details?.country2 || '',
                                                                        formData?.candidate_new_data?.contact_details?.zip2 || ''
                                                                    ].filter(Boolean).join(', ')}
                                                                />
                                                            </span>
                                                        </li>

                                                        <li>
                                                            <strong>Mobiles:</strong>
                                                            <span>
                                                                <textarea
                                                                    name="current_address"
                                                                    className={styles.input}
                                                                    disabled
                                                                    style={{ height: '60px' }}
                                                                    value={[
                                                                        formData?.candidate_new_data?.contact_details?.mobile || '',
                                                                        formData?.candidate_new_data?.contact_details?.mobile2 || '',
                                                                        formData?.candidate_new_data?.contact_details?.mobile3 || ''
                                                                    ].filter(Boolean).join(', ')}
                                                                >

                                                                </textarea>
                                                            </span>
                                                        </li>

                                                        <li>
                                                            <strong>Emails:</strong>
                                                            <span>
                                                                <textarea
                                                                    name="current_address"
                                                                    className={styles.input}
                                                                    style={{ height: '60px' }}
                                                                    disabled
                                                                    value={[
                                                                        formData?.candidate_new_data?.email || '',
                                                                        formData?.candidate_new_data?.contact_details?.email2 || '',
                                                                        formData?.candidate_new_data?.contact_details?.email3 || ''
                                                                    ].filter(Boolean).join(', ')}
                                                                >

                                                                </textarea>
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <strong>Emergency Contact Details:</strong>
                                                            <span>
                                                                <textarea
                                                                    name="current_address"
                                                                    className={styles.input}
                                                                    disabled
                                                                    style={{ height: '70px' }}
                                                                    value={[
                                                                        formData?.candidate_new_data?.candidate_emergency_details.contact_name && `Name: ${formData?.candidate_new_data?.candidate_emergency_details.contact_name}`,
                                                                        formData?.candidate_new_data?.candidate_emergency_details.mobile && `Mobile: ${formData?.candidate_new_data?.candidate_emergency_details.mobile}`,
                                                                        formData?.candidate_new_data?.candidate_emergency_details.relationship && `Relationship: ${formData?.candidate_new_data?.candidate_emergency_details.relationship}`
                                                                    ].filter(Boolean).join('\n')}
                                                                >

                                                                </textarea>
                                                            </span>
                                                        </li>

                                                    </ul>
                                                    <ul className={styles['two-col']}>
                                                        <h3>PROFESSIONAL/INTERNSHIP EXPERIENCE</h3>
                                                        {formData?.candidate_new_data?.candidate_experience_details.map((experience, index) => (
                                                            <li key={index}>
                                                                <textarea
                                                                    name={`experience_${index}`}
                                                                    className={styles.input}
                                                                    disabled
                                                                    style={{ height: '60px' }}
                                                                    value={[
                                                                        experience.employer_name && `Employer Name: ${experience.employer_name}`,
                                                                        experience.period_from && `Period From: ${experience.period_from}`,
                                                                        experience.period_to && `Period To: ${experience.period_to}`
                                                                    ].filter(Boolean).join('\n')}
                                                                />
                                                            </li>
                                                        ))}
                                                    </ul>
                                                    <ul className={styles['two-col']}>
                                                        <h3>EDUCATION/AWARDS</h3>
                                                        {formData?.candidate_new_data?.candidate_education_details.map((education, index) => (
                                                            <li key={index}>
                                                                <textarea
                                                                    name={`education_${index}`}
                                                                    className={styles.input}
                                                                    disabled
                                                                    style={{ height: '120px' }}
                                                                    value={[
                                                                        education.degree_name && `Degree Name: ${education.degree_name}`,
                                                                        education.institute_name && `Institute name: ${education.institute_name}`,
                                                                        education.course_start && `Duration: ${education.course_start}-` + `${education.course_end}`,
                                                                        education.div_marks && `Subjects/Specification: ${education.subject}`,
                                                                        education.div_marks && `Duration: ${education.div_marks}`
                                                                    ].filter(Boolean).join('\n')}
                                                                />
                                                            </li>
                                                        ))}
                                                    </ul>
                                                    <ul>
                                                        <li>
                                                            <input
                                                                type="button"
                                                                style={{ width: "50%", float: "right", marginRight: "40px" }}
                                                                value="Submit"
                                                                className={styles.button}
                                                                onClick={hanldeSaveInfo}
                                                                disabled={loading || buttonsDisabled }
                                                            />
                                                        </li>
                                                       
                                                    </ul>
                                                </div>
                                            </div>

                                            <div className={styles.col6}>
                                                <div className={styles['left-space']}>
                                                    <h3>EMPLOYEE ZOHO RECRUIT DATA</h3>
                                                    <ul className={styles['two-col']}>
                                                        <li>
                                                            <strong>Name:</strong> 
                                                            <span>
                                                                <input
                                                                    type="text"
                                                                    name="first_name"
                                                                    className={styles.input}
                                                                    value={`${formData?.candidate_old_data?.first_name || ''} ${formData?.candidate_old_data?.middle_name ? formData.candidate_old_data.middle_name + ' ' : ''}${formData?.candidate_old_data?.last_name || ''}`}
                                                                    disabled
                                                                />
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <strong>Date Of Birth:</strong>
                                                            <span>
                                                                <input
                                                                    type="text"
                                                                    name="dob"
                                                                    className={styles.input}
                                                                    value={formData?.candidate_old_data?.dob || ''}
                                                                    disabled
                                                                />
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <strong>Blood Group:</strong>
                                                            <span>
                                                                <input
                                                                    type="text"
                                                                    name="blood_group"
                                                                    className={styles.input}
                                                                    value={formData?.candidate_old_data?.blood_group || ''}
                                                                    disabled
                                                                />
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <strong>Sex:</strong>
                                                            <span>
                                                                <input
                                                                    type="text"
                                                                    name="gender"
                                                                    className={styles.input}
                                                                    value={formData?.candidate_old_data?.gender || ''}
                                                                    disabled
                                                                />
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <strong>Marital status:</strong>
                                                            <span>
                                                                <input
                                                                    type="text"
                                                                    name="martial_status"
                                                                    className={styles.input}
                                                                    value={formData?.candidate_old_data?.martial_status || ''}
                                                                    disabled
                                                                />
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <strong>Identification Marks:</strong>
                                                            <span>
                                                                <input
                                                                    type="text"
                                                                    name="identification_marks"
                                                                    className={styles.input}
                                                                    value={formData?.candidate_old_data?.identification_marks || ''}
                                                                    disabled
                                                                />
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <strong>Weight:</strong>
                                                            <span>
                                                                <input
                                                                    type="text"
                                                                    name="weight"
                                                                    className={styles.input}
                                                                    value={formData?.candidate_old_data?.weight || ''}
                                                                    disabled
                                                                />
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <strong>Height:</strong>
                                                            <span>
                                                                <input
                                                                    type="text"
                                                                    name="height"
                                                                    className={styles.input}
                                                                    value={formData?.candidate_old_data?.height || ''}
                                                                    disabled
                                                                />
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <strong>Current Address:</strong>
                                                            <span>
                                                                <textarea
                                                                    name="current_address"
                                                                    className={styles.input}
                                                                    disabled
                                                                    value={[
                                                                        formData?.candidate_old_data?.contact_details?.address || '',
                                                                        formData?.candidate_old_data?.contact_details?.state || '',
                                                                        formData?.candidate_old_data?.contact_details?.city || '',
                                                                        formData?.candidate_old_data?.contact_details?.country || '',
                                                                        formData?.candidate_old_data?.contact_details?.zip || ''
                                                                    ].filter(Boolean).join(', ')}
                                                                >

                                                                </textarea>
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <strong>Permanent Address:</strong>
                                                            <span>
                                                                <textarea
                                                                    name="permanent_address"
                                                                    className={styles.input}
                                                                    disabled
                                                                    value={[
                                                                        formData?.candidate_old_data?.contact_details?.address2 || '',
                                                                        formData?.candidate_old_data?.contact_details?.state2 || '',
                                                                        formData?.candidate_old_data?.contact_details?.city2 || '',
                                                                        formData?.candidate_old_data?.contact_details?.country2 || '',
                                                                        formData?.candidate_old_data?.contact_details?.zip2 || ''
                                                                    ].filter(Boolean).join(', ')}
                                                                />
                                                            </span>
                                                        </li>

                                                        <li>
                                                            <strong>Mobiles:</strong>
                                                            <span>
                                                                <textarea
                                                                    name="current_address"
                                                                    className={styles.input}
                                                                    disabled
                                                                    style={{ height: '60px' }}
                                                                    value={[
                                                                        formData?.candidate_old_data?.contact_details?.mobile || '',
                                                                        formData?.candidate_old_data?.contact_details?.mobile2 || '',
                                                                        formData?.candidate_old_data?.contact_details?.mobile3 || ''
                                                                    ].filter(Boolean).join(', ')}
                                                                >

                                                                </textarea>
                                                            </span>
                                                        </li>

                                                        <li>
                                                            <strong>Emails:</strong>
                                                            <span>
                                                                <textarea
                                                                    name="current_address"
                                                                    className={styles.input}
                                                                    disabled
                                                                    style={{ height: '60px' }}
                                                                    value={[
                                                                        formData?.candidate_old_data?.email || '',
                                                                        formData?.candidate_old_data?.contact_details?.email2 || '',
                                                                        formData?.candidate_old_data?.contact_details?.email3 || ''
                                                                    ].filter(Boolean).join(', ')}
                                                                >

                                                                </textarea>
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <strong>Emergency Contact Details:</strong>
                                                            <span>
                                                                <textarea
                                                                    name="current_address"
                                                                    className={styles.input}
                                                                    disabled
                                                                    style={{ height: '70px' }}
                                                                    value={[
                                                                        formData?.candidate_old_data?.candidate_emergency_details.contact_name && `Name: ${formData?.candidate_old_data?.candidate_emergency_details.contact_name}`,
                                                                        formData?.candidate_old_data?.candidate_emergency_details.mobile && `Mobile: ${formData?.candidate_old_data?.candidate_emergency_details.mobile}`,
                                                                        formData?.candidate_old_data?.candidate_emergency_details.relationship && `relationship: ${formData?.candidate_old_data?.candidate_emergency_details.relationship}`
                                                                    ].filter(Boolean).join('\n')}
                                                                >

                                                                </textarea>
                                                            </span>
                                                        </li>

                                                    </ul>
                                                    <ul className={styles['two-col']}>
                                                        <h3>PROFESSIONAL/INTERNSHIP EXPERIENCE</h3>
                                                        {formData?.candidate_old_data?.candidate_experience_details.map((experience, index) => (
                                                            <li key={index}>
                                                                <textarea
                                                                    name={`experience_${index}`}
                                                                    className={styles.input}
                                                                    disabled
                                                                    style={{ height: '60px' }} // Adjust the height as needed
                                                                    value={[
                                                                        experience.employer_name && `Employer Name: ${experience.employer_name}`,
                                                                        experience.period_from && `Period From: ${experience.period_from}`,
                                                                        experience.period_to && `Period To: ${experience.period_to}`
                                                                    ].filter(Boolean).join('\n')}
                                                                />
                                                            </li>
                                                        ))}
                                                    </ul>
                                                    <ul className={styles['two-col']}>
                                                        <h3>EDUCATION/AWARDS</h3>
                                                        {formData?.candidate_old_data?.candidate_education_details.map((education, index) => (
                                                            <li key={index}>
                                                                <textarea
                                                                    name={`education_${index}`}
                                                                    className={styles.input}
                                                                    disabled
                                                                    style={{ height: '120px' }}
                                                                    value={[
                                                                        education.degree_name && `Degree Name: ${education.degree_name}`,
                                                                        education.institute_name && `Institute name: ${education.institute_name}`,
                                                                        education.course_start && `Duration: ${education.course_start}-` + `${education.course_end}`,
                                                                        education.div_marks && `Subjects/Specification: ${education.subject}`,
                                                                        education.div_marks && `Duration: ${education.div_marks}`
                                                                    ].filter(Boolean).join('\n')}
                                                                />
                                                            </li>
                                                        ))}
                                                    </ul>
                                                    <ul>{/*<li>
                                                        <input
                                                            type="button"
                                                            style={{ width: "50%", float: "right", marginRight: "40px" }}
                                                            value="Submit"
                                                            className={styles.button}
                                                            onClick={hanldeSaveZohoInfo}
                                                            disabled={loading || buttonsDisabled}
                                                        />
                                                    </li>*/}
                                                   </ul>
                                                </div>

                                            </div>



                                        </div>
                                        <input type="hidden" name="created_by" className={styles.input} //value={formData.created_by} 
                                        />
                                    </form>
                                    <ToastContainer />
                                </fieldset>
                            </div>
                        </div>
                        {loading && <div className={`${styles['loader-center']} ${styles['loaderHeight-classification']} `}>
                            <div className={`${styles['newsloader-container']} ${styles['newsloaderContainer-classification']} `}>
                                <div className={styles.newsloader}></div></div>
                        </div>}
                    </>
                    {/*  )}*/}
                </div>

            </div>
        </>
    );
};

export default ImportToBlitz;
