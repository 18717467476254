import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import styles from '../Boading.module.scss';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Logo from '../../assets/images/logo.svg';


const TodayInterview = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const navigate = useNavigate();

    const handleLogin = async (e) => {
        e.preventDefault();
        setLoading(true);
    
        const loginData = {
            username: username,
            password: password,
        };
    
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}api/interview/interview-schedule-login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(loginData),
            });
      
           
            const data = await response.json();
    
            
            if (data.status === 200 && data.authenticate) {
                const redirectUrl = data.redirect_to;
                toast.success(data.msg);
                window.location.href = redirectUrl; 
            } else {
                toast.error(data.msg || 'Invalid username or password.');
            }
        } catch (error) {
            console.error('Error during login:', error);
            toast.error('An error occurred. Please try again later.');
        } finally {
            setLoading(false);
        }
    };
    

    return (
        <>
            <Helmet>
                <title>Login Form</title>
            </Helmet>
            <section className={`${styles['box-shadow']} ${styles.container}`}>
                <div className={styles.header}>
                    <div className={styles.grid}>
                        <div className={styles.col12}>
                            <ul>
                                <li className={styles['li-first']}>
                                    <div className={styles.logo}>
                                        <img src={Logo} alt="Logo" width="195" height="42" />
                                    </div>
                                </li>
                                <li className={styles['li-third']}>
                                    <div className={styles['align-center']} style={{ color: '#9393ff', fontWeight: '500', fontSize: '1.5rem' }}>
                                        Login Form
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className={styles.employee}>
                    <div className={styles.grid}>
                        <div className="Boading_edit-bg__53BA+"></div>

                        <div className={styles.col9}>
                            <div className={`${styles['box-shadow']} ${styles['border-radius']} `}>
                                <fieldset className={styles.fieldset}>
                                    <legend style={{ paddingBottom: "20px" }}>Today's Interview</legend>
                                    <form onSubmit={handleLogin} className={styles.interviewloginForm}>
                                        <div className={styles.grid}>
                                            <div className={styles.col6}>
                                                <div className={styles['right-space']}>
                                                    <ul className={styles['two-col']}>
                                                        <li>
                                                            <strong>Username:</strong>
                                                            <span>
                                                                <input
                                                                    type="text"
                                                                    id="username"
                                                                    value={username}
                                                                    className={styles.input}
                                                                    onChange={(e) => setUsername(e.target.value)}
                                                                    required
                                                                />
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <strong>Password:</strong>
                                                            <span>
                                                                <input
                                                                    type="password"
                                                                    id="password"
                                                                    className={styles.input}
                                                                    value={password}
                                                                    onChange={(e) => setPassword(e.target.value)}
                                                                    required
                                                                />
                                                            </span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>

                                            {/* Button block */}
                                            <div className={styles['button-block']}>
                                                <input type="submit" value="Save" className={styles.button} />
                                            </div>
                                        </div>
                                    </form>
                                </fieldset>
                            </div>
                        </div>
                    </div>
                </div>

                <ToastContainer />
            </section>
        </>
    );
};

export default TodayInterview;
