import './App.css';
import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import MainContent from './applicantContainers/Candidate/MainContent/MainContent';
import { TokenProvider } from './context/AuthContext';
import { AuthProvider } from './context/AdminContext';
import AdminRoutes from './router/AdminRoutes';
import FeedbackForm from './applicantContainers/candidateFeedback/FeedbackForm';
import NotFound from './components/NotFound/NotFound';
import { HelmetProvider } from 'react-helmet-async';
import OnboadingRoutes from './router/OnboadingRoutes';
import AdminDashboard from './admin/CandidateList/AdminDashboard';
import AddEmployee from './OnBoading/Main/Employee';
import ImportToBlitz from './OnBoading/importToBlitz/importToBlitz';
import OffBoadingEmployeeDetails from './OffBoading/Main/OffBoadingEmployeeDetails'
import EmployeeJob from './OffBoading/Employee job/EmployeeJob';
import OffBoardingPdf from './admin/CandidateList/OffBoardingPdf';
import Pdf from './admin/CandidateList/pdf';
import InterviewCandidateList from './OnBoading/InterviewCandidateList/InterviewCandidateList';
import TodayInterview from './OnBoading/TodayInterview/TodayInterview';
import HrInterviewCandidateList from './OnBoading/InterviewCandidateList/HrInterviewCandidateList';
function App() {
  const [popupVisible, setPopupVisible] = useState(false);
  return (
    <Router>
      <HelmetProvider>
        <TokenProvider>
          <Routes>
            <Route path="/" element={<MainContent />} />
            <Route path="/candidate-feedback-form" element={<FeedbackForm />} />
           <Route path="/dashboard" element={<AdminDashboard />} />
           <Route path="/onboarding" element={<AddEmployee />} />
           <Route path="/offboarding" element={<OffBoadingEmployeeDetails />} />
           <Route path="/candidate-details" element={<ImportToBlitz />} />
           <Route path="/employee-details" element={<EmployeeJob />} />
           <Route path="/interview-candidates" element={<InterviewCandidateList />} />
           <Route path="/candidate-interview-list" element={<HrInterviewCandidateList />} />
           <Route path="/today-interview" element={<TodayInterview />} />
           <Route path="*" element={<NotFound />} />
           <Route path="/pdf" element={<OffBoardingPdf />} />
           
        </Routes>
        </TokenProvider>
       
      </HelmetProvider>
    </Router>
  );
}

export default App;
