import axios from 'axios';


const OffboardingTabProgress = async (token,employee_id) => {
  try {
        const response = await axios.get(
            `${process.env.REACT_APP_API_URL}api/offboarding/employee-tab-progress/`+ employee_id,
           {
                headers: {
                    'token': token,
                    'accept': 'application/json',
                },
            }

        );
       return response.data;
    } catch (error) {
        console.error('Error fetching candidate details:', error);
        if (error.response) {
            console.log('Response data:', error.response.data);
        }
        throw error;
    }
};


export default OffboardingTabProgress;