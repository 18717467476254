import React, { useState, useEffect } from 'react';
import { useToken } from '../../../context/AuthContext';
import styles from './Main.module.scss';
import Basic from '../../../assets/images/basic-details-icon.svg';
import Family from '../../../assets/images/family-icon.svg';
import Professional from '../../../assets/images/professional-icon.svg';
import Education from '../../../assets/images/education-icon.svg';
import Information from '../../../assets/images/other-information-icon.svg';
import StepItem from '../../../components/StepItem/StepItem';
import GetCandidateDetails from '../../../api/fetchCandidateData';
import SaveCandidateData from '../../../api/SaveCandidateData';
import StepEmitter from '../../../components/StepEmitter/StepEmitter';

const Application = ({ submitStep, setPopupVisible, popupVisible, displayStyle, setStep, apiDataStatus, error, isEdit, setIsEdit, stepNumber }) => {
  const { token, zoho_candidate_id } = useToken();
  const [showErrorMessageModal, setShowErrorMessageModal] = useState(false);
  const [showSuccessMessageModal, setShowSuccessMessageModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [updatedDisplayStyle, setUpdatedDisplayStyle] = useState(displayStyle);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const itemClassName = (
    (updatedDisplayStyle && updatedDisplayStyle.trim() === 'inline-block' && !showErrorMessageModal && !showSuccessMessageModal) ? '' : 'disable'
  );

  console.log(submitStep)

  const fetchCandidateDetails = async () => {
    if (apiDataStatus !== 200 && error) {
      setErrorMessage(error);
      setShowErrorMessageModal(true);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchCandidateDetails();
  }, [apiDataStatus, error]);

  useEffect(() => {
    if (displayStyle === 'inline-block') {
      setIsEdit(true);
    } else {
      setIsEdit(false);
    }
  }, [displayStyle, setIsEdit]);

  useEffect(() => {
    if (showSuccessMessageModal) {
      setUpdatedDisplayStyle('disable');
    } else {
      setUpdatedDisplayStyle(displayStyle);

    }
  }, [showSuccessMessageModal, displayStyle]);

  const handleButtonClick = (stepNumber) => {
    setPopupVisible(true);
    setStep(stepNumber);
    StepEmitter.emit('stepChange', stepNumber);
    document.body.classList.add(styles['fixed-overlay']);
    document.body.classList.remove(styles['fixed-overlay-preview']);
  };

  const handleCloseSuccessPopup = () => {
    setShowSuccessMessageModal(false);
  };

  const handlePreviewPopupClick = () => {
    setPopupVisible(true);
    document.body.classList.add(styles['fixed-overlay-preview']);
    document.body.classList.add(styles['fixed-overlay']);
    setStep(11);
    StepEmitter.emit('stepChange', 11);
  };

  const handleContinueClick = async () => {
    console.log('ff')
    try {
      const details = await GetCandidateDetails(token, zoho_candidate_id);
      if (details == 1) {
        console.log('Form already submitted');
        setSuccessMessage('Form submitted successfully');
        setShowSuccessMessageModal(true);
        setUpdatedDisplayStyle('disable');
      }
      else {
        const data = details.data;
        const transformedData = transformData(data);


        const sendResponse = await SaveCandidateData(transformedData);
        if (sendResponse.status === 200) {

          setSuccessMessage('Form submitted successfully');
          setShowSuccessMessageModal(true);
          setUpdatedDisplayStyle('disable');
        }

      }



    } catch (error) {
      // Handle error if necessary
    }
  };
  if (submitStep === 'Yes') {
    handleContinueClick();
  }

  const transformData = (data) => {
    data.basic_details.FormStatus = 'Submitted';
    const transformedData = {
      basic_details: data.basic_details,
      family_details: data.candidate_family_details,
      candidate_references_details: data.candidate_references_details,
      candidate_professional_details: data.candidate_professional_details,
      candidate_educational_details: data.candidate_educational_details,
      candidate_computer_skills: data.candidate_computer_skills,
      candidate_language_known_skills: data.candidate_language_known_skills,
      candidate_scholarship_award: data.candidate_scholarship_award,
      candidate_other_information: data.candidate_other_information,
      'Zoho_Item_Id': zoho_candidate_id,
      Process_Status: "11"
    };
    return transformedData;
  };

  return (
    <div className={`${styles['small-box']} ${!popupVisible ? '' : styles.hidden}`}>
      <div className={styles.main}>
        <ul>
          <StepItem
            stepNumber={1}
            icon={Basic}
            title="Basic<br>Details"
            description="Fill basic Information and address."
            onClick={() => handleButtonClick(2)}
            className={(stepNumber === 4 || stepNumber === 5 || stepNumber === 6 || stepNumber === 7 || stepNumber === 8 || stepNumber === 9 || stepNumber === 10 || stepNumber === 11 || apiDataStatus === 200) ? 'continue' : 'disable'}
            displayStyle={updatedDisplayStyle}
          />
          <StepItem
            stepNumber={2}
            icon={Family}
            title="Family/<br>Reference"
            description="Fill details about your family and professional references."
            onClick={() => handleButtonClick(5)}
            className={(stepNumber === 5 || stepNumber === 6 || stepNumber === 7 || stepNumber === 8 || stepNumber === 9 || stepNumber === 10 || stepNumber === 11) ? 'continue' : itemClassName}
            displayStyle={updatedDisplayStyle}
          />

          <StepItem
            stepNumber={3}
            icon={Professional}
            title="Professional<br>Experience"
            description="Required details of your work experience."
            onClick={() => handleButtonClick(7)}
            className={(stepNumber === 7 || stepNumber === 8 || stepNumber === 9 || stepNumber === 10 || stepNumber === 11 || stepNumber === 11) ? 'continue' : itemClassName}
            displayStyle={updatedDisplayStyle}
          />

          <StepItem
            stepNumber={4}
            icon={Education}
            title="Education/<br>Awards"
            description="Required details of your education."
            onClick={() => handleButtonClick(8)}
            className={(stepNumber === 8 || stepNumber === 9 || stepNumber === 10 || stepNumber === 11) ? 'continue' : itemClassName}
            displayStyle={updatedDisplayStyle}
          />

          <StepItem
            stepNumber={5}
            icon={Information}
            title="Other<br>Information"
            description="You can fill any other information and your salary details."
            onClick={() => handleButtonClick(9)}
            className={(stepNumber === 9 || stepNumber === 10 || stepNumber === 11 || stepNumber === 12) ? 'continue' : itemClassName}
            displayStyle={updatedDisplayStyle}
          />

        </ul>

        {updatedDisplayStyle === 'inline-block' && (
          <ol className={`${styles.btnContent} ${styles.btnContent2}`} style={{ display: 'block' }}>
            <li>
              <a className={`${styles.btnContinue} ${styles.BTNpreview}`} onClick={handlePreviewPopupClick}>Preview</a>
              <a className={styles.btnContinue} onClick={handleContinueClick}>Submit Form</a>
            </li>
          </ol>
        )}

        {showErrorMessageModal && (
          <div id="myModalerror" className={styles.modal}>
            <div className={styles['modal-content']}>
              <p>{errorMessage}</p>
            </div>
          </div>
        )}

        {showSuccessMessageModal && (
          <div id="myModal" className={styles.modal}>
            <div className={styles['modal-content']}>
              <p>{successMessage}</p>
            </div>
          </div>
        )}

      </div>
    </div>
  );
};

export default Application;
