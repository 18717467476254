import React, { useState, useEffect } from 'react';
import BoadingHeader from '../Header/BoadingHeader';
import styles from '../Boading.module.scss';
import EmployeeDetails from '../EmployeeDetails/EmployeeDetails';
import Tabs from '../Tabs/Tabs';
import BoadingFooter from '../Footer/BoadingFooter';
import { useToken } from '../../context/AuthContext';
import AdminAuth from '../../api/AdminAuth'
import { Helmet } from 'react-helmet-async';

const AddEmployee = () => {
    const [showUnoutherizedModal, setShowUnoutherizedModal] = useState(false);
    const [authorized, setAuthorized] = useState(false);
    const [employeeId, setEmployeeId] = useState('');
    const [adminName, setadminName] = useState();
    const [department, setdepartment] = useState();
    const [activeTab, setActiveTab] = useState(0);
    const [token, setToken] = useState('');
    const [offboardingTabs, setOffboardingTabs] = useState([]);
    const [TabStatus, setTabStatus] = useState([]);
    useEffect(() => {
        const checkAuthorization = async () => {
            const urlParams = new URLSearchParams(window.location.search);
            const token = urlParams.get('token');
            const employee_Id = urlParams.get('employeeId');
            
            setEmployeeId(employee_Id);
            if (token) {
                try {
                    const authResult = await AdminAuth(token);
                    if (authResult && authResult.status === 200 && authResult.authenticate) {
                        setAuthorized(true);
                        setadminName(authResult.user_name);
                        setdepartment(authResult.department);
                        setToken(authResult.token);
                        setShowUnoutherizedModal(false);
                        setOffboardingTabs(authResult.onboarding_tabs || []);
                        
                    } else {
                        setAuthorized(false);
                        setShowUnoutherizedModal(true);
                    }
                } catch (error) {
                    setAuthorized(false);
                    setShowUnoutherizedModal(true);
                    console.error('Error checking authorization:', error);

                }
            }
        };

        checkAuthorization();
    }, []);
    
    return (
        <>
            <Helmet>
                <title>OnBoarding</title>

            </Helmet>
            {showUnoutherizedModal && (
                <div id="myModal" className={styles.modal}>
                    <div className={styles['modal-content']}>
                        <p>Yor are not authorized to access this page.</p>
                    </div>
                </div>
            )}
            {!showUnoutherizedModal && (
                <section className={`${styles.container} ${styles['box-shadow']} `}>
                    <BoadingHeader admin={adminName} />
                    <Tabs department={department} authorized={authorized} token={token} employeeId={employeeId} setActiveTab={setActiveTab} admin={adminName} tabs={offboardingTabs} tabStatus={TabStatus}/>
                    {activeTab === 0 && <EmployeeDetails department={department} authorized={authorized} token={token} employeeId={employeeId} />}
                    <BoadingFooter />
                </section>
            )}
        </>

    );
};

export default AddEmployee;
