import React, { useEffect, useState } from 'react';
import styles from '../Boading.module.scss';
import EmployeeInfo from '../EmployeeInfo/EmployeeInfo';
import DatePicker from "react-datepicker";
import OnboadingDetails from '../../api/FetchOnboaingDetail';
import OnboadingSaveEmployeeDetails from '../../api/SaveOnboadingData';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select';

const Performance = ({ department, isActive, authorized, token, employeeId, setEmployeeId, admin, disableTab, onSubmit, activeTabIndex }) => {
    const [loading, setLoading] = useState(true);
    const [start_date, setStartDate] = useState(null);
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');
    const [buttonsDisabled, setButtonsDisabled] = useState(false);
    const [selectedBuddyEmail, setSelectedBuddyEmail] = useState(null);
    const [selectedBuddyEmail2, setSelectedBuddyEmail2] = useState(null);
    const [emailOptions, setEmailOptions] = useState([]);
    const [formData, setFormData] = useState({
        buddy_email: '',
        buddy_email2: '',
        induction: false,
        basic_training: false,
        buddy_introductory_email_sent: false,
        buddy_assigned: false,
        meeting_schedule: null,
        emails: [],
        comment: ''
    });
    const [errors, setErrors] = useState({
        buddy_assigned: false,
        buddy_email: false,
        buddy_email2: false,
        buddy_introductory_email_sent: false
    });
    const handleChange = (e) => {
        setEmail(e.target.value);
        if (!e.target.validity.valid) {
            setEmailError('Please enter a valid email address');
        } else {
            setEmailError('');
        }
        const { name, value } = e.target;
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value
        }));
    };

    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: checked,
            ...(name === 'induction' && !checked && { meeting_schedule: null })
        }));
        if (name === 'induction' && !checked) {
            setStartDate(null);
        }
    };

    const handleDateChange = (date) => {
        setFormData({ ...formData, meeting_schedule: date });
        
    };

    const handleSelectChange1 = (selectedOption) => {
        setSelectedBuddyEmail(selectedOption);
    };

    const handleSelectChange2 = (selectedOption) => {
        setSelectedBuddyEmail2(selectedOption);
    };

    useEffect(() => {
        const fetchData = async () => {
            if (authorized) {
                try {
                    setLoading(true);
                    const url = 'get-employee-performance';
                    const employeeData = await OnboadingDetails(url, token, employeeId);
                    if (employeeData.status === 200) {
                        const empDetails = employeeData.performance;
                        const buddyEmails = empDetails.emails;
                        const options = [
                            { value: '', label: 'Please select buddy email' },
                            ...buddyEmails.map(emailObj => {
                                const emailID = Object.keys(emailObj)[0];
                                const email = emailObj[emailID];
                                return { value: email, label: email, emailID };
                            })
                        ];
                        setEmailOptions(options);
                        setFormData({
                            buddy_email: empDetails.buddy_email || '',
                            buddy_email2: empDetails.buddy_email2 || '',
                            induction: empDetails.induction || false,
                            basic_training: empDetails.basic_training || false,
                            meeting_schedule: empDetails.meeting_schedule || '',
                            buddy_introductory_email_sent: empDetails.buddy_introductory_email_sent || false,
                            buddy_assigned: empDetails.buddy_assigned || false,
                            comment: empDetails.comment || ''
                        });
                        // Set the selected options based on empDetails
                        const selectedOption1 = options.find(option => option.value === empDetails.buddy_email);
                        const selectedOption2 = options.find(option => option.value === empDetails.buddy_email2);
                        setSelectedBuddyEmail(selectedOption1 || null);
                        setSelectedBuddyEmail2(selectedOption2 || null);
                        if (empDetails.status === 'Submitted') {
                            setButtonsDisabled(true);
                        }
                    } else {
                        console.error('Error fetching employee data:', employeeData.error);
                    }
                } catch (error) {
                    console.error('Error fetching employee data:', error);
                } finally {
                    setLoading(false);
                }
            }
        };

        fetchData();
    }, [authorized, token, employeeId]);

    const hanldeSaveInfo = async (event) => {
        event.preventDefault();
        if (formData.induction && !formData.meeting_schedule) {
            toast.error('Please enter a meeting schedule date for induction');
            return;
        }
        if (!formData.comment) {
            toast.error('Please Provide Comment!');
            return;
        }
        try {
            setLoading(true);
            const url = 'save-employee-performance';
            const requestData = {
                employee_id: employeeId,
                performance: {
                    ...formData,
                    employee_id: employeeId,
                    status: "Pending",
                    created_by: admin,
                    buddy_email: selectedBuddyEmail?.value,
                    buddy_id: selectedBuddyEmail?.emailID,
                    buddy_email2: selectedBuddyEmail2?.value,
                    buddy2_id: selectedBuddyEmail2?.emailID,
                }
            };

            const response = await OnboadingSaveEmployeeDetails(url, token, employeeId, requestData);
            toast.success(response.msg);
        } catch (error) {
            console.error('Error saving form data:', error);
        } finally {
            setLoading(false);
        }
    }
    const handleSubmit = async (event) => {

        event.preventDefault();
        if (formData.induction && !formData.meeting_schedule) {
            toast.error('Please enter a meeting schedule date for induction');
            return;
        }
        if (!formData.comment) {
            toast.error('Please Provide Comment!');
            return;
        }
        const newErrors = {};

        let errorMessages = [];

        if (!formData.buddy_assigned) {
            newErrors.buddy_assigned = 'Buddy Assigned is required';
            errorMessages.push(newErrors.buddy_assigned);
        }
        if (!selectedBuddyEmail || selectedBuddyEmail.value === '') {
            newErrors.buddy_email = 'Buddy email 1 is required';
            errorMessages.push(newErrors.buddy_email);
        }
        if (!formData.buddy_introductory_email_sent) {
            newErrors.buddy_introductory_email_sent = 'Buddy Introductory Email Sent is required';
            errorMessages.push(newErrors.buddy_introductory_email_sent);
        }
        if (formData.induction && !formData.meeting_schedule) {
            newErrors.meeting_schedule = 'Meeting schedule is required for induction';
            errorMessages.push(newErrors.meeting_schedule);
        }
        //setErrors(newErrors);

        if (Object.keys(newErrors).length > 0) {
            toast.error(errorMessages.join(' ')); 
            return;
        }
        try {
            setLoading(true);
            const url = 'save-employee-performance';
            const requestData = {
                employee_id: employeeId,
                performance: {
                    ...formData,
                    employee_id: employeeId,
                    status: "Submitted",
                    created_by: admin,
                    buddy_email: selectedBuddyEmail?.value,
                    buddy_id: selectedBuddyEmail?.emailID,
                    buddy_email2: selectedBuddyEmail2?.value,
                    buddy2_id: selectedBuddyEmail2?.emailID,
                }
            };

           const response = await OnboadingSaveEmployeeDetails(url, token, employeeId, requestData);
           toast.success(response.msg);
           setButtonsDisabled(true);
        } catch (error) {
            console.error('Error saving form data:', error);
        } finally {
           
            setLoading(false);
        }

    }
    return (
        <div className={styles.employee}>
            <div className={styles.grid}>
                <div className={styles['edit-bg']}></div>
                <EmployeeInfo token={token} employeeId={employeeId} />

                <div className={styles.col9}>
                    <div className={`${styles['box-shadow']} ${styles['border-radius']} `}>
                        <fieldset className={styles.fieldset}>
                            <legend style={{ paddingBottom: "20px" }}>Performance Management</legend>
                            <div className={styles.grid}>
                                <div className={styles.col6}>
                                    <div className={styles['right-space']}>
                                        <ul className={styles['two-col']}>
                                            <li>
                                                <strong>Buddy Assigned<i className={styles['red']}>*</i>:</strong>
                                                <span>

                                                    <input
                                                        type="checkbox"
                                                        id="buddy_assigned"
                                                        name="buddy_assigned"
                                                        value="buddy_assigned"
                                                        className={errors.buddy_assigned ? styles['empl-error'] : ''}
                                                        checked={formData.buddy_assigned}
                                                        onChange={handleCheckboxChange}
                                                    />
                                                    <label htmlFor="buddy_assigned" className={errors.buddy_assigned ? styles['empl-error'] : ''}></label>

                                                </span>
                                            </li>
                                            <li className={errors.buddy_email ? styles.error : ''}>
                                                <strong>Buddy email 1<i className={styles['red']}>*</i>:</strong>
                                                <span>


                                                    <Select
                                                        value={selectedBuddyEmail}
                                                        onChange={handleSelectChange1}
                                                        options={emailOptions}
                                                        placeholder="Select Buddy 1"
                                                        name="buddy_email"
                                                    />

                                                </span>
                                                {errors.buddy_email && <div className={styles.errorMessage}>{errors.buddy_email}</div>}
                                            </li>
                                            <li className={errors.buddy_email2 ? styles.error : ''}>
                                                <strong>Buddy email 2:</strong>
                                                <span>


                                                    <Select
                                                        value={selectedBuddyEmail2}
                                                        onChange={handleSelectChange2}
                                                        options={emailOptions}
                                                        placeholder="Select Buddy 2"
                                                    />

                                                </span>
                                                {errors.buddy_email2 && <div className={styles.errorMessage}>{errors.buddy_email2}</div>}
                                            </li>
                                            <li className={errors.buddy_introductory_email_sent ? styles.error : ''}>
                                                <strong>Buddy Introductory Email Sent<i className={styles['red']}>*</i>:</strong>
                                                <span>
                                                    <input
                                                        type="checkbox"
                                                        id="buddy_introductory_email_sent"
                                                        name="buddy_introductory_email_sent"
                                                        value="buddy_introductory_email_sent"
                                                        checked={formData.buddy_introductory_email_sent}
                                                        onChange={handleCheckboxChange}
                                                    />
                                                    <label htmlFor="buddy_introductory_email_sent" className={errors.buddy_assigned ? styles['empl-error'] : ''}></label>
                                                </span>

                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div className={styles.col6}>
                                    <div className={styles['left-space']}>
                                        <ul className={styles['two-col']}>
                                            <li>
                                                <strong>Induction Meeting Scheduled:</strong>
                                                <span>
                                                    <input
                                                        type="checkbox"
                                                        id="induction"
                                                        name="induction"
                                                        value="induction"
                                                        checked={formData.induction}
                                                        onChange={handleCheckboxChange}
                                                    />
                                                    <label htmlFor="induction"></label>
                                                    <DatePicker
                                                        selected={formData.meeting_schedule}
                                                        onChange={handleDateChange}
                                                        peekNextMonth
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        dropdownMode="select"
                                                        className={styles.input}
                                                        id="Meeting"
                                                        name="Meeting"
                                                        autoComplete="off"
                                                        required
                                                    />
                                                </span>
                                            </li>
                                            <li>
                                                <strong>Basic Training and Policy Document Sent:</strong>
                                                <span>
                                                    <input
                                                        type="checkbox"
                                                        id="basic_training"
                                                        name="basic_training"
                                                        value="basic_training"
                                                        checked={formData.basic_training}
                                                        onChange={handleCheckboxChange}
                                                    />
                                                    <label htmlFor="basic_training"></label>
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className={styles['right-space']}>
                                    <ul className={styles['two-col']}>
                                        <li>
                                            <strong>Comment<i className={styles['red']}>*</i>:</strong>
                                            <span>
                                                <textarea
                                                    name="comment"
                                                    className={styles.input}
                                                    value={formData.comment}
                                                    onChange={handleChange}
                                                ></textarea>
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                                {/* Button block */}
                                <div className={styles['button-block']}>
                                {(!buttonsDisabled) && (
                                        <input type="submit" value="Save" className={styles.button} onClick={hanldeSaveInfo} disabled={loading || !!emailError} />
                                    )}
                                    {(!buttonsDisabled ) && (
                                        <input
                                            type="submit"
                                            value={buttonsDisabled ? 'Submitted' : 'Submit'}
                                            className={styles.button}
                                            onClick={handleSubmit}
                                            disabled={loading || !!emailError}
                                        />
                                    )}
                                    <ToastContainer />


                                </div>
                            </div>
                        </fieldset>
                    </div>
                </div>
                {loading && <div className={`${styles['loader-center']} ${styles['loaderHeight-sales']} `}>
                    <div className={`${styles['newsloader-container']} ${styles['newsloaderContainer-payroll']} `}>
                        <div className={styles.newsloader}></div></div>
                </div>}
            </div>
        </div>
    );
};

export default Performance;
