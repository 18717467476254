import React, { useState, useEffect } from 'react';
import styles from '../MainContent/Main.module.scss';
import { useToken } from '../../../context/AuthContext'
import StepEmitter from '../../../components/StepEmitter/StepEmitter'
import FechCandidateImage from '../../../api/FetchImageData'
import GetCandidateDetails from '../../../api/fetchCandidateData'
import logo from '../../../assets/images/logo.svg';
import SaveCandidateData from '../../../api/SaveCandidateData';
const Preview = ({ step, setStep, isEdit, setIsEdit, handlePreviewClick, apiData, displayStyle, setDisplayStyle }) => {
  const { token, zoho_candidate_id } = useToken();
  const [familyDetails, setFamilyDetails] = useState([]);
  const [refrenceDetails, setRefrenceDetails] = useState([]);
  const [professionalDetails, setProfessionalDetails] = useState([]);
  const [educationDetails, setEducationDetails] = useState([]);
  const [skillsDetails, setSkillsDetails] = useState([]);
  const [awardDetails, setAwardsDetails] = useState([]);
  const [languageDetails, setLanguageDetails] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [image, setImage] = useState('');
  
  const [formData, setFormData] = useState({
    First_Name: '',
    Middle_Name: '',
    Last_Name: '',
    Height: '',
    Weight: '',
    Date_Of_Birth: '',
    Blood_Group: '',
    Identification_Marks: '',
    Email: '',
    Email2: '',
    Email3: '',
    work_experience: '',
    emergency_contact_number: '',
    emergency_contact_relation: '',
    emergency_contact_name: '',
    Gender: '',
    Marital_Status: '',
    image: null,
    Present_Address: '',
    Present_City: '',
    Present_State: '',
    Present_Country: '',
    Present_Zip_Code: '',
    Permanent_Phone: '',
    Permanent_Address: '',
    Permanent_Country: '',
    Permanent_State: '',
    Permanent_City: '',
    Permanent_Zip_Code: '',
    Permanent_Phones: [],
    Referred_For_Employment: "",
    Criminal_Case: "",
    Major_illness: "",
    Vehicle_Detail: "",
    Abroad_Detail: "",
    Passport_Detail: "",
    Aim_In_Life: "",
    Yourself_after_Years: "",
    Hobby: "",
    Other_Information: "",
    Current_Salary: "",
    Expected_Salary: "",
    Notice_preiod: "",
    When_Join: "",
    Declare_Status: "",
    Candidate_Resume:"",
  });

  const handleBasicInfo = (option) => {
    if (!isEdit) {
      setIsEdit(true);
    }
    setDisplayStyle('inline-block');
    
    StepEmitter.emit('stepChange', option);
    //handlePreviewClick();
  }





  useEffect(() => {
    if (step === 11) {
      document.body.classList.add(styles['fixed-overlay-preview']);
      document.body.classList.remove(styles['close-popup']);

    } else {
      document.body.classList.remove(styles['fixed-overlay-preview']);
    }
    return () => {
      document.body.classList.remove(styles['fixed-overlay-preview']);
    };
  }, [step]);

  const handleClosePopup = () => {
    //setIsEdit(!isEdit);
    StepEmitter.emit('stepChange', 1);
    document.body.classList.remove(styles['fixed-overlay-preview']);
    handlePreviewClick();

  };
  const parseDate = (dateString) => {
    const dateParts = dateString.split('-');
    const day = dateParts[2];
    const month = dateParts[1];
    const year = dateParts[0].slice(-4);
    return `${month}/${day}/${year}`;
  };

  const fetchCandidateDetails = async () => {
    try {
      const details = await GetCandidateDetails(token, zoho_candidate_id);
      const apiData = details.data;
      const basicDetails = apiData.basic_details;
     


      setFormData({
        ...formData,
        First_Name: basicDetails.First_Name || '',
        Middle_Name: basicDetails.Middle_Name || '',
        Last_Name: basicDetails.Last_Name || '',
        Height: basicDetails.Height || '',
        Weight: basicDetails.Weight || '',
        Date_Of_Birth: parseDate(basicDetails.Date_Of_Birth || ''),
        Blood_Group: basicDetails.Blood_Group || '',
        Identification_Marks: basicDetails.Identification_Marks || '',
        Email: basicDetails.Email || '',
        Email2: basicDetails.Email2 || '',
        Email3: basicDetails.Email3 || '',
        work_experience: basicDetails.work_experience || '',
        emergency_contact_number: basicDetails.emergency_contact_number || '',
        emergency_contact_relation: basicDetails.emergency_contact_relation || '',
        emergency_contact_name: basicDetails.emergency_contact_name || '',
        Gender: apiData.basic_details.Gender || '',
        Marital_Status: apiData.basic_details.Marital_Status || '',
        Present_Address: apiData.basic_details.Present_Address || '',
        Present_City: apiData.basic_details.Present_City || '',
        Present_State: apiData.basic_details.Present_State || '',
        Present_Country: apiData.basic_details.Present_Country || '',
        Present_Zip_Code: apiData.basic_details.Present_Zip_Code || '',
        Present_Phone: apiData.basic_details.Present_Phone || '',
        Present_Phone2: apiData.basic_details.Present_Phone2 || '',
        Present_Phone3: apiData.basic_details.Present_Phone3 || '',
        Permanent_Address: apiData.basic_details.Permanent_Address || '',
        Permanent_City: apiData.basic_details.Permanent_City || '',
        Permanent_State: apiData.basic_details.Permanent_State || '',
        Permanent_Country: apiData.basic_details.Permanent_Country || '',
        Permanent_Zip_Code: apiData.basic_details.Permanent_Zip_Code || '',
        Referred_For_Employment: apiData.candidate_other_information.Referred_For_Employment || '',
        Criminal_Case: apiData.candidate_other_information.Criminal_Case || '',
        Major_illness: apiData.candidate_other_information.Major_illness || '',
        Vehicle_Detail: apiData.candidate_other_information.Vehicle_Detail || '',
        Abroad_Detail: apiData.candidate_other_information.Abroad_Detail || '',
        Passport_Detail: apiData.candidate_other_information.Passport_Detail || '',
        Aim_In_Life: apiData.candidate_other_information.Aim_In_Life || '',
        Yourself_after_Years: apiData.candidate_other_information.Yourself_after_Years || '',
        Hobby: apiData.candidate_other_information.Hobby || '',
        Other_Information: apiData.candidate_other_information.Other_Information || '',
        Current_Salary: apiData.candidate_other_information.Current_Salary || '',
        Expected_Salary: apiData.candidate_other_information.Expected_Salary || '',
        Notice_preiod: apiData.candidate_other_information.Notice_preiod || '',
        When_Join: apiData.candidate_other_information.When_Join || '',
        Declare_Status: apiData.candidate_other_information.Declare_Status || '',
        Candidate_Resume:apiData.candidate_other_information.Candidate_Resume

      });
      //family data
      const candidate_family_details = apiData.candidate_family_details;
      setFamilyDetails(candidate_family_details || []);
      // refrence data
      const candidate_references_details = apiData.candidate_references_details;
      setRefrenceDetails(candidate_references_details || []);
      //professional data
      const candidate_professional_details = apiData.candidate_professional_details;
      setProfessionalDetails(candidate_professional_details || []);
      //educational data
      const candidate_educational_details = apiData.candidate_educational_details;
      setEducationDetails(candidate_educational_details || []);
      //skills data
      const candidate_computer_skills = apiData.candidate_computer_skills;
      setSkillsDetails(candidate_computer_skills || []);
      //awards data
      const candidate_scholarship_award = apiData.candidate_scholarship_award;
      setAwardsDetails(candidate_scholarship_award || []);
      //language data
      const candidate_language_known_skills = apiData.candidate_language_known_skills;
      setLanguageDetails(candidate_language_known_skills || []);




    } catch (error) {
      console.error('Error fetching candidate details:', error);

    }
  };

  useEffect(() => {
    fetchCandidateDetails();
  }, [token, zoho_candidate_id]);

  useEffect(() => {
    const fetchImageURL = async () => {
      // const update = 'false';

      try {
        const response = await FechCandidateImage(zoho_candidate_id); // Pass null as formData
        const imageUrl = response.picture_url;
        setImage(imageUrl);
      } catch (error) {
        console.error('Error fetching image URL:', error);
      }
    };

    fetchImageURL();
  }, [zoho_candidate_id]);

  const transformData = (data) => {
    data.basic_details.FormStatus = 'Submitted';
    const transformedData = {
      basic_details: data.basic_details,
      family_details: data.candidate_family_details,
      candidate_references_details: data.candidate_references_details,
      candidate_professional_details: data.candidate_professional_details,
      candidate_educational_details: data.candidate_educational_details,
      candidate_computer_skills: data.candidate_computer_skills,
      candidate_language_known_skills: data.candidate_language_known_skills,
      candidate_scholarship_award: data.candidate_scholarship_award,
      candidate_other_information: data.candidate_other_information,
      'Zoho_Item_Id': zoho_candidate_id,
      Process_Status: "11"
    };
    return transformedData;
  };
const handleSubmitClick = async () =>{
  try {
    const details = await GetCandidateDetails(token, zoho_candidate_id);
    const data = details.data;
    const transformedData = transformData(data);
    const sendResponse = await SaveCandidateData(transformedData);
    if (sendResponse.status === 200) {
      
      handlePreviewClick('Yes');
    }
  } catch (error) {
    // Handle error if necessary
  }
  

}
  const handleEditClick = () => {
    setIsEdit(!isEdit);
    StepEmitter.emit('stepChange', 1);
    document.body.classList.remove(styles['fixed-overlay-preview']);
    //handlePreviewClick();
  };

  return (
    <>
      {step === 11 && (
        <div className={styles['popup-preview']} style={{ display: step === 11 ? 'block' : 'none' }}>
          <div className={styles['popup-content-preview']}> <i className={styles['close-popup-preview']} onClick={handleClosePopup}></i>
            <div className={styles['header-previwleft']}><img src={logo} alt="BluEnt" /></div>
            <div><h2><span>Preview</span> of APPLICATION FOR EMPLOYMENT</h2></div>

            <h4 className={styles['accordion-btn']} id="first-active"><span></span> Basic Details</h4>
            <div className={styles['accordion-content']}>
              <table cellPadding="0" cellSpacing="0" className={styles['basicTABLE']}>
                <tr>
                  <td className={styles.colSpan} colSpan="3">
                    <h5>Basic Details
                      &nbsp;
                      <a className={`${styles.preEdit} ${styles.popBTN}`} onClick={() => handleBasicInfo(2)}> <i className={styles.editIcon} ></i>
                      </a></h5>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className={styles['span']}>First Name<i className={styles['red']}>*</i>:</span>
                    <div className={styles.div}>
                      <label className={styles['P-label']}></label>
                      <input type="text" id="prview_first_name" name="First_Name" className={styles.input} value={formData.First_Name} disabled />

                    </div>
                  </td>
                  <td>
                    <span className={styles.span}>Middle Name:</span>
                    <div className={styles.div}>
                      <label className={styles['P-label']}></label>
                      <input type="text" id="prview_middle_name" name="Middle_Name" className={styles.input} value={formData.Middle_Name} disabled />

                    </div>
                  </td>

                  <td rowSpan={2}>
                    <span className={styles.span}>Upload Photo: <span className={styles['span-small']}>(Height - 200px, Width - 200px)</span><a className={`${styles.preEdit} ${styles.popBTN}`} onClick={() => handleBasicInfo(3)}> <i className={styles.editIcon} ></i>
                    </a></span>
                    <div className={styles.div}>
                      <div className={`${styles['avatar-upload']} ${styles['avatar-upload2']}`}>
                        <div className={`${styles['avatar-preview']}  ${styles['avatar-preview2']}`}>
                          <label className={styles['P-label']}></label>
                          {image && <img src={image} className={` ${styles.disabled}`} alt="Uploaded" />}
                        </div>
                      </div>
                    </div>

                  </td>

                </tr>
                <tr>
                  <td>
                    <span className={styles.span}>Last Name<i className={styles.red}>*</i>:</span>
                    <div className={styles.div}>
                      <label className={styles['P-label']}></label>
                      <input type="text" id="prview_last_name" name="Last_Name" className={styles.input} value={formData.Last_Name} disabled />

                    </div>
                  </td>
                  <td>
                    <span className={styles.span}>Email Id<i className={styles.red}>*</i>: </span>
                    <div className={styles.div}>
                      <label className={styles['P-label']}></label>
                      <input type="text" id="prview_" name="Email" className={styles.input}
                        value={formData.Email} disabled />
                      <input type="text" id="prview_" name="Email2" className={styles.input}
                        value={formData.Email2} disabled />
                      <input type="text" id="prview_" name="Email3" className={styles.input}
                        value={formData.Email3} disabled />
                    </div>
                  </td>


                </tr>
                <tr>
                  <td>
                    <span className={styles.span}>Date of Birth<i className={styles.red}>*</i>: <span className={styles['span-small']}>(mm/dd/yyyy)</span>
                    </span>
                    <div className={styles.div}>
                      <label className={styles['P-label']}></label>
                      <input type="text" id="prview_date_of_birth" name="Date_Of_Birth" className={styles.input} value={formData.Date_Of_Birth} disabled />
                    </div>
                  </td>
                  <td>
                    <span className={styles.span}>Height: <span className={styles['span-small']}>(In cm)</span> </span>
                    <div className={styles.div}>
                      <label className={styles['P-label']}></label>
                      <input type="number" id="prview_height" name="Height" className={styles.input} value={formData.Height} disabled />
                    </div>
                  </td>
                  <td>
                    <span className={styles.span}>Weight: <span className={styles['span-small']}>(In Kgs)</span> </span>
                    <div className={styles.div}>
                      <label className={styles['P-label']}></label>
                      <input type="number" id="prview_weight" name="Weight" className={styles.input} value={formData.Weight} disabled />
                    </div>
                  </td>





                </tr>
                <tr>

                  <td>
                    <span className={styles.span}>Blood Group:</span>
                    <div className={styles.div}>
                      <label className={styles['P-label']}></label>
                      <input type="text" id="prview_blood_group" name="Blood_Group" className={styles.input} value={formData.Blood_Group} disabled />
                    </div>
                  </td>
                  <td>
                    <span className={styles.span}>Identification Marks:</span>
                    <div className={styles.div}>
                      <label className={styles['P-label']}></label>
                      <input type="text" id="prview_identification_marks" name="Identification_Marks" className={styles.input}
                        value={formData.Identification_Marks} disabled />
                    </div>
                  </td>
                  <td>
                    <span className={styles.span}>Work Experience:</span>
                    <div className={styles.div}>
                      <label className={styles['P-label']}></label>
                      <input type="text" id="prview_work_experience" name="work_experience" className={styles.input}
                        value={formData.work_experience} disabled />
                    </div>
                  </td>

                </tr>
                <tr>


                  <td>
                    <span className={styles.span}>Emergency Contact Name:</span>
                    <div className={styles.div}>
                      <label className={styles['P-label']}></label>
                      <input type="text" id="prview_emergency_contact_name" name="emergency_contact_name" className={styles.input}
                        value={formData.emergency_contact_name} disabled />
                    </div>
                  </td>
                  <td>
                    <span className={styles.span}>Emergency Contact Relation:</span>
                    <div className={styles.div}>
                      <label className={styles['P-label']}></label>
                      <input type="text" id="prview_emergency_contact_relation" name="emergency_contact_relation" className={styles.input}
                        value={formData.emergency_contact_relation} disabled />
                    </div>
                  </td>
                  <td>
                    <span className={styles.span}>Emergency Contact Number:</span>
                    <div className={styles.div}>
                      <label className={styles['P-label']}></label>
                      <input type="text" id="prview_emergency_contact_number" name="emergency_contact_number" className={styles.input}
                        value={formData.emergency_contact_number} disabled />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className={styles.colSpan} colSpan="3">
                    <h5>Status &nbsp;&nbsp;
                      <a className={`${styles.preEdit} ${styles.popBTN}`} onClick={() => handleBasicInfo(3)}> <i className={styles.editIcon} ></i>
                      </a></h5>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className={styles.span}>Sex<i className={styles.red}>*</i>:</span>
                    <div className={styles.div}>
                      <label className={styles['P-label']}></label>
                      <input type="text" id="prview_" name="prview_" className={styles.input} value={formData.Gender} disabled />
                    </div>
                  </td>
                  <td>
                    <span className={styles.span}>Marital Status<i className={styles.red}>*</i>:</span>
                    <div className={styles.div}>
                      <label className={styles['P-label']}></label>
                      <input type="text" id="prview_" name="prview_" className={styles.input} value={formData.Marital_Status} disabled />
                    </div>
                  </td>


                </tr>

                <tr>
                  <td className={styles.colSpan} colSpan="3">
                    <h5>Present Address&nbsp;
                      <a className={`${styles.preEdit} ${styles.popBTN}`} onClick={() => handleBasicInfo(4)}> <i className={styles.editIcon} ></i>
                      </a></h5></td></tr>
                <tr>
                  <td>
                    <span className={styles.span}>Address<i className={styles.red}>*</i>:</span>
                    <div className={styles.div}>
                      <label className={styles['P-label']}></label>
                      <textarea rows="1" cols="1" id="prview_" name="prview_" className={`${styles.input} ${styles.textarea} `} value={formData.Present_Address} disabled></textarea>
                    </div>
                  </td>
                  <td>
                    <span className={styles.span}>City<i className={styles.red}>*</i>:</span>
                    <div className={styles.div}>
                      <label className={styles['P-label']}></label>
                      <input type="text" id="prview_" name="prview_" className={styles.input} value={formData.Present_City} disabled />
                    </div>
                  </td>
                  <td>
                    <span className={styles.span}>State<i className={styles.red}>*</i>:</span>
                    <div className={styles.div}>
                      <label className={styles['P-label']}></label>
                      <input type="text" id="prview_" name="prview_" className={styles.input} value={formData.Present_State} disabled />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className={styles.span}>Country<i className={styles.red}>*</i>:</span>
                    <div className={styles.div}>
                      <label className={styles['P-label']}></label>
                      <input type="text" id="prview_" name="prview_" className={styles.input} value={formData.Present_Country} disabled />
                    </div>
                  </td>
                  <td>
                    <span className={styles.span}>Pin/Zip Code<i className={styles.red}>*</i>:</span>
                    <div className={styles.div}>
                      <label className={styles['P-label']}></label>
                      <input type="text" id="prview_" name="prview_" className={styles.input} value={formData.Present_Zip_Code} disabled />
                    </div>
                  </td>
                  <td>
                    <span className={styles.span}>Cell/Phone<i className={styles.red}>*</i>:</span>
                    <div className={styles.div}>
                      <label className={styles['P-label']}></label>
                      <input type="text" id="" name="" className={styles.input} value={formData.Present_Phone} disabled />
                      <input type="text" id="" name="" className={styles.input} value={formData.Present_Phone2} disabled />
                      <input type="text" id="" name="" className={styles.input} value={formData.Present_Phone3} disabled />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className={styles.colSpan} colSpan="3">
                    <h5>Permanent Address</h5>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className={styles.span}>Address<i className={styles.red}>*</i>:</span>
                    <div className={styles.div}>
                      <label className={styles['P-label']}></label>
                      <textarea rows="1" cols="1" id="" name="" className={`${styles.input} ${styles.textarea} `} value={formData.Permanent_Address} disabled></textarea>
                    </div>
                  </td>
                  <td>
                    <span className={styles.span}>City<i className={styles.red}>*</i>:</span>
                    <div className={styles.div}>
                      <label className={styles['P-label']}></label>
                      <input type="text" id="" name="" className={styles.input} value={formData.Permanent_City} disabled />
                    </div>
                  </td>
                  <td>
                    <span className={styles.span}>State<i className={styles.red}>*</i>:</span>
                    <div className={styles.div}>
                      <label className={styles['P-label']}></label>
                      <input type="text" id="" name="" className={styles.input} value={formData.Permanent_State} disabled />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className={styles.span}>Country<i className={styles.red}>*</i>:</span>
                    <div className={styles.div}>
                      <label className={styles['P-label']}></label>
                      <input type="text" id="" name="" className={styles.input} value={formData.Permanent_Country} disabled />
                    </div>
                  </td>
                  <td>
                    <span className={styles.span}>Pin/Zip Code<i className={styles.red}>*</i>:</span>
                    <div className={styles.div}>
                      <label className={styles['P-label']}></label>
                      <input type="text" id="" name="" className={styles.input} value={formData.Permanent_Zip_Code} disabled />
                    </div>
                  </td>

                </tr>
              </table>

            </div>

            <h4 className={styles['accordion-btn']}><span></span> Family/Reference</h4>
            <div className={styles['accordion-content']}>
              <table cellPadding="0" cellSpacing="0" className={styles.familyTABLE}>
                <tbody>
                  <tr>
                    <td className={styles.colSpan} colSpan="3">
                      <h5>Family Details&nbsp;
                        <a className={`${styles.preEdit} ${styles.popBTN}`} onClick={() => handleBasicInfo(5)}> <i className={styles.editIcon} ></i>
                        </a></h5></td>
                  </tr>
                  {familyDetails.map((member, index) => (
                    <tr key={index}>
                      <td>
                        <span className={styles.span}>Relation<i className={styles.red}>*</i>:</span>
                        <div className={styles.div}>
                          <input type="text" className={styles.input} value={member.Relation} disabled />
                        </div>
                      </td>
                      <td>
                        <span className={styles.span}>Name<i className={styles.red}>*</i>:</span>
                        <div className={styles.div}>
                          <input type="text" className={styles.input} value={member.Name} disabled />
                        </div>
                      </td>
                      <td>
                        <span className={styles.span}>Age<i className={styles.red}>*</i>:</span>
                        <div className={styles.div}>
                          <input type="text" className={styles.input} value={member.Age} disabled />
                        </div>
                      </td>
                      <td>
                        <span className={styles.span}>Occupation<i className={styles.red}>*</i>:</span>
                        <div className={styles.div}>
                          <input type="text" className={styles.input} value={member.Occupation} disabled />
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>


              <div><br /></div>

              <table cellPadding="0" cellSpacing="0" className={styles.referenceTABLE}>
                <tr>
                  <td className={styles.colSpan} colSpan="5">
                    <h5>Whom can we refer about your past experience/credentials?

                      &nbsp;
                      <a className={`${styles.preEdit} ${styles.popBTN}`} onClick={() => handleBasicInfo(6)}> <i className={styles.editIcon} ></i>
                      </a></h5>
                    <p>(Please do not give relatives as reference, Share atleast two references)</p>
                  </td>
                </tr>
                {refrenceDetails.map((member, index) => (
                  <tr key={index}>
                    <td>
                      <span className={styles.span}>Name<i className={styles.red}>*</i>:</span>
                      <div className={styles.div}>
                        <label className={styles['P-label']}></label>
                        <input type="text" id="" name="" className={styles.input} value={member.Name} disabled />
                      </div>
                    </td>
                    <td>
                      <span className={styles.span}>Address<i className={styles.red}>*</i>:</span>
                      <div className={styles.div}>
                        <label className={styles['P-label']}></label>
                        <textarea rows="1" cols="1" id="" name="" className={`${styles.input} ${styles.textarea} `} value={member.Address} disabled></textarea>
                      </div>
                    </td>
                    <td>
                      <span className={styles.span}>Telephone/Cell<i className={styles.red}>*</i>:</span>
                      <div className={styles.div}>
                        <label className={styles['P-label']}></label>
                        <textarea rows="1" cols="1" id="" name="" className={`${styles.input} ${styles.textarea} `} value={member.Mobile} disabled></textarea>
                      </div>
                    </td>
                    <td>
                      <span className={styles.span}>Email Id<i className={styles.red}>*</i>:</span>
                      <div className={styles.div}>
                        <label className={styles['P-label']}></label>
                        <input type="text" id="" name="" className={styles.input} value={member.Email} disabled />
                      </div>
                    </td>
                    <td>
                      <span className={styles.span}>Relation<i className={styles.red}>*</i>:</span>
                      <div className={styles.div}>
                        <label className={styles['P-label']}></label>
                        <input type="text" id="" name="" className={styles.input} value={member.Relation} disabled />
                      </div>
                    </td>
                  </tr>
                ))}
              </table>


            </div>

            <h4 className={styles['accordion-btn']}><span></span> Professional Experience</h4>

            <div className={styles['accordion-content']}>
              <table cellPadding="0" cellSpacing="0" className={styles.professionalTABLE}>
                <tr>
                  <td className={styles.colSpan} colSpan="3">
                    <h5> Professional Experience&nbsp;
                      <a className={`${styles.preEdit} ${styles.popBTN}`} onClick={() => handleBasicInfo(7)}> <i className={styles.editIcon} ></i>
                      </a></h5></td>
                </tr>
                {professionalDetails.map((member, index) => (

                  <React.Fragment key={index}>

                    <tr>
                      <td>
                        <span className={styles.span}>Name of the company<i className={styles.red}>*</i>:</span>
                        <div className={styles.div}>
                          <label className={styles['P-label']}></label>
                          <input type="text" id="" name="" className={styles.input} value={member.Company_Name} disabled />
                        </div>
                      </td>
                      <td>
                        <span className={styles.span}>Name of the manager/employer<i className={styles.red}>*</i>:</span>
                        <div className={styles.div}>
                          <label className={styles['P-label']}></label>
                          <input type="text" id="" name="" className={styles.input} value={member.Employer_Name} disabled />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span className={styles.span}>Period<i className={styles.red}>*</i>:</span>
                        <div className={styles.div}>
                          <div className={styles['row-c']}>
                            <div className={styles['td-left']}>From
                              <label className={styles['P-label']}></label>
                              <input type="text" id="" name="" className={styles.input} value={member.Start_Date} disabled />
                            </div>
                            <div className={styles['td-right']}>To
                              <label className={styles['P-label']}></label>
                              <input type="text" id="" name="" className={styles.input} value={member.End_Date} disabled />
                            </div>
                            <div className={styles.clear}></div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <span className={styles.span}>Salary Drawn<i className={styles.red}>*</i>: <span className={styles['span-small']}>(In lakh per
                          annum)</span></span>
                        <div className={styles.div}>
                          <div className={styles['row-c']}>
                            <div className={styles['td-left']}>Start
                              <label className={styles['P-label']}></label>
                              <input type="text" id="" name="" className={styles.input} value={member.Start_Salary} disabled />
                            </div>
                            <div className={styles['td-right']}>Last
                              <label className={styles['P-label']}></label>
                              <input type="text" id="" name="" className={styles.input} value={member.Last_Salary} disabled />
                            </div>
                            <div className={styles.clear}></div>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className={styles.colSpan} colSpan="2">
                        <span className={styles.span}>Reason for Leaving<i className={styles.red}>*</i>:</span>
                        <div className={styles.div}>
                          <label className={styles['P-label']}></label>
                          <textarea rows="1" cols="1" id="" name="" className={`${styles.input} ${styles.textarea} `}
                            value={member.Reason_For_Leaving} disabled></textarea>
                        </div>
                      </td>
                    </tr>
                  </React.Fragment>
                ))}
              </table>

            </div>


            <h4 className={styles['accordion-btn']}><span></span> Education/Awards</h4>
            <div className={styles['accordion-content']}>
              <table cellPadding="0" cellSpacing="0" className={styles.educationTABLE}>
                <tr>
                  <td className={styles.colSpan} colSpan="3">
                    <h5> Education&nbsp;
                      <a className={`${styles.preEdit} ${styles.popBTN}`} onClick={() => handleBasicInfo(8)}> <i className={styles.editIcon} ></i>
                      </a></h5></td>
                </tr>
                {educationDetails.map((member, index) => (
                  <React.Fragment key={index}>
                    <tr>
                      <td><span className={styles.span}>Qualification<i className={styles.red}>*</i>:</span>
                        <div className={styles.div}>
                          <label className={styles['P-label']}></label>
                          <input type="text" id="" name="" className={styles.input} value={member.Qualification} disabled />
                        </div>
                      </td>
                      {index > 1 && (
                        <td><span className={styles.span}>Name of Course<i className={styles.red}>*</i>:</span>
                          <div className={styles.div}>
                            <label className={styles['P-label']}></label>
                            <input type="text" id="" name="" className={styles.input} value={member.Course_Name} disabled />
                          </div>
                        </td>
                      )}
                      <td><span className={styles.span}>Name of the Institution<i className={styles.red}>*</i>:</span>
                        <div className={styles.div}>
                          <label className={styles['P-label']}></label>
                          <input type="text" id="" name="" className={styles.input} value={member.Institution_Name} disabled />
                        </div>
                      </td>
                      <td><span className={styles.span}>Address of Institution:</span>
                        <div className={styles.div}>
                          <label className={styles['P-label']}></label>
                          <textarea rows="1" cols="1" id="" name="" className={`${styles.input} ${styles.textarea} `} value={member.Institution_Address} disabled></textarea>
                        </div>
                      </td>
                      {index <= 1 ? (
                        <td><span className={styles.span}>Subjects/Specialization:<i className={styles.red}>*</i></span>
                          <div className={styles.div}>
                            <label className={styles['P-label']}></label>
                            <textarea rows="1" cols="1" id="" name="" className={`${styles.input} ${styles.textarea} `} value={member.Specialization} disabled></textarea>
                          </div>
                        </td>
                      ) : null}
                    </tr>

                    <tr>
                      <td><span className={styles.span}>Period:<i className={styles.red}>*</i></span>
                        <div className={styles.div}>
                          <div className={styles['row-c']}>
                            <div className={styles['td-left']}> Start
                              <label className={styles['P-label']}></label>
                              <input type="text" id="" name="" className={styles.input} value={member.Start_Date} disabled />
                            </div>
                            <div className={styles['td-right']}> End
                              <label className={styles['P-label']}></label>
                              <input type="text" id="" name="" className={styles.input} value={member.End_Date} disabled />
                            </div>
                            <div className={styles.clear}></div>
                          </div>
                        </div>
                      </td>
                      <td><span className={styles.span}>Percentage:</span>
                        <div className={styles.div}>
                          <label className={styles['P-label']}></label>
                          <input type="text" id="" name="" className={styles.input} value={member.Percentage} disabled />
                        </div>
                      </td>
                      {index > 1 ? (
                        <td><span className={styles.span}>Subjects/Specialization:<i className={styles.red}>*</i></span>
                          <div className={styles.div}>
                            <label className={styles['P-label']}></label>
                            <textarea rows="1" cols="1" id="" name="" className={`${styles.input} ${styles.textarea} `} value={member.Specialization} disabled></textarea>
                          </div>
                        </td>
                      ) : null}
                    </tr>
                    {index > 1 && (
                      <tr>
                        <td><span className={styles.span}>Event:</span>
                          <div className={styles.div}>
                            <label className={styles['P-label']}></label>
                            <input type="text" id="" name="" className={styles.input} value={member.Event_Name} disabled />
                          </div>
                        </td>
                        <td><span className={styles.span}>Date:</span>
                          <div className={styles.div}>
                            <label className={styles['P-label']}></label>
                            <input type="text" id="" name="" className={styles.input} value={member.Event_Date} disabled />
                          </div>
                        </td>
                        <td><span className={styles.span}>Represented Whom:</span>
                          <div className={styles.div}>
                            <label className={styles['P-label']}></label>
                            <input type="text" id="" name="" className={styles.input} value={member.Represented_Whom} disabled />
                          </div>
                        </td>
                        <td><span className={styles.span}>Awards Received, if any:</span>
                          <div className={styles.div}>
                            <label className={styles['P-label']}></label>
                            <textarea rows="1" cols="1" id="" name="" className={`${styles.input} ${styles.textarea} `} value={member.Awards_Received} disabled></textarea>
                          </div>
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                ))}
              </table>



            </div>


            <h4 className={styles['accordion-btn']}><span></span> Other Information</h4>
            <div className={styles['accordion-content']}>
              <table cellPadding="0" cellSpacing="0" className={styles.otherTABLE}>
                <tr>
                  <td className={styles.colSpan} colSpan="3">
                    <h5> Other Information&nbsp;
                      <a className={`${styles.preEdit} ${styles.popBTN}`} onClick={() => handleBasicInfo(9)}> <i className={styles.editIcon} ></i>
                      </a></h5></td>
                </tr>
                <tr>
                  <td className={styles['colSpan-L']} colSpan="2"><span className={styles.span}>Who referred you to us for employment?<i
                    className={styles.red}>*</i></span></td>
                  <td className={styles['colSpan-R']} colSpan="2">
                    <div className={styles.div}>
                      <label className={styles['P-label']}></label>
                      <input type="text" id="" name="" className={styles.input} value={formData.Referred_For_Employment} disabled />
                    </div>
                  </td>
                </tr>

                <tr>
                  <td className={styles['colSpan-L']} colSpan="2"><span className={styles.span}>Have you been involved in any Civil/Criminal Litigation?<i
                    className={styles.red}>*</i></span></td>
                  <td className={styles['colSpan-R']} colSpan="2">
                    <div className={styles.div}>
                      <label className={styles['P-label']}></label>
                      <input type="text" id="" name="" className={styles.input} value={formData.Criminal_Case ? formData.Criminal_Case : 'No'} disabled />

                    </div>
                  </td>
                </tr>

                <tr>
                  <td className={styles['colSpan-L']} colSpan="2"><span className={styles.span}>Have you had major illness/operations in the last
                    three years?<i className={styles.red}>*</i></span></td>
                  <td className={styles['colSpan-R']} colSpan="2">
                    <div className={styles.div}>
                      <label className={styles['P-label']}></label>
                      <input type="text" id="" name="" className={styles.input} value={formData.Major_illness ? formData.Major_illness : 'No'} disabled />
                    </div>
                  </td>
                </tr>

                <tr>
                  <td className={styles['colSpan-L']} colSpan="2"><span className={styles.span}>Do you own any vehicle?<i className={styles.red}>*</i></span>
                  </td>
                  <td className={styles['colSpan-R']} colSpan="2">
                    <div className={styles.div}>
                      <label className={styles['P-label']}></label>
                      <input type="text" id="" name="" className={styles.input} value={formData.Vehicle_Detail ? formData.Vehicle_Detail : 'No'} disabled />
                    </div>
                  </td>
                </tr>

                <tr>
                  <td className={styles['colSpan-L']} colSpan="2"><span className={styles.span}>Have you been abroad?<i className={styles.red}>*</i></span></td>
                  <td className={styles['colSpan-R']} colSpan="2">
                    <div className={styles.div}>
                      <label className={styles['P-label']}></label>
                      <input type="text" id="" name="" className={styles.input} value={formData.Abroad_Detail ? formData.Abroad_Detail : 'No'} disabled />
                    </div>
                  </td>
                </tr>

                <tr>
                  <td className={styles['colSpan-L']} colSpan="2"><span className={styles.span}>Do you have a Valid passport?<i
                    className={styles.red}>*</i></span></td>
                  <td className={styles['colSpan-R']} colSpan="2">
                    <div className={styles.div}>
                      <label className={styles['P-label']}></label>
                      <input type="text" id="" name="" className={styles.input} value={formData.Passport_Detail ? formData.Passport_Detail : 'No'} disabled />
                    </div>
                  </td>
                </tr>

                <tr>
                  <td className={styles['colSpan-L']} colSpan="2"><span className={styles.span}>What is your aim in life?<i className={styles.red}>*</i></span>
                  </td>
                  <td className={styles['colSpan-R']} colSpan="2">
                    <div className={styles.div}>
                      <label className={styles['P-label']}></label>
                      <textarea
                        rows="1"
                        cols="1"
                        id=""
                        name=""
                        className={`${styles.input} ${styles.textarea} `}
                        value={formData.Aim_In_Life.trim() ? formData.Aim_In_Life : 'No'}
                        disabled
                      ></textarea>
                    </div>
                  </td>
                </tr>

                <tr>
                  <td className={styles['colSpan-L']} colSpan="2"><span className={styles.span}>Where will you find yourself after 5 years?<i
                    className={styles.red}>*</i></span></td>
                  <td className={styles['colSpan-R']} colSpan="2">
                    <div className={styles.div}>
                      <label className={styles['P-label']}></label>
                      <textarea rows="1" cols="1" id="" name="" className={`${styles.input} ${styles.textarea} `} value={formData.Yourself_after_Years} disabled></textarea>
                    </div>
                  </td>
                </tr>

                <tr>
                  <td className={styles.colSpan} colSpan="4">
                    <h5>Languages known:&nbsp;
                      <a className={`${styles.preEdit} ${styles.popBTN}`} onClick={() => handleBasicInfo(10)}> <i className={styles.editIcon} ></i>
                      </a></h5></td>
                </tr> 

                <tr>
                  <td className={styles.colspanP} colSpan="4">
                    <table cellPadding="0" cellSpacing="0" className={styles.languageTABLE}>
                      <tr>
                        <td>
                          <span className={styles.span}>Language<i className={styles.red}>*</i>:</span>
                        </td>
                        <td>
                          <span className={styles.span}>Level<i className={styles.red}>*</i>:</span>


                        </td>
                        

                      </tr>
                      {languageDetails.map((member, index) => (
                        <React.Fragment key={index}>
                          <tr>
                            <td>

                              <div className={styles.div}>
                                <label className={styles['P-label']}></label>
                                <input type="text" id="" name="" className={styles.input} value={member.Language} disabled />
                              </div>
                            </td>
                            <td>

                              <div className={styles.div}>
                                <label className={styles['P-label']}></label>
                                <input type="text" id="" name="" className={styles.input} value={member.Language_Knowledge} disabled />
                              </div>
                            </td>
                            
                          </tr>
                        </React.Fragment>
                      ))}
                    </table>
                  </td>
                </tr>
                <tr>
                  
                <h5>Download Resume:</h5>
                                                    <div className={styles.div}>
                                                        <div className={styles['resumeupload-container']}>
                                                            <div className={styles['button-wrap-download']}>
                                                                <span className={styles['dowunload-resume']}  download="Resume.pdf"></span>
                                                                <a href={formData.Candidate_Resume} className={styles.resumeDownloadBtn} ><i></i></a> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                </tr>
                <tr>
                  <td className={styles.colSpan} colSpan="4">
                    <h5>If you have taken part in competitive sports, please specify:</h5>
                  </td>
                </tr>

                <tr>
                  <td className={styles.colspanP} colSpan="4">
                    <table cellPadding="0" cellSpacing="0" className={styles.sportsTABLE}>
                      {awardDetails.map((member, index) => (
                        <React.Fragment key={index}>
                          <tr>
                            <td className={styles.competitive1}>
                              <span className={styles.span}>Event:</span>
                              <div className={styles.div}>
                                <label className={styles['P-label']}></label>
                                <input type="text" id="" name="" className={styles.input} value={member.Event_Name} disabled />
                              </div>
                            </td>
                            <td className={styles.competitive2}>
                              <span className={styles.span}>Year:</span>
                              <div className={styles.div}>
                                <label className={styles['P-label']}></label>
                                <input type="text" id="" name="" className={styles.input} value={member.Event_Date} disabled />
                              </div>
                            </td>
                            <td className={styles.competitive3}>
                              <span className={styles.span}>Represented whom:</span>
                              <div className={styles.div}>
                                <label className={styles['P-label']}></label>
                                <input type="text" id="" name="" className={styles.input} value={member.Represented_Whom} disabled />
                              </div>
                            </td>
                            <td className={styles.competitive4}>
                              <span className={styles.span}>Awards received, if any:</span>
                              <div className={styles.div}>
                                <label className={styles['P-label']}></label>
                                <input type="text" id="" name="" className={styles.input} value={member.Awards_Received} disabled />
                              </div>
                            </td>
                          </tr>
                        </React.Fragment>
                      ))}
                    </table>
                  </td>
                </tr>

                <tr>
                  <td className={styles['colSpan-L']} colSpan="2"><span className={styles.span}>Hobby/extra curriculam activities:</span></td>
                  <td className={styles['colSpan-R']} colSpan="2">
                    <div className={styles.div}>
                      <label className={styles['P-label']}></label>
                      <textarea rows="1" cols="1" id="" name="" className={`${styles.input} ${styles.textarea} `} value={formData.Hobby} disabled></textarea>
                    </div>
                  </td>
                </tr>

                <tr>
                  <td className={styles['colSpan-L']} colSpan="2"><span className={styles.span}>Any other information you wish to provide in support
                    of your candidature:</span></td>
                  <td className={styles['colSpan-R']} colSpan="2">
                    <div className={styles.div}>
                      <label className={styles['P-label']}></label>
                      <textarea rows="1" cols="1" id="" name="" className={`${styles.input} ${styles.textarea} `} value={formData.Other_Information} disabled></textarea>
                    </div>
                  </td>
                </tr>

                <tr>
                  <td className={styles.colSpan} colSpan="4">
                    <h5>Salary Expected</h5>
                  </td>
                </tr>

                <tr>
                  <td className={styles.colspanP} colSpan="4">
                    <table cellPadding="0" cellSpacing="0" className={styles.salaryTABLE}>
                      <tr>
                        <td>
                          <span className={styles.strong}>RENUMERATION EXPECTED<i className={styles.red}>*</i>: <span className={styles['span-small']}></span></span>
                          <div className={styles.div}>
                            <label className={styles['P-label']}></label>
                            <input type="text" id="" name="" className={styles.input} value={formData.Expected_Salary} disabled />
                          </div>
                        </td>
                        <td>
                          <span className={styles.strong}>Current SALARY<i className={styles.red}>*</i>: <span className={styles['span-small']}></span></span>
                          <div className={styles.div}>
                            <label className={styles['P-label']}></label>
                            <input type="text" id="" name="" className={styles.input} value={formData.Current_Salary} disabled />
                          </div>
                        </td>

                        <td>
                          <span className={styles.strong}>Notice period<i className={styles.red}>*</i>:</span>
                          <div className={styles.div}>
                            <label className={styles['P-label']}></label>
                            <input type="text" id="" name="" className={styles.input} value={formData.Notice_preiod} disabled />
                          </div>
                        </td>
                        <td>
                          <span className={styles.strong}>WHEN CAN YOU JOIN?<i className={styles.red}>*</i></span>
                          <div className={styles.div}>
                            <label className={styles['P-label']}></label>
                            <input type="text" id="" name="" className={styles.input} value={formData.When_Join} disabled />
                          </div>
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>

                <tr>
                  <td className={styles.colSpan} colSpan="4">
                    <div className={styles.declare}>
                      <label className={styles.checkbox}>I, hereby declare that the information furnished above is true, complete and
                        correct to the best of my knowledge and belief.<i className={styles.red}>*</i>
                        <input type="checkbox" checked/>
                        <span className={styles.checkmark}></span></label>
                    </div>
                    <div className={styles.clear}></div>
                  </td>
                </tr>


              </table>
              <div className={styles['btn-content-edit']}>
                <div>
                   <a id="" className={styles.btnEdit} onClick={handleSubmitClick}>Submit Form</a>
                </div>


              </div>
            </div>

          </div>
        </div>
      )}
    </>
  );
}

export default Preview;